import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { LogoIcon } from 'src/components/common/icon';
import { useUserProfile } from '../../../hooks/useUserProfile';
import LinearLayout from '../../layout/LinearLayout';
import { SettingPages, supportNavigation } from '../../../store/slices/authSlice';
import { setViewportHeight } from '../../../utils/ViewPortHeight';
import BaseButton from '../../common/button/BaseButton';
import { XCloseIcon } from '../../common/icon/icons/XCloseIcon';
import { StyledLogo } from '../header/styles';
import { useTranslation } from 'react-i18next';

interface MobileSettingTemplateProps {
  setMenuVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onMenuClick: (key: SettingPages) => void;
  onHomeClick?: () => void;
  onCreateTableClick?: () => void;
  isInTable?: boolean;
}

const MobileMenuTemplate: React.FC<MobileSettingTemplateProps> = ({ setMenuVisible, onMenuClick, onHomeClick, onCreateTableClick, isInTable }) => {
  const { user } = useUserProfile();
  const { t } = useTranslation();
  const handleCreate = () => {
    onCreateTableClick?.();
    setMenuVisible(false);
  };

  const getTabName = (tabName: SettingPages) => {
    switch (tabName) {
      case 'Cash game':
        return t('LANDING.CashGames');
      case 'Profile':
        return t('PROFILE.Profile');
      case 'Settings':
        return t('SETTINGS.Settings');
      case 'Wallet':
        return t('WALLET.Wallet');
      case 'History':
        return t('HISTORY.History');
      case 'Log out':
        return t('LOGOUT.LogOut');
    }
  };

  useEffect(() => {
    setViewportHeight();
    window.addEventListener('resize', setViewportHeight);
    return () => window.removeEventListener('resize', setViewportHeight);
  }, []);

  return (
    <Wrap>
      <Top>
        <StyledLogo onClick={onHomeClick}>
          <LogoIcon h={20} w={30} color={'primary500'} />
        </StyledLogo>
        <XCloseIcon onClick={() => setMenuVisible(false)} />
      </Top>
      <Avatar src={user?.profile} alt="Profile" />
      <LinearLayout width={'calc(100vw - 40px)'} marginTop={16} borderRadius={12} backgroundColor={'gray700'} gap={8} padding={32}>
        {supportNavigation
          .filter(item => (isInTable ? item !== 'Log out' : true))
          .map(item => (
            <MenuItem key={item} onClick={() => onMenuClick(item)}>
              {getTabName(item)}
            </MenuItem>
          ))}
      </LinearLayout>
      {onCreateTableClick && (
        <Bottom>
          <BaseButton onClick={handleCreate} size={'btn40'} color={'primary'} textStyle={'TextSmall_Semibold'} textColor={'white'} text={t('BUTTON.CreateTable')} />
        </Bottom>
      )}
    </Wrap>
  );
};

const Wrap = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  top: 0;
  left: 0;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  z-index: 10000;
  background-color: ${({ theme }) => theme.colors.gray800};
  padding: 20px;
`;

const Top = styled.div`
  box-sizing: border-box;
  width: calc(100vw - 40px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
`;

const Bottom = styled.div`
  width: calc(100vw - 40px);
  margin-top: 16px;
`;

const Avatar = styled.img`
  width: 44px;
  height: 44px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
  display: flex;
  margin-top: 34px;
`;

const MenuItem = styled.div`
  ${({ theme }) => theme.textStyles.TextMd_Medium};
  box-sizing: border-box;
  padding: 8px 8px;
  color: ${({ theme }) => theme.colors.white};
  width: 100%;
  border-radius: 12px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray600};
  }
`;

export default MobileMenuTemplate;
