import React from 'react';
import styled from '@emotion/styled';
import Text from '../../common/text/Text';
import BaseButton from '../../common/button/BaseButton';
import Collapse from '../../common/Collapse';
import useDevice from '../../../hooks/useDevice';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';

const LandingFaq = () => {
  const [expandedIndex, setExpandedIndex] = React.useState<number | null>(0);
  const { isMobile } = useDevice();
  const { t: translation } = useTranslation();

  const faqs = [
    {
      title: translation('LANDING.WhatIsBasepoker'),
      desc: translation('LANDING.WhatIsBasePokerAnswer')
    },
    {
      title: translation('LANDING.IsBasepokerFair'),
      desc: translation('LANDING.IsBasepokerFairAnswer')
    },
    {
      title: translation('LANDING.WhatPaymentMethod'),
      desc: translation('LANDING.WhatPaymentMethodAnswer')
    },
    {
      title: translation('LANDING.SafeBasepoker'),
      desc: translation('LANDING.SafeBasepokerAnswer')
    },
    {
      title: translation('LANDING.CanMobile'),
      desc: translation('LANDING.CanMobileAnswer')
    },
    {
      title: translation('LANDING.CanMultiple'),
      desc: translation('LANDING.CanMultipleAnswer')
    }
  ];

  const goSupportCenter = () => {
    window.open(`https://support.basepoker.com`, '_blank');
  };

  return (
    <StyledWrap>
      <StyledTitleWrap>
        <StyledTitle textAlign={'start'} fontStyle={'DisplaySm_Semibold'} text={translation('LANDING.BasepokerQuestions')} lineHeight={30} />
        <BaseButton size={isMobile ? 'btn44' : 'btn48'} color={'primary'} textStyle={'TextMd_Semibold'} text={translation('LANDING.SupportCenter')} w={150} onClick={goSupportCenter} />
      </StyledTitleWrap>
      <StyledFaqs>
        {faqs.map((faq, index) => (
          <Collapse key={index} expanded={index === expandedIndex} onToggle={() => setExpandedIndex(index)} title={faq.title}>
            <Text lineHeight={24} textColor={'gray300'} textAlign={'start'} fontStyle={isMobile ? 'TextSmall_Medium' : 'TextMd_Medium'} text={faq.desc} breakType={['ja', 'zh'].includes(i18n.language) ? 'all' : 'word'} />
          </Collapse>
        ))}
      </StyledFaqs>
    </StyledWrap>
  );
};

const StyledTitle = styled(Text)`
  line-height: 38px;
  @media (max-width: 1280px) {
    ${({ theme }) => theme.textStyles.TextXl_Semibold}
    line-height: 30px;
  }
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextMd_Semibold}
    line-height: 24px;
  }
`;
const StyledFaqs = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  gap: 8px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const StyledWrap = styled.div`
  width: 100%;
  max-width: 1200px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1280px) {
    gap: 16px;
    padding: 0 40px;
    box-sizing: border-box;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0 20px;
    gap: 32px;
  }
`;

const StyledTitleWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 240px;
  @media (max-width: 768px) {
    gap: 24px;
  }
`;

export default LandingFaq;
