import { useUserProfile } from './useUserProfile';
import { waitForReduxStateChange } from 'src/utils/Util';
import { selectIsSeatInPrePared, selectTableUser } from 'src/store/slices/streaming';
import { RootState } from 'src/store/store';
import { ModalType, showModal, showModalWithRequest } from 'src/store/slices/modalSlice';
import { useDispatch } from 'react-redux';
import { bpSocketService } from 'src/services/BpWebSocketService';
import { logError } from '../utils/ConsoleUtil';
import { SeatStatus } from '../store/api/responseTypes';
import { useEffect, useState } from 'react';
import { useTableContext } from './TableProvider';
import { setTableSelectedSeatId } from '../store/slices/tableSlice';

const useTableActions = () => {
  const dispatch = useDispatch();
  const { user } = useUserProfile();
  const { isTournamentTable, handId, canStart, canSeatBackIn, canBuyIn, prepareStart, canReserve, availableSeats, winnerType, handRound, myPlayerData, tableId, mySeatId, myStack, myUserData, mySeatStatus } = useTableContext();
  const [canShowCards, setCanShowCards] = useState(false);

  const startGame = () => bpSocketService.tableStart();

  const showBuyInModal = () => dispatch(showModal(ModalType.BuyInModal));

  const showAuthModal = () => dispatch(showModal(ModalType.AuthModal));

  const seatOrBuyIn = (seatId?: number) => {
    if (!user) {
      dispatch(setTableSelectedSeatId({ tableId, seatId }));
      showAuthModal();
      return;
    }
    if (bpSocketService.isConnected() && (mySeatStatus === undefined || mySeatStatus === SeatStatus.STAND)) {
      dispatch(setTableSelectedSeatId({ tableId, seatId: undefined }));
      seatOut(seatId);
      waitForReduxStateChange(
        (state: RootState) => selectIsSeatInPrePared(state, Number(tableId), user.id),
        isPrepared => isPrepared
      )
        .then(() => {
          if (myStack > 0) {
            seatIn(seatId);
          } else {
            showBuyInModal();
          }
        })
        .catch(error => {
          logError(error);
        });
    }
  };

  const showVerifyPasswordModal = (seatId?: number) => {
    if (!user) {
      dispatch(setTableSelectedSeatId({ tableId, seatId }));
      showAuthModal();
      return;
    }
    if (myUserData) {
      if (myUserData?.isPasswordVerified) {
        seatOrBuyIn(seatId);
      } else {
        dispatch(setTableSelectedSeatId({ tableId, seatId: undefined }));
        dispatch(showModalWithRequest({ type: ModalType.PasswordVerifyModal, data: seatId ?? undefined }));
      }
    }
  };

  const seatIn = (seatId?: number) => {
    if (bpSocketService.isConnected() && mySeatId !== undefined) {
      bpSocketService.tableSeatIn(seatId);
    }
  };

  const seatOut = (seatId?: number) => {
    // 내 자리가 있거나 남아 있는 자리가 있거나
    if (bpSocketService.isConnected() && (mySeatId !== undefined || availableSeats >= 1)) {
      bpSocketService.tableSeatOut(seatId);
    }
  };

  const seatBackIn = () => {
    if (bpSocketService.isConnected() && mySeatId !== undefined) {
      bpSocketService.tableSeatIn();
    }
  };

  const stand = () => {
    if (bpSocketService.isConnected() && mySeatId !== undefined) {
      bpSocketService.tableStand(mySeatId);
    }
  };

  const showCards = (card: (0 | 1)[]) => {
    if (bpSocketService.isConnected() && mySeatId !== undefined) {
      bpSocketService.gameShowHand(card, isTournamentTable, handId);
    }
  };

  const selectUser = (userId?: number) => {
    if (!user) {
      showAuthModal();
      return;
    }
    if (userId === undefined || userId === user?.id) {
      dispatch(selectTableUser({ userId: undefined, tableId: tableId }));
    } else {
      dispatch(selectTableUser({ userId: userId, tableId: tableId }));
    }
  };

  useEffect(() => {
    if (winnerType === undefined) {
      if (myPlayerData && handRound === 'SETTLEMENT' && !myPlayerData.isCardRevealed) {
        setCanShowCards(true);
      } else {
        setCanShowCards(false);
      }
    } else {
      setCanShowCards(false);
    }
  }, [myPlayerData, handRound, myPlayerData?.isCardRevealed, winnerType]);

  return {
    canStart,
    prepareStart,
    canBuyIn,
    canSeatBackIn,
    availableSeats,
    myCards: myPlayerData?.cards,
    tableId,
    canReserve,
    canShowCards,
    startGame,
    showVerifyPasswordModal,
    seatIn,
    seatOut,
    seatBackIn,
    stand,
    seatOrBuyIn,
    showBuyIn: showBuyInModal,
    showCards,
    selectUser
  };
};

export default useTableActions;
