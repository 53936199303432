import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Colors } from '../../../styles/Colors';
import BaseButton from '../../common/button/BaseButton';
import Text from '../../common/text/Text';
import TitleValueHorizontal from '../../common/text/TitleValueHorizontal';
import LinearLayout from '../../layout/LinearLayout';
import { TournamentDetailBox } from '../../../pages/TournamentDetailScreen';
import { useTournamentLobby } from '../../../hooks/useTournamentLobby';
import { toFixedFloor } from '../../../utils/StringUtil';
import { getTournamentDate, getTournamentDuration } from '../../../utils/DateUtil';
import HorizontalLayout from '../../layout/HorizontalLayout';
import { ModalType, showModalWithRequest } from '../../../store/slices/modalSlice';
import { useDispatch } from 'react-redux';
import useOpenTable from '../../../hooks/useOpenTable';
import { TournamentFinished } from '../../common/icon';
import { TournamentStatus } from '../../../store/slices/tournamentTypes';

const base = Date.now();

interface TournamentStateTimerProps {
  size?: number;
}

const TournamentStateTimer: React.FC<TournamentStateTimerProps> = ({ size = 90 }) => {
  const dispatch = useDispatch();
  const { tournamentId, tournamentInfo, isRegistered, tournamentStructure, totalPrizeAmount, tournamentStatus, isRegisterEnable, tournamentAsset, myTournamentTableShareCode } = useTournamentLobby();
  const { openTable } = useOpenTable();
  const currentBlindInfo = tournamentStructure?.blindSchedule.find(blindSchedule => {
    return blindSchedule.blindId === tournamentInfo?.dynamic?.currentBlindId?.toString();
  });

  const startTime = base - 60 * 1000; // 1분 전
  const endTime = base + 60 * 1000; // 1분 후
  const now = Date.now();
  const [timeLeft, setTimeLeft] = useState(endTime - now);
  const [isAnimating, setIsAnimating] = useState(false);
  const totalTime = endTime - startTime;
  const radius = (size / 2) * 0.8; // Calculate radius based on size prop
  const circumference = 2 * Math.PI * radius;

  const getStrokeDashoffset = () => {
    const progress = timeLeft / totalTime;
    return progress * circumference;
  };

  useEffect(() => {
    setTimeLeft(endTime - Date.now());
    setTimeout(() => setIsAnimating(true), 0); // 애니메이션 활성화

    const intervalId = setInterval(() => {
      const currentTime = Date.now();
      if (currentTime >= endTime) {
        setTimeLeft(0); // 종료
      } else if (currentTime <= startTime) {
        setTimeLeft(totalTime); // 시작 전
      } else {
        setTimeLeft(endTime - currentTime); // 남은 시간 업데이트
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [endTime, startTime, totalTime]);

  // 시간 포맷 함수
  const formatTime = (milliseconds: number) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const days = Math.floor(totalSeconds / (24 * 60 * 60));
    const hours = Math.floor((totalSeconds % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
    const seconds = totalSeconds % 60;

    if (days > 0) return `${days}d`;
    if (hours > 0) return `${hours}h`;
    if (minutes > 0) return `${minutes}m`;
    return `${seconds}s`;
  };

  const register = () => {
    if (tournamentId !== null) {
      dispatch(showModalWithRequest({ type: ModalType.TournamentRegisterModal, data: { tournamentId: tournamentId } }));
    }
  };

  const unregister = () => {
    if (tournamentId !== null) {
      dispatch(showModalWithRequest({ type: ModalType.TournamentUnregisterModal, data: { tournamentId: tournamentId } }));
    }
  };

  const getStatus = () => {
    switch (tournamentStatus) {
      case TournamentStatus.CREATED:
      case TournamentStatus.REGISTERING:
        return 'Registering';
      case TournamentStatus.PREPARING:
        return 'Preparing';
      case TournamentStatus.LATE_REGISTRATION:
        return 'Late Registration';
      case TournamentStatus.RUNNING:
        return 'Running';
      case TournamentStatus.CLOSED:
        return 'Finished';
      default:
        return 'Unknown';
    }
  };

  const getStatusColor = (): keyof typeof Colors => {
    switch (tournamentStatus) {
      case TournamentStatus.CREATED:
      case TournamentStatus.REGISTERING:
      case TournamentStatus.PREPARING:
        return 'primary400';
      case TournamentStatus.LATE_REGISTRATION:
        return 'error500';
      case TournamentStatus.RUNNING:
        return 'gray400';
      case TournamentStatus.CLOSED:
        return 'gray400';
      default:
        return 'primary200';
    }
  };

  const getStatusView = () => {
    switch (tournamentInfo?.dynamic?.status) {
      case TournamentStatus.CREATED:
      case TournamentStatus.REGISTERING:
      case TournamentStatus.PREPARING:
        return (
          <>
            <TitleValueHorizontal label={`Starting Time`} value={getTournamentDate(tournamentInfo?.static.startingTime)} labelColor="gray400" valueColor="gray300" />
            <TitleValueHorizontal label={`Estimated Duration`} value={getTournamentDuration(tournamentInfo?.static.estimatedDuration)} labelColor="gray400" valueColor="gray300" />
          </>
        );
      case TournamentStatus.LATE_REGISTRATION:
      case TournamentStatus.RUNNING:
        return (
          <>
            <TitleValueHorizontal label={`Blind Level ${currentBlindInfo?.level}`} value={`${currentBlindInfo?.sb}/${currentBlindInfo?.bb}`} labelColor="gray400" valueColor="gray300" />
            <TitleValueHorizontal label="Next Blind" value="TODO" labelColor="gray400" valueColor="gray300" />
            <TitleValueHorizontal label="Next Break" value="TODO" labelColor="gray400" valueColor="gray300" />
          </>
        );
      case TournamentStatus.CLOSED:
      default:
        return (
          <>
            <TitleValueHorizontal label={`Level ${currentBlindInfo?.level}`} value={`${currentBlindInfo?.sb}/${currentBlindInfo?.bb}`} labelColor="gray400" valueColor="gray300" />
          </>
        );
    }
  };

  return (
    <TournamentDetailBox>
      <Wrap>
        <PrizePoolSection>
          <LinearLayout gap={4}>
            <Text fontStyle="TextMd_Semibold" textColor="white" text="Prize Pool" textAlign={'start'} />
            <HorizontalLayout margin={'0'} justifyContent={'start'} gap={4}>
              <AssetImage src={tournamentAsset?.symbolImage} alt={tournamentAsset?.name} />
              <Text fontStyle="TextXl_Semibold" textColor="primary400" text={toFixedFloor(totalPrizeAmount, 0, false, true)} textAlign={'start'} />
            </HorizontalLayout>
          </LinearLayout>
          {isRegisterEnable && !myTournamentTableShareCode && (
            <BaseButton size="btn40" w={105} color={!isRegisterEnable ? 'gray' : isRegistered ? 'red' : 'primary'} disabled={!isRegisterEnable} onClick={isRegistered ? unregister : register}>
              {isRegistered ? 'Unregister' : 'Register'}
            </BaseButton>
          )}
          {!isRegisterEnable && isRegistered && myTournamentTableShareCode && (
            <BaseButton
              size="btn40"
              w={105}
              color={isRegistered ? 'red' : 'primary'}
              disabled={!isRegistered}
              onClick={() => {
                openTable(myTournamentTableShareCode, tournamentId);
              }}
            >
              {'Take a seat'}
            </BaseButton>
          )}
          {tournamentStatus === TournamentStatus.CLOSED && (
            <BaseButton size="btn40" w={105} color={'gray'} disabled={true} onClick={() => {}}>
              {'Finished'}
            </BaseButton>
          )}
        </PrizePoolSection>
        {tournamentStatus === TournamentStatus.CLOSED ? (
          <CircularTimer>
            <TournamentFinished />
          </CircularTimer>
        ) : (
          <CircularTimer>
            <svg width={size} height={size}>
              <circle cx={size / 2} cy={size / 2} r={radius} stroke={Colors.primary600} strokeWidth="6" fill="transparent" />
              <circle
                cx={size / 2}
                cy={size / 2}
                r={radius}
                stroke={Colors.primary200}
                strokeWidth="6"
                fill="transparent"
                strokeDasharray={circumference}
                strokeDashoffset={getStrokeDashoffset()}
                strokeLinecap="round"
                style={{
                  transition: isAnimating ? 'stroke-dashoffset 1s linear' : 'none',
                  transform: 'rotate(-90deg)',
                  transformOrigin: '50% 50%'
                }}
              />
            </svg>
            <TimeDisplay>{formatTime(timeLeft)}</TimeDisplay>
          </CircularTimer>
        )}
        <LinearLayout gap={4} flex={1}>
          <Text fontStyle="TextMd_Semibold" textColor={getStatusColor()} text={getStatus()} textAlign={'start'} marginBottom={12} />
          {getStatusView()}
        </LinearLayout>
      </Wrap>
    </TournamentDetailBox>
  );
};

const Wrap = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: 24px;
  gap: 12px;
  color: white;
`;

const PrizePoolSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 22px;
  height: 100%;
  min-width: 105px;
`;

const CircularTimer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 6px;
`;

const TimeDisplay = styled.div`
  position: absolute;
  ${({ theme }) => theme.textStyles.TextMd_Semibold};
  color: white;
`;

const AssetImage = styled.img`
  width: 20px;
  height: 20px;
  align-self: center;
`;

export default TournamentStateTimer;
