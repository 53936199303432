import React from 'react';
import styled from '@emotion/styled';
import { TextStyles } from '../../../styles/textStyles';
import { HeaderLogoIcon } from '../../common/icon';
import { ReactComponent as BeGambleAwareLogo } from '../../../assets/img/BeGambleAwareLogo.svg';
import { ReactComponent as AgeRestrictedIcon } from '../../../assets/img/ageRestrictedIcon.svg';
import { ReactComponent as GaLogo } from '../../../assets/img/gaLogo.svg';
import { setPolicyTab } from '../../../store/slices/authSlice';
import { useDispatch } from 'react-redux';
import useLangNavigate from '../../../hooks/useLangNavigate';
import { useTranslation } from 'react-i18next';

enum LinkType {
  MAIN = 'main',
  X = 'X.com',
  DISCORD = 'Discord',
  TELEGRAM = 'Telegram',
  REDDIT = 'Reddit',
  FACEBOOK = 'Facebook',
  SUPPORT_CENTER = 'Support Center',
  BLOG = 'Blog',
  BE_GAMBLE_AWARE = 'BeGambleAware',
  GA = 'GA',
  TERMS = 'Terms of Service',
  PRIVACY_POLICY = 'Privacy Policy',
  COOKIE_POLICY = 'Cookie Policy',
  HOUSE_RULES = 'House Rules',
  RESPONSIBLE_GAMING = 'Responsible Gaming'
}

const getTranslationKey = (type: LinkType): string => {
  switch (type) {
    case LinkType.TERMS:
      return 'Terms';
    case LinkType.PRIVACY_POLICY:
      return 'PrivacyPolicy';
    case LinkType.COOKIE_POLICY:
      return 'Cookie';
    case LinkType.HOUSE_RULES:
      return 'HouseRules';
    case LinkType.RESPONSIBLE_GAMING:
      return 'Responsible';
    case LinkType.SUPPORT_CENTER:
      return 'SupportCenter';
    case LinkType.BLOG:
      return 'Blog';
    case LinkType.DISCORD:
      return 'Discord';
    case LinkType.X:
      return 'Twitter'; // Adjust key as needed for translation
    case LinkType.FACEBOOK:
      return 'Facebook';
    case LinkType.TELEGRAM:
      return 'Telegram';
    default:
      throw new Error(`Translation key not found for LinkType: ${type}`);
  }
};

const Footer: React.FC = () => {
  const navigate = useLangNavigate();
  const { t: translation } = useTranslation();
  const dispatch = useDispatch();

  const onLinkClick = (type: LinkType) => {
    switch (type) {
      case LinkType.MAIN:
        navigate('/');
        break;
      case LinkType.X:
        window.open(`https://x.com/BasepokerHQ`, '_blank');
        break;
      case LinkType.DISCORD:
        window.open(`https://discord.gg/RpzCgkUHw9`, '_blank');
        break;
      case LinkType.TELEGRAM:
        window.open(`https://t.me/basepokerHQ`, '_blank');
        break;
      case LinkType.REDDIT:
        window.open(`https://www.reddit.com/r/Basepoker`, '_blank');
        break;
      case LinkType.FACEBOOK:
        window.open(`https://www.facebook.com/basepoker`, '_blank');
        break;
      case LinkType.SUPPORT_CENTER:
        window.open(`https://support.basepoker.com`, '_blank');
        break;
      case LinkType.BLOG:
        window.open(`https://medium.com/@basepoker`, '_blank');
        break;
      case LinkType.BE_GAMBLE_AWARE:
        window.open(`https://www.begambleaware.org/`, '_blank');
        break;
      case LinkType.GA:
        window.open(`https://www.gamblersanonymous.org.uk/`, '_blank');
        break;
      case LinkType.TERMS:
      case LinkType.PRIVACY_POLICY:
      case LinkType.COOKIE_POLICY:
      case LinkType.HOUSE_RULES:
      case LinkType.RESPONSIBLE_GAMING:
        navigate('/policy');
        dispatch(setPolicyTab(type));
        break;
      default:
        break;
    }
  };

  const policyList = [LinkType.TERMS, LinkType.PRIVACY_POLICY, LinkType.COOKIE_POLICY, LinkType.HOUSE_RULES, LinkType.RESPONSIBLE_GAMING];
  const supportList = [LinkType.SUPPORT_CENTER, LinkType.BLOG];
  const communityList = [LinkType.DISCORD, LinkType.X, LinkType.FACEBOOK, LinkType.TELEGRAM];

  return (
    <FooterWrap>
      <FooterContainer>
        <StyledLogoIcon onClick={() => onLinkClick(LinkType.MAIN)}>
          <HeaderLogoIcon />
        </StyledLogoIcon>
        <FooterInner>
          <StyledList>
            <StyledTitle>{translation('FOOTER.Policy')}</StyledTitle>
            {policyList.map((item, index) => (
              <StyledItem key={index} onClick={() => onLinkClick(item)}>
                {translation(`FOOTER.${getTranslationKey(item)}`)}
              </StyledItem>
            ))}
          </StyledList>
          <StyledList>
            <StyledTitle>{translation('FOOTER.Support')}</StyledTitle>
            {supportList.map((item, index) => (
              <StyledItem key={index} onClick={() => onLinkClick(item)}>
                {translation(`FOOTER.${getTranslationKey(item)}`)}
              </StyledItem>
            ))}
          </StyledList>
          <StyledList>
            <StyledTitle>{translation('FOOTER.Community')}</StyledTitle>
            {communityList.map((item, index) => (
              <StyledItem key={index} onClick={() => onLinkClick(item)}>
                {translation(`FOOTER.${getTranslationKey(item)}`)}
              </StyledItem>
            ))}
          </StyledList>
        </FooterInner>
        <StyledLogoWrap>
          <StyledLogo>
            <StyledBeGambleAwareLogo onClick={() => onLinkClick(LinkType.BE_GAMBLE_AWARE)}>
              <BeGambleAwareLogo />
            </StyledBeGambleAwareLogo>
          </StyledLogo>
          <StyledLogo>
            <StyledAgeRestrictedIcon>
              <AgeRestrictedIcon />
            </StyledAgeRestrictedIcon>
            <StyledGaLogo onClick={() => onLinkClick(LinkType.GA)}>
              <GaLogo />
            </StyledGaLogo>
          </StyledLogo>
        </StyledLogoWrap>
        <StyledCopyRight>Basepoker | © 2024</StyledCopyRight>
      </FooterContainer>
    </FooterWrap>
  );
};

const StyledBeGambleAwareLogo = styled.div`
  cursor: pointer;
  @media (max-width: 768px) {
    svg {
      width: 153.333px;
      height: 20px;
    }
  }
`;
const StyledAgeRestrictedIcon = styled.div`
  @media (max-width: 768px) {
    svg {
      width: 30px;
      height: 30px;
    }
  }
`;
const StyledGaLogo = styled.div`
  cursor: pointer;
  @media (max-width: 768px) {
    svg {
      width: 46.875px;
      height: 30px;
    }
  }
`;

const StyledLogoIcon = styled.div`
  cursor: pointer;
  @media (max-width: 992px) {
    position: absolute;
    left: 0;
    top: 0;
  }
  @media (max-width: 768px) {
    svg {
      width: 135.3px;
      height: 16px;
    }
  }
`;

const StyledCopyRight = styled.div`
  position: absolute;
  bottom: 12px;
  left: 50%;
  transform: translateX(-50%);
  ${({ theme }) => theme.textStyles.TextXs_Medium}
  color: ${({ theme }) => theme.colors.gray400};
  @media (max-width: 768px) {
    left: 0;
    transform: none;
  }
`;
const FooterWrap = styled.div`
  text-align: start;
  ${TextStyles.TextMd}
  align-self: center;
  display: flex;
  justify-content: center;
  z-index: 11;
  background-color: ${({ theme }) => theme.colors.gray800};
  width: 100%;
  height: 334px;
  box-sizing: border-box;
  padding: 60px 120px 0 120px;
  @media (max-width: 992px) {
    height: 458px;
    padding: 40px 60px 0 60px;
  }
  @media (max-width: 768px) {
    height: 426px;
    padding: 32px 20px 0 20px;
  }
`;
const StyledLogoWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media (max-width: 992px) {
    margin-bottom: 90px;
  }
`;

const StyledLogo = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledTitle = styled.div`
  ${({ theme }) => theme.textStyles.TextSmall_Semibold}
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 4px;
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextXs_Semibold}
  }
`;
const StyledItem = styled.div`
  ${({ theme }) => theme.textStyles.TextSmall_Medium}
  color: ${({ theme }) => theme.colors.gray400};
  line-height: 20px;
  cursor: pointer;
  transition: color 0.2s;
  text-align: start;

  &:hover {
    color: ${({ theme }) => theme.colors.white};
  }

  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextXs_Medium}
  }
`;
const StyledList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const FooterContainer = styled.footer`
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  max-width: 1360px;
  width: 100%;
  @media (max-width: 992px) {
    flex-direction: column;
    align-items: flex-end;
    gap: 24px;
  }
  @media (max-width: 768px) {
    align-items: flex-start;
    justify-content: flex-start;
    gap: 32px;
  }
`;

const FooterInner = styled.div`
  display: flex;
  gap: 60px;
  @media (max-width: 768px) {
    margin-top: 48px;
    gap: 40px;
  }
`;

export default Footer;
