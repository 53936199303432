import styled from '@emotion/styled';
import React from 'react';
import Text from 'src/components/common/text/Text';
import { useTableContext } from '../../../../hooks/TableProvider';
import { timeFormat } from '../../../../utils/StringUtil';

const SeatOutCounter = () => {
  const interval = React.useRef<NodeJS.Timeout>();
  const [diffTime, setDiffTime] = React.useState<number>(0);
  const { mySeatData } = useTableContext();

  React.useEffect(() => {
    if (mySeatData?.autoStandAt) {
      const endTime = new Date(mySeatData?.autoStandAt);
      interval.current = setInterval(() => {
        const now = new Date().getTime();
        const diff = endTime.getTime() - now;

        setDiffTime(diff);
        if (diff < 0) {
          clearInterval(interval.current!);
          setDiffTime(0);
        }
      }, 500);
    } else {
      clearInterval(interval.current!);
      setDiffTime(0);
    }
    return () => {
      clearInterval(interval.current!);
    };
  }, [mySeatData]);

  return (
    <StyledCountdown visible={diffTime > 0}>
      <Text textColor="white" fontStyle="Display2xl_Bold" text={timeFormat(diffTime)} />
    </StyledCountdown>
  );
};

const StyledCountdown = styled.div<{ visible?: boolean }>`
  ${({ visible }) => !visible && 'visibility: hidden;'}
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140%;
  top: 0;
  bottom: 0;
  border-radius: 1000px;
  z-index: 1199;
  background: radial-gradient(50% 50% at 50% 50%, rgba(16, 24, 40, 0.7) 0%, rgba(37, 52, 70, 0) 100%);
  @media (max-width: 768px) {
    padding-bottom: 70px;
  }
`;

export default SeatOutCounter;
