import React, { useEffect, useRef, useState } from 'react';
import { CardsIcon, HeaderLogoIcon, LogoIcon, Menu2Icon, TournamentIcon } from '../../common/icon';
import { useDispatch } from 'react-redux';

import { ModalType, showModal, showModalWithRequest } from '../../../store/slices/modalSlice';
import { useUserProfile } from '../../../hooks/useUserProfile';
import useDevice from '../../../hooks/useDevice';
import MobileMenuTemplate from '../settings/MobileMenuTemplate';
import { setActiveTab, SettingPages, supportNavigation } from '../../../store/slices/authSlice';
import { Avatar, HeaderInner, HeaderProfile, HoverContainer, MenuItem, Menus, StyledLeftHeaderContents, StyledLogo, StyledRightHeaderContents } from './styles';
import styled from '@emotion/styled';
import BaseButton from '../../common/button/BaseButton';
import { useMyBalance } from '../../../hooks/useMyBalance';
import CashierButton from '../cashier/CashierButton';
import { toFixedFloor } from '../../../utils/StringUtil';
import useLangNavigate from '../../../hooks/useLangNavigate';
import { useTranslation } from 'react-i18next';
import SideNavigation from './SideNavigation';

type HeaderProps = {
  errorType?: string;
  path?: string;
};

const MainHeader: React.FC<HeaderProps> = ({ errorType, path }) => {
  const { isMobile } = useDevice();
  const { t: translation } = useTranslation();
  const navigate = useLangNavigate();
  const dispatch = useDispatch();
  const { user } = useUserProfile();
  const sidebarRef = useRef<HTMLDivElement>(null); // 사이드 네비게이션 참조
  const menuButtonRef = useRef<HTMLDivElement>(null); // 사이드 네비게이션 참조

  const [isMenuVisible, setMenuVisible] = useState(false);
  const { myBalance } = useMyBalance();
  const totalEstimatedBalance = toFixedFloor(Number(myBalance?.estimateBalance ?? 0), 2, false, true) ?? 0;
  const [isSidebarActive, setIsSidebarActive] = useState<boolean | undefined>();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isSidebarActive === true && sidebarRef.current && menuButtonRef.current && !sidebarRef.current.contains(event.target as Node) && !menuButtonRef.current.contains(event.target as Node)) {
        setIsSidebarActive(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [sidebarRef, menuButtonRef, setIsSidebarActive, isSidebarActive]);

  const goHome = () => {
    if (path !== '/') {
      navigate('/');
      setMenuVisible(false);
      setIsSidebarActive(false);
    }
  };

  const onMenuClick = (menu: SettingPages) => {
    switch (menu) {
      case 'Profile':
      case 'Settings':
      case 'Wallet':
      case 'History':
        navigate(`/settings`);
        dispatch(setActiveTab(menu));
        break;
      case 'Cash game':
        navigate('/cashgame');
        break;
      case 'Log out':
        dispatch(showModal(ModalType.LogoutModal));
        break;
    }
    setMenuVisible(false);
  };

  const onCreateTableClick = () => {
    dispatch(showModalWithRequest({ type: ModalType.CreateTableModal, data: { chip: undefined } }));
  };

  const showAuthModal = () => {
    setIsSidebarActive(false);
    dispatch(showModal(ModalType.AuthModal));
  };

  /**
   * 마우스 클릭 시 열린 헤더를 닫는 부분
   */
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setMenuVisible(false);
      }
    };
    if (isMobile) {
      document.removeEventListener('mousedown', handleClickOutside);
    } else {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const goLobby = () => {
    navigate('/cashgame');
    setIsSidebarActive(false);
  };

  const goTournament = () => {
    navigate('/tournament');
    setIsSidebarActive(false);
  };

  const getTabName = (tabName: SettingPages) => {
    switch (tabName) {
      case 'Profile':
        return translation('PROFILE.Profile');
      case 'Settings':
        return translation('SETTINGS.Settings');
      case 'Wallet':
        return translation('WALLET.Wallet');
      case 'History':
        return translation('HISTORY.History');
      case 'Log out':
        return translation('LOGOUT.LogOut');
    }
  };

  return (
    <>
      <HeaderInner>
        {isMobile && isMenuVisible && <MobileMenuTemplate setMenuVisible={setMenuVisible} onMenuClick={onMenuClick} onCreateTableClick={onCreateTableClick} onHomeClick={goHome} />}
        <StyledLeftHeaderContents>
          <div ref={menuButtonRef}>
            <StyledMenuLogo onClick={() => setIsSidebarActive(prev => !prev)}>
              <Menu2Icon />
            </StyledMenuLogo>
          </div>

          <StyledLogo onClick={goHome}>{isMobile ? <LogoIcon h={20} w={30} color={'primary500'} /> : <HeaderLogoIcon />}</StyledLogo>
          {!isMobile && (
            <StyledLinks direction={'row'} gap={32}>
              <StyledLink className={'link'} onClick={goLobby} isActive={path === 'cashgame'}>
                <CardsIcon color={path === 'cashgame' ? 'white' : 'gray400'} />
                <StyledText>{translation('HEADER.CashGame')}</StyledText>
              </StyledLink>
              {/*<StyledLink className={'link'} onClick={goTournament} isActive={path === 'tournament'}>*/}
              {/*  <TournamentIcon color={path === 'tournament' ? 'white' : 'gray400'} />*/}
              {/*  <StyledText>{translation('LANDING.Tournaments')}</StyledText>*/}
              {/*</StyledLink>*/}
            </StyledLinks>
          )}
        </StyledLeftHeaderContents>
        {user && isMobile && totalEstimatedBalance && <CashierButton amount={totalEstimatedBalance} />}
        <StyledRightHeaderContents>
          {user ? (
            <>
              {!isMobile && totalEstimatedBalance && <CashierButton amount={totalEstimatedBalance} />}
              <HeaderProfile>
                <HoverContainer
                  onClick={() => {
                    setMenuVisible(!isMenuVisible);
                    setIsSidebarActive(false);
                  }}
                >
                  <Avatar src={user?.profile} alt="Profile" />
                  {isMenuVisible && (
                    <Menus isVisible={isMenuVisible} ref={menuRef}>
                      {supportNavigation
                        .filter(page => (isMobile ? true : page !== 'Cash game'))
                        .map(item => (
                          <MenuItem key={item} onClick={() => onMenuClick(item)}>
                            {getTabName(item)}
                          </MenuItem>
                        ))}
                    </Menus>
                  )}
                </HoverContainer>
              </HeaderProfile>
            </>
          ) : (
            <StyledMainStartButton>
              <BaseButton color="primary" textColor="white" textStyle={isMobile ? 'TextSmall_Semibold' : 'TextMd_Semibold'} size={isMobile ? 'btn36' : 'btn44'} w={isMobile ? 101 : 121} onClick={showAuthModal} text={translation('BUTTON.GetStarted')} />
            </StyledMainStartButton>
          )}
        </StyledRightHeaderContents>
      </HeaderInner>
      <div ref={sidebarRef}>
        <SideNavigation path={path} isSidebarActive={isSidebarActive} onClose={() => setIsSidebarActive(false)} />
      </div>
    </>
  );
};

const StyledMenuLogo = styled.div`
  margin-right: 48px;
  display: flex;
  align-items: center;
  cursor: pointer;
  @media (max-width: 768px) {
    margin-right: 20px;
  }
`;

const StyledMainStartButton = styled.div`
  display: flex;
  gap: 24px;
  justify-content: end;
  align-items: center;

  @media (max-width: 768px) {
    position: absolute;
    right: 0;
    padding: 20px;
  }
`;

const StyledLinks = styled.div<{ direction: 'row' | 'column'; gap: number }>`
  display: flex;
  width: 100%;
  flex-direction: ${({ direction }) => direction};
  gap: ${({ gap }) => `${gap}px`};
  margin-left: ${({ direction }) => (direction === 'row' ? '60px' : '0')};
  margin-bottom: ${({ direction }) => (direction === 'row' ? '0' : '60px')};

  .link {
    gap: ${({ direction }) => (direction === 'column' ? '16px' : '8px')};
  }
`;
const StyledLink = styled.div<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  white-space: nowrap;
  color: ${({ theme, isActive }) => (isActive ? theme.colors.white : theme.colors.gray400)};

  &:hover {
    filter: brightness(1.3);
  }

  transition: filter 0.2s;
`;
const StyledText = styled.div`
  ${({ theme }) => theme.textStyles.TextMd_Semibold};
`;

export default MainHeader;
