import React, { useEffect } from 'react';
import LinearLayout from '../../layout/LinearLayout';
import HorizontalLayout from '../../layout/HorizontalLayout';
import Text from '../../common/text/Text';
import { ChevronDownIcon } from '../../common/icon';
import TextInput from '../../common/input/TextInput';
import BaseButton from '../../common/button/BaseButton';
import { LeftText } from '../../common/text/styles';
import CountryCodeSelect from '../../common/select/CountryCodeSelect';
import CountrySelect from '../../common/select/CountrySelect';
import styled from '@emotion/styled';
import { useUserProfile } from '../../../hooks/useUserProfile';
import { useCountryList } from '../../../hooks/useCountryList';
import { useUpdateKycMutation } from '../../../store/api/user';
import { showSuccessToast } from '../../common/toast/BpToast';
import useDevice from '../../../hooks/useDevice';
import { useTranslation } from 'react-i18next';

const KycTemplate = () => {
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
  const [selectedCountryCode, setSelectedCountryCode] = React.useState<string | undefined>(undefined);
  const [selectedCountry, setSelectedCountry] = React.useState<string | undefined>(undefined);
  const [phoneNumber, setPhoneNumber] = React.useState<string | undefined>(undefined);
  const [firstName, setFirstName] = React.useState<string | undefined>(undefined);
  const [lastName, setLastName] = React.useState<string | undefined>(undefined);
  const [dateOfBirth, setDateOfBirth] = React.useState<string | undefined>(undefined);
  const [residentialAddress, setResidentialAddress] = React.useState<string | undefined>(undefined);
  const [city, setCity] = React.useState<string | undefined>(undefined);
  const [postalCode, setPostalCode] = React.useState<string | undefined>(undefined);
  const [dateErrorMessage, setDateErrorMessage] = React.useState<string | undefined>('');

  const { user, kyc } = useUserProfile();
  const { t } = useTranslation();
  const countryList = useCountryList();
  const { isMobile } = useDevice();

  const canSave = Boolean(
    (phoneNumber ?? kyc?.bios.phoneNumber) &&
      (firstName ?? kyc?.bios.firstName) &&
      (lastName ?? kyc?.bios.lastName) &&
      (dateOfBirth ? dateOfBirth.length >= 10 && !dateErrorMessage : kyc?.bios.dateOfBirth) &&
      (residentialAddress ?? kyc?.address.address) &&
      (city ?? kyc?.address.city) &&
      (postalCode ?? kyc?.address.postalCode)
  );
  const showButtons = [phoneNumber, firstName, lastName, dateOfBirth, residentialAddress, city, postalCode, selectedCountryCode, selectedCountry].some(v => v !== undefined);
  const [requestUpdate] = useUpdateKycMutation();

  const handleCountryCodeChange = (code: string) => {
    setSelectedCountryCode(code);
  };
  const handleCountryChange = (code: string) => {
    setSelectedCountry(code);
  };
  const handlePhoneNumberChange = (phone: string) => {
    const sanitizedPhoneNumber = phone.replace(/[^0-9]/g, '');
    setPhoneNumber(sanitizedPhoneNumber);
  };
  const handleFirstNameChange = (name: string) => {
    const sanitizedFirstName = name.replace(/[^a-zA-Z]/g, '');
    setFirstName(sanitizedFirstName);
  };

  const handleLastNameChange = (name: string) => {
    const sanitizedLastName = name.replace(/[^a-zA-Z]/g, '');
    setLastName(sanitizedLastName);
  };

  const handleDateOfBirthChange = (date: string) => {
    let cleanedDate = date.replace(/[^0-9]/g, '');
    if (cleanedDate.length > 8) {
      cleanedDate = cleanedDate.slice(0, 8);
    }
    let formattedDate = '';
    if (cleanedDate.length > 4) {
      formattedDate = `${cleanedDate.slice(0, 2)}/${cleanedDate.slice(2, 4)}/${cleanedDate.slice(4)}`;
    } else if (cleanedDate.length > 2) {
      formattedDate = `${cleanedDate.slice(0, 2)}/${cleanedDate.slice(2)}`;
    } else {
      formattedDate = cleanedDate;
    }
    setDateOfBirth(formattedDate);
    if (formattedDate.length === 10) {
      checkDate(formattedDate);
    } else {
      setDateErrorMessage('');
    }
  };

  const checkDate = (date: string) => {
    const regex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
    const match = date.match(regex);

    if (!match) {
      setDateErrorMessage('Please enter a valid date');
      return;
    }

    const day = parseInt(match[1], 10);
    const month = parseInt(match[2], 10) - 1; // 월은 0부터 시작하므로 1을 뺌
    const year = parseInt(match[3], 10);

    const dateObj = new Date(year, month, day);
    const isValid = dateObj.getFullYear() === year && dateObj.getMonth() === month && dateObj.getDate() === day;

    if (!isValid) {
      setDateErrorMessage('Please enter a valid date');
      return;
    }

    const today = new Date();
    const age = today.getFullYear() - year;
    const monthDifference = today.getMonth() - month;
    const dayDifference = today.getDate() - day;

    const isOver18 = age > 18 || (age === 18 && (monthDifference > 0 || (monthDifference === 0 && dayDifference >= 0)));

    if (!isOver18) {
      setDateErrorMessage('You must be 18 or older');
      return;
    }
  };

  const handleResidentialAddressChange = (address: string) => {
    const sanitizedAddress = address.replace(/[^a-zA-Z0-9\s]/g, '');
    setResidentialAddress(sanitizedAddress);
  };

  const handleCityChange = (city: string) => {
    const sanitizedCity = city.replace(/[^a-zA-Z]/g, '');
    setCity(sanitizedCity);
  };

  const handlePostalCodeChange = (code: string) => {
    const sanitizedPostalCode = code.replace(/[^0-9]/g, '');
    setPostalCode(sanitizedPostalCode);
  };

  const resetFields = () => {
    setSelectedCountryCode(undefined);
    setSelectedCountry(undefined);
    setPhoneNumber(undefined);
    setFirstName(undefined);
    setLastName(undefined);
    setDateOfBirth(undefined);
    setResidentialAddress(undefined);
    setCity(undefined);
    setPostalCode(undefined);
  };

  const handleSave = () => {
    if (countryList.find(country => country.code === user?.country)?.callingCode && user?.country) {
      requestUpdate({
        bios: {
          firstName: firstName || kyc?.bios.firstName!,
          lastName: lastName || kyc?.bios.lastName!,
          dateOfBirth: dateOfBirth || kyc?.bios.dateOfBirth!,
          countryCode: selectedCountryCode || kyc?.bios.countryCode || countryList.find(country => country.code === user?.country)?.callingCode!,
          phoneNumber: phoneNumber || kyc?.bios.phoneNumber!
        },
        address: {
          address: residentialAddress || kyc?.address.address!,
          city: city || kyc?.address.city!,
          postalCode: postalCode || kyc?.address.postalCode!,
          country: selectedCountry || kyc?.address.country || user?.country!
        }
      }).then(() => {
        showSuccessToast('Profile details updated');
        resetFields();
      });
    }
  };

  return (
    <LinearLayout backgroundColor={'gray800'} borderRadius={12} padding={40} marginTop={8}>
      <StyledTrigger onClick={() => setIsExpanded(prev => !prev)}>
        <HorizontalLayout margin={'0'} gap={16}>
          <LinearLayout gap={4}>
            <Text textAlign={'start'} fontStyle={isMobile ? 'TextSmall_Semibold' : 'TextMd_Semibold'} text={t('PROFILE.ConfirmKyc')} />
            <Text textAlign={'start'} textColor={'gray400'} fontStyle={'TextSmall_Medium'} text={t('PROFILE.ConfirmKycDescription')} />
          </LinearLayout>
          <RotatingChevron isExpanded={isExpanded}>
            <ChevronDownIcon w={24} h={24} />
          </RotatingChevron>
        </HorizontalLayout>
      </StyledTrigger>
      {isExpanded && (
        <LinearLayout gap={16} marginTop={16}>
          <HorizontalLayout gap={isMobile ? 8 : 12} margin={'0'}>
            <StyledCountryCodeWrapper>
              <HorizontalLayout gap={4} margin={'0'} justifyContent={'start'}>
                <StyledLabel fontStyle={'TextMd_Medium'} text={t('PROFILE.CountryCode')} textColor={'gray300'} />
                <StyledLabel fontStyle={'TextMd_Medium'} text={'*'} textColor={'error500'} />
              </HorizontalLayout>
              <CountryCodeSelect value={selectedCountryCode || kyc?.bios.countryCode || user?.country || ''} onChange={code => handleCountryCodeChange(code)} />
            </StyledCountryCodeWrapper>
            <StyledInputWrapper>
              <HorizontalLayout gap={4} margin={'0'} justifyContent={'start'}>
                <StyledLabel fontStyle={'TextMd_Medium'} text={t('PROFILE.PhoneNumber')} textColor={'gray300'} />
                <StyledLabel fontStyle={'TextMd_Medium'} text={'*'} textColor={'error500'} />
              </HorizontalLayout>
              <TextInput value={phoneNumber ?? kyc?.bios.phoneNumber ?? ''} maxLength={15} onChange={e => handlePhoneNumberChange(e.target.value)} />
            </StyledInputWrapper>
          </HorizontalLayout>
          <HorizontalLayout gap={isMobile ? 8 : 12} margin={'0'}>
            <LinearLayout gap={isMobile ? 2 : 4}>
              <HorizontalLayout gap={4} margin={'0'} justifyContent={'start'}>
                <StyledLabel fontStyle={'TextMd_Medium'} text={t('PROFILE.FirstName')} textColor={'gray300'} />
                <StyledLabel fontStyle={'TextMd_Medium'} text={'*'} textColor={'error500'} />
              </HorizontalLayout>
              <TextInput value={firstName ?? kyc?.bios.firstName ?? ''} maxLength={50} onChange={e => handleFirstNameChange(e.target.value)} />
            </LinearLayout>
            <LinearLayout gap={isMobile ? 2 : 4}>
              <HorizontalLayout gap={4} margin={'0'} justifyContent={'start'}>
                <StyledLabel fontStyle={'TextMd_Medium'} text={t('PROFILE.LastName')} textColor={'gray300'} />
                <StyledLabel fontStyle={'TextMd_Medium'} text={'*'} textColor={'error500'} />
              </HorizontalLayout>
              <TextInput value={lastName ?? kyc?.bios.lastName ?? ''} maxLength={50} onChange={e => handleLastNameChange(e.target.value)} />
            </LinearLayout>
          </HorizontalLayout>
          <LinearLayout gap={isMobile ? 2 : 4}>
            <HorizontalLayout gap={4} margin={'0'} justifyContent={'start'}>
              <StyledLabel fontStyle={'TextMd_Medium'} text={t('PROFILE.DateOfBirth')} textColor={'gray300'} />
              <StyledLabel fontStyle={'TextMd_Medium'} text={'*'} textColor={'error500'} />
            </HorizontalLayout>
            <TextInput value={dateOfBirth ?? kyc?.bios.dateOfBirth ?? ''} onChange={e => handleDateOfBirthChange(e.target.value)} placeholder={'DD/MM/YYYY'} />
            {dateErrorMessage && <Text fontStyle={'TextSmall_Medium'} textColor={'error500'} textAlign={'start'} text={dateErrorMessage} marginTop={4} />}
          </LinearLayout>
          <LinearLayout gap={isMobile ? 2 : 4}>
            <HorizontalLayout gap={4} margin={'0'} justifyContent={'start'}>
              <StyledLabel fontStyle={'TextMd_Medium'} text={t('PROFILE.CountryCode')} textColor={'gray300'} />
              <StyledLabel fontStyle={'TextMd_Medium'} text={'*'} textColor={'error500'} />
            </HorizontalLayout>
            <CountrySelect value={selectedCountry || kyc?.address.country || (user?.country ?? '')} onChange={code => handleCountryChange(code)} />
          </LinearLayout>
          <LinearLayout gap={isMobile ? 2 : 4}>
            <HorizontalLayout gap={4} margin={'0'} justifyContent={'start'}>
              <StyledLabel fontStyle={'TextMd_Medium'} text={t('PROFILE.ResdientialAddress')} textColor={'gray300'} />
              <StyledLabel fontStyle={'TextMd_Medium'} text={'*'} textColor={'error500'} />
            </HorizontalLayout>
            <TextInput value={residentialAddress ?? kyc?.address.address ?? ''} maxLength={100} onChange={e => handleResidentialAddressChange(e.target.value)} />
          </LinearLayout>
          <HorizontalLayout gap={isMobile ? 8 : 12} margin={'0'}>
            <LinearLayout gap={isMobile ? 2 : 4}>
              <HorizontalLayout gap={4} margin={'0'} justifyContent={'start'}>
                <StyledLabel fontStyle={'TextMd_Medium'} text={t('PROFILE.City')} textColor={'gray300'} />
                <StyledLabel fontStyle={'TextMd_Medium'} text={'*'} textColor={'error500'} />
              </HorizontalLayout>
              <TextInput value={city ?? kyc?.address.city ?? ''} maxLength={50} onChange={e => handleCityChange(e.target.value)} />
            </LinearLayout>
            <LinearLayout gap={isMobile ? 2 : 4}>
              <HorizontalLayout gap={4} margin={'0'} justifyContent={'start'}>
                <StyledLabel fontStyle={'TextMd_Medium'} text={t('PROFILE.PostalCode')} textColor={'gray300'} />
                <StyledLabel fontStyle={'TextMd_Medium'} text={'*'} textColor={'error500'} />
              </HorizontalLayout>
              <TextInput height={52} value={postalCode ?? kyc?.address.postalCode ?? ''} maxLength={20} onChange={e => handlePostalCodeChange(e.target.value)} />
            </LinearLayout>
          </HorizontalLayout>
          {showButtons && (
            <HorizontalLayout gap={isMobile ? 8 : 12} margin={'0'} justifyContent={'end'}>
              <BaseButton w={124} color={'text'} textStyle={isMobile ? 'TextSmall_Semibold' : 'TextMd_Semibold'} text={t('BUTTON.Cancel')} size={isMobile ? 'btn40' : 'btn48'} onClick={resetFields} />
              <BaseButton w={124} text={t('BUTTON.Save')} textStyle={isMobile ? 'TextSmall_Semibold' : 'TextMd_Semibold'} color={'primary'} size={isMobile ? 'btn40' : 'btn48'} disabled={!canSave} onClick={handleSave} />
            </HorizontalLayout>
          )}
        </LinearLayout>
      )}
    </LinearLayout>
  );
};

const StyledCountryCodeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  gap: 4px;
  @media (max-width: 768px) {
    gap: 2px;
  }
`;
const StyledInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
  @media (max-width: 768px) {
    gap: 2px;
  }
`;

const StyledTrigger = styled.div`
  cursor: pointer;
  width: 100%;
  div {
    cursor: pointer;
  }
`;

const StyledLabel = styled(Text)`
  ${({ theme }) => theme.textStyles.TextMd_Medium};
  text-align: start;
  align-self: start;
  user-select: none;
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextXs_Medium};
  }
`;

const RotatingChevron = styled.div<{ isExpanded: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  transform: ${({ isExpanded }) => (isExpanded ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s ease;
`;
export default KycTemplate;
