import React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as AlertIcon } from 'src/assets/img/alert.svg';

import HorizontalLayout from '../../layout/HorizontalLayout';
import { useDispatch } from 'react-redux';
import Text from '../../common/text/Text';
import theme from '../../../styles/theme';
import { dismissModal } from '../../../store/slices/modalSlice';
import { useTranslation } from 'react-i18next';

const TableDisconnectedModal: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onCancel = () => {
    dispatch(dismissModal());
  };

  const onConfirm = () => {
    dispatch(dismissModal());
    window.location.reload();
  };

  return (
    <Background>
      <AlertIcon />
      <Text fontStyle={'TextXl_Semibold'} textAlign={'start'} text={t('MODAL.RefreshTitle')} />
      <Text fontStyle={'TextMd_Medium'} textAlign={'start'} textColor={'gray300'} text={t('MODAL.RefreshDes')} />
      <HorizontalLayout margin={'32px 0 0 0'} gap={12}>
        <CancelButton onClick={onCancel}>{t('BUTTON.Cancel')}</CancelButton>
        <ConfirmButton onClick={onConfirm}>{t('MODAL.Refresh')}</ConfirmButton>
      </HorizontalLayout>
    </Background>
  );
};

export default TableDisconnectedModal;

const Background = styled.div`
  width: 500px;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray800};
  padding: 40px;
  border-radius: 12px;
  gap: 16px;
  @media (max-width: 768px) {
    padding: 20px;
    width: 80vw;
  }
`;

const Button = styled.button`
  ${({ theme }) => theme.textStyles.TextMd_Semibold};
  color: ${({ theme }) => theme.colors.white};
  flex: 1;
  min-height: 48px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
`;

const ConfirmButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.gray700};
  box-shadow: 0 0 0 1px ${theme.colors.gray700};
  transition: background-color 0.1s;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray500};
  }
`;

const CancelButton = styled(Button)`
  background-color: transparent;
  box-shadow: 0 0 0 1px ${theme.colors.gray500};
  transition: background-color 0.1s;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray500};
  }
`;
