import React from 'react';
import styled from '@emotion/styled';

import CopyableBox from 'src/components/common/text/CopyableBox';
import Text from 'src/components/common/text/Text';
import useShareLink from './useWaitingMessage';
import { TableStatus } from '../../../../store/api/responseTypes';
import { useTableContext } from '../../../../hooks/TableProvider';
import { useTranslation } from 'react-i18next';

const WaitingMessage = () => {
  const { isOwner, isWaiting, tableStatus, mySeatStatus } = useTableContext();
  const { shareUrl, onSocialClick, socialIcons } = useShareLink();
  const { t } = useTranslation();

  const centerMessage = React.useMemo(() => {
    switch (tableStatus) {
      case TableStatus.CLOSED:
        return t('MESSAGE.CashgameClosed');
      case TableStatus.WAITING:
        return isOwner ? t('MESSAGE.ShareTheLink') : t('MESSAGE.CashgameWillStart');
      case TableStatus.PAUSED:
        return t('MESSAGE.CashgamePaused');
      case TableStatus.PLAYING:
      default:
        return '';
    }
  }, [isOwner, tableStatus]);

  if (!isOwner && !mySeatStatus) {
    return <></>;
  } else if (tableStatus === TableStatus.PAUSED || tableStatus === TableStatus.WAITING || tableStatus === TableStatus.CLOSED) {
    return (
      <Wrap>
        <StyledText text={centerMessage} fontStyle="TextLg_Bold" />
        {tableStatus === 'WAITING' && isOwner && (
          <>
            <StyledCopyableBox copyableValue={shareUrl} backgroundColor={'gray800'} />
            <SocialIcons>
              {Object.keys(socialIcons).map((key, i) => (
                <SocialButton key={i} onClick={() => onSocialClick(key)}>
                  {socialIcons[key as 'facebook' | 'twitter' | 'telegram']}
                </SocialButton>
              ))}
            </SocialIcons>
          </>
        )}
      </Wrap>
    );
  } else {
    return <></>;
  }
};

const SocialButton = styled.div`
  cursor: pointer;
`;

const StyledCopyableBox = styled(CopyableBox)`
  padding: 0.75em 1.5em;
  width: 35em;
  box-sizing: border-box;
  @media (max-width: 768px) {
    width: 100%;
    border-radius: 8px;
    padding: 12px 16px;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 0.75em;
`;

const StyledText = styled(Text)`
  line-height: 1.5em;
  font-size: 1.125em;
  color: ${({ theme }) => theme.colors.white};
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const Wrap = styled.div`
  position: absolute;
  z-index: 2;
  /* padding: 0 6.6875em; */
  gap: 0.5em;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 10.25em; */
  width: 45em;
  padding: 2.5em 0;
  background-color: ${({ theme }) => theme.colors.gray900};
  filter: drop-shadow(0px 8px 12px rgba(0, 0, 0, 0.25));
  will-change: filter;
  top: 50%;
  left: 50%;
  border-radius: 12.5em;
  transform: translate(-50%, -50%);
  @media (max-width: 768px) {
    border-radius: 12px;
    padding: 24px 16px;
    height: auto;
    z-index: 100;
    gap: 12px;
    width: 70%;
    top: 47%;
    transform: translate(-50%, -85%);
  }
`;

export default WaitingMessage;
