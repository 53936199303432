import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { HeaderLogoIcon } from 'src/components/common/icon';
import TableStyleSelector from './TableStyleSelector';
import DeckStyleSelector from './DeckStyleSelector';
import LinearLayout from '../../layout/LinearLayout';
import ToggleSwitch from '../../common/switch/ToggleSwitch';
import HorizontalLayout from '../../layout/HorizontalLayout';
import ReserveSitOut from '../room/reserveSitout';
import { XCloseIcon } from '../../common/icon/icons/XCloseIcon';
import { setViewportHeight } from '../../../utils/ViewPortHeight';
import BaseButton from '../../common/button/BaseButton';
import { SeatStatus } from '../../../store/api/responseTypes';
import { useTableContext } from '../../../hooks/TableProvider';
import { useTranslation } from 'react-i18next';

interface MobileSettingTemplateProps {
  isAddChipAvailable: boolean;
  isStandAvailable: boolean;
  hasSound: boolean;
  toggleSound: () => void;
  isBlindView: boolean;
  toggleBlindView: () => void;
  setSettingVisible: React.Dispatch<React.SetStateAction<boolean>>;
  requestAddChips: () => void;
  requestStand: () => void;
}

const MobileSettingTemplate: React.FC<MobileSettingTemplateProps> = ({ isAddChipAvailable, isStandAvailable, isBlindView, toggleBlindView, hasSound, toggleSound, setSettingVisible, requestAddChips, requestStand }) => {
  const { t } = useTranslation();
  const { reservedSitOut, mySeatStatus, tableSettings } = useTableContext();
  useEffect(() => {
    setViewportHeight();
    window.addEventListener('resize', setViewportHeight);
    return () => window.removeEventListener('resize', setViewportHeight);
  }, []);
  return (
    <Wrap>
      <Top>
        <HeaderLogoIcon />
        <XCloseIcon onClick={() => setSettingVisible(false)} />
      </Top>
      <SpaceBetweenWraps>
        <LinearLayout>
          <HorizontalLayout width={'calc(100vw - 40px)'} margin={'20px 0 0 0'} borderRadius={12} backgroundColor={'gray700'}>
            <SettingItem>
              <StyledTitle>{t('SETTINGS.Sound')}</StyledTitle>
              <ToggleSwitch isActive={hasSound} onClick={toggleSound} />
            </SettingItem>
          </HorizontalLayout>
          <LinearLayout width={'calc(100vw - 40px)'} marginTop={8} borderRadius={12} backgroundColor={'gray700'} padding={40} gap={24}>
            <DeckStyleSelector tableId={tableSettings?.tableId} />
            <TableStyleSelector tableId={tableSettings?.tableId} />
            <HorizontalLayout margin={'0'}>
              <StyledTitle>{t('SETTINGS.DisplayStackBB')}</StyledTitle>
              <ToggleSwitch isActive={isBlindView} onClick={toggleBlindView} />
            </HorizontalLayout>
          </LinearLayout>
          <ReserveSitOut />
        </LinearLayout>
        {!reservedSitOut && mySeatStatus !== SeatStatus.STAND && mySeatStatus !== undefined && (
          <BaseButton
            onClick={() => {
              setSettingVisible(false);
              requestStand();
            }}
            gap={8}
            leftIcon="LoginIcon"
            iconSize={16}
            color={'gray'}
            size={'btn40'}
            textColor="gray200"
            textStyle="TextMd_Semibold"
            text={t('ACTIONS.Stand')}
          />
        )}
      </SpaceBetweenWraps>
    </Wrap>
  );
};

const Wrap = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  top: 0;
  left: 0;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  z-index: 1000;
  background-color: ${({ theme }) => theme.colors.gray800};
  padding: 20px;
  box-sizing: border-box;
`;

const Top = styled.div`
  box-sizing: border-box;
  width: calc(100vw - 40px);
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SettingItem = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  padding: 20px;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  box-sizing: border-box;
`;

const StyledTitle = styled.label`
  ${({ theme }) => theme.textStyles.TextSmall_Medium};
  color: ${({ theme }) => theme.colors.white};
`;

const SpaceBetweenWraps = styled.div`
  justify-content: space-between;
  flex-direction: column;
  display: flex;
  flex: 1;
`;

export default MobileSettingTemplate;
