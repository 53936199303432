import React, { useEffect } from 'react';
import ChatNav from './ChatNav';
import styled from '@emotion/styled';
import Chat from './Chat';
import Note from './Note';
import SeedPhrase from './SeedPhrase';
import useDevice from 'src/hooks/useDevice';
import { useUserTableSetting } from '../../../../hooks/useUserTableSetting';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { selectedUser } from '../../../../store/slices/streaming';
import { useTableId } from '../../../../hooks/useTableId';

export type SupportChatTab = 'Chat' | 'Note' | 'Seed' | 'Hide';

const ChatBox = () => {
  const { isMobile, isTablet, isSmallDesktop } = useDevice();
  const tableId = useTableId();
  const selectedUserData = useSelector((state: RootState) => selectedUser(state, tableId));
  const setting = useUserTableSetting();
  const [tab, setChatTab] = React.useState<SupportChatTab>(isSmallDesktop ? 'Hide' : 'Chat');

  useEffect(() => {
    setChatTab(isSmallDesktop ? 'Hide' : setting?.isChatEnabled ? 'Chat' : 'Note');
  }, [setting?.isChatEnabled]);

  useEffect(() => {
    if (selectedUserData?.userId === undefined) return;
    setChatTab('Note');
  }, [selectedUserData?.userId, selectedUserData?.timestamp]);

  return (
    <ChatContainer isHide={tab === 'Hide'}>
      <ChatNav useChat={setting?.isChatEnabled ?? true} selectedTab={tab} setSelectedTab={setChatTab} />
      {!isMobile && tab !== 'Hide' && (
        <ContentWrap>
          {tab === 'Chat' && <Chat />}
          {tab === 'Note' && <Note />}
          {tab === 'Seed' && <SeedPhrase />}
        </ContentWrap>
      )}
    </ChatContainer>
  );
};

const ChatContainer = styled.div<{ isHide: boolean }>`
  width: 520px;
  box-sizing: border-box;
  gap: 16px;
  display: flex;
  padding: 0 0 16px 16px;
  @media (max-width: 2560px) {
    width: 412px;
  }
  @media (max-width: 1440px) {
    width: 332px;
  }
  @media (max-width: 1280px) {
    width: 312px;
    ${({ isHide }) => !isHide && 'background-color: rgba(16, 24, 40, 0.8);'};
    border-top-right-radius: 8px;
    padding: 12px;
  }

  @media (max-width: 768px) {
    min-width: unset;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    flex-direction: column;
    padding: 0;
    background-color: transparent;
  }
`;

const ContentWrap = styled.div`
  display: flex;
  align-items: flex-end;
  flex: 1;
  width: 100%;
  //min-height: 192px;
  align-self: end;
  max-width: 80%;
`;

export default ChatBox;
