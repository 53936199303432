import React from 'react';
import styled from '@emotion/styled';
import Text from '../../common/text/Text';
import { useDispatch } from 'react-redux';
import LinearLayout from '../../layout/LinearLayout';
import { dismissModal } from '../../../store/slices/modalSlice';
import HorizontalLayout from '../../layout/HorizontalLayout';
import { XCloseIcon } from '../../common/icon/icons/XCloseIcon';
import TablePreview from '../settings/TablePreview';
import useTable from '../../../hooks/useTable';
import { bpSocketService } from '../../../services/BpWebSocketService';
import { useTranslation } from 'react-i18next';

interface TablePreviewModalProps {
  tableId: number;
}

const TablePreviewModal: React.FC<TablePreviewModalProps> = ({ tableId }) => {
  const dispatch = useDispatch();
  const { tableSettings } = useTable(tableId);
  const { t } = useTranslation();

  const onRequestClose = () => {
    bpSocketService.offAll();
    dispatch(dismissModal());
  };

  return (
    <Background>
      <LinearLayout borderRadius={12} gap={16}>
        <HorizontalLayout margin={'0 0 16px 0'}>
          <Text fontStyle={'TextXl_Semibold'} text={tableSettings?.name ?? t('CASHGAME.TableInfo')} textAlign={'start'} />
          <XCloseIcon onClick={onRequestClose} />
        </HorizontalLayout>
        <TablePreview tableId={tableId} />
      </LinearLayout>
    </Background>
  );
};

const Background = styled.div`
  width: 361px;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray800};
  padding: 32px;
  border-radius: 12px;
  gap: 16px;
  @media (max-width: 768px) {
    padding: 20px;
    width: 100%;
  }
`;

export default TablePreviewModal;
