import React from 'react';
import styled from '@emotion/styled';
import Text from '../../common/text/Text';
import { useDispatch } from 'react-redux';
import { dismissModal } from '../../../store/slices/modalSlice';
import Chat from '../room/chatBox/Chat';
import { XCloseIcon } from '../../common/icon/icons/XCloseIcon';
import { useTranslation } from 'react-i18next';

const ChatModal: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <Wrap>
      <Top>
        <Text fontStyle={'TextMd_Semibold'} textAlign={'center'} text={t('FEATURES.ChatTitle')} />
        <XCloseIcon onClick={() => dispatch(dismissModal())} />
      </Top>
      <Chat />
    </Wrap>
  );
};

const Wrap = styled.div`
  display: flex;
  flex: 1;
  height: 98%;
  padding-bottom: 2%;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  background-color: ${({ theme }) => theme.colors.gray800};
  overflow: clip;
`;

const Top = styled.div`
  top: 0;
  z-index: 5;
  background-color: ${({ theme }) => theme.colors.gray800};
  position: absolute;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  height: 56px;
`;
export default ChatModal;
