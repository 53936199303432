import React from 'react';
import { IconProps } from '../types';
import { Colors } from 'src/styles/Colors';

export const Globe = React.memo<IconProps>(({ w = 20, h = 20, color = 'gray400' }) => (
  <svg width={w} height={h} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.9987 0.833252C15.0613 0.833252 19.1654 4.93731 19.1654 9.99992C19.1654 15.0625 15.0613 19.1666 9.9987 19.1666C4.93609 19.1666 0.832031 15.0625 0.832031 9.99992C0.832031 4.93731 4.93609 0.833252 9.9987 0.833252ZM2.54447 9.16658C2.8887 6.05261 5.13937 3.51323 8.10345 2.74144C6.83128 4.6536 6.06061 6.86393 5.87377 9.16658H2.54447ZM2.54447 10.8333C2.8887 13.9472 5.13937 16.4866 8.10345 17.2584C6.83128 15.3462 6.06061 13.1359 5.87377 10.8333H2.54447ZM11.8939 17.2584C14.858 16.4866 17.1087 13.9472 17.4529 10.8333H14.1236C13.9368 13.1359 13.1661 15.3462 11.8939 17.2584ZM17.4529 9.16658H14.1236C13.9368 6.86393 13.1661 4.6536 11.8939 2.74144C14.858 3.51323 17.1087 6.05261 17.4529 9.16658ZM9.9987 2.95892C11.3901 4.75153 12.2414 6.90561 12.4507 9.16658H7.54669C7.75602 6.90561 8.6073 4.75153 9.9987 2.95892ZM9.9987 17.0409C8.6073 15.2483 7.75602 13.0942 7.54669 10.8333H12.4507C12.2414 13.0942 11.3901 15.2483 9.9987 17.0409Z"
      fill={Colors[color]}
    />
  </svg>
));

Globe.displayName = 'Globe';
