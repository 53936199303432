import React from 'react';
import { IconProps } from '../types';
import { Colors } from 'src/styles/Colors';

export const Menu2Icon = React.memo<IconProps>(({ w = 24, h = 24, color = 'gray300' }) => (
  <svg width={w} height={h} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 12H21M3 6H21M3 18H15" stroke={Colors[color]} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
));

Menu2Icon.displayName = 'Menu2';
