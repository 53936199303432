import React from 'react';
import Text from '../../common/text/Text';
import LinearLayout from '../../layout/LinearLayout';
import useDevice from '../../../hooks/useDevice';
import { StyledPolicyLink, StyledPolicyText } from './styles';

const TermsOfService = () => {
  const { isMobile } = useDevice();

  return (
    <LinearLayout backgroundColor={'gray800'} borderRadius={12} padding={40} gap={24}>
      <Text lineHeight={20} fontStyle={isMobile ? 'TextMd_Bold' : 'TextLg_Bold'} textColor={'white'} text={'Terms of Service'} textAlign={'start'} />
      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'BASEPOKER.COM'} />
        <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'Last updated: June 17th, 2024'} />
        <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'Version TS20240617.1'} />
        <Text
          lineHeight={20}
          fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
          textAlign={'start'}
          textColor={'white'}
          text={
            'IMPORTANT: PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING OUR SERVICES. YOUR AGREEMENT TO THESE TERMS IS LEGALLY BINDING. IT IS YOUR RESPONSIBILITY TO REVIEW THIS TERMS & CONDITIONS PERIODICALLY, AND CONTINUED USE OF THE SITE AND SERVICES CONSTITUTES YOUR ACCEPTANCE OF THESE TERMS.'
          }
        />
      </LinearLayout>
      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Introduction'} />
        <LinearLayout gap={8}>
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={
              'These updated terms and conditions ("Conditions") govern the relationship between "You" (also referred to as "Your") and Base International Limited and/or its group companies (collectively "we", "us", or "our"). These Conditions apply to all software licensed by us and your use of the game client and Basepoker ("Software").'
            }
          />
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={
              'Basepoker is operated by Base International Limited ("The Company"), a company incorporated in the Isle of Man with its registered office at The Hubb, Queen Victoria Street, Douglas, IM1 2LF, Isle of Man. We are licensed and regulated by the Isle of Man Gambling Supervision Commission. The Company offers online poker services ("Services") through its brand "Basepoker" and its website www.basepoker.com ("Website"). Our services may also be available through alternative mediums such as mobile devices, and these Conditions apply to such use where applicable.'
            }
          />
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={
              'By using the Website and participating in any games ("Game(s)") available thereon, you agree to these Conditions, which are legally binding. If you do not agree to these terms, please do not open an account or continue to use the Website or participate in the Games. These Conditions define the rights and responsibilities you and we have with respect to the Games, the Website, and Basepoker. You confirm that you have the right, authority, and capacity to accept these Conditions and to comply with them, and that you have read and understood these Conditions fully.'
            }
          />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'By using, visiting, registering on the Website, and/or continuing to use the Services, you agree to be bound by:'} />
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={
              '• Terms & Conditions\\n• Privacy Policy\\n • Cookie Policy (detailing how we use personal information you provide)\\n • House Rules\\n • Any other applicable terms, conditions, game rules, or policies related to promotions, bonuses, special offers, or any aspect of your use of the Website'
            }
          />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'In case of any conflict between these Conditions and the House Rules, these terms and conditions shall prevail.'} />
        </LinearLayout>
      </LinearLayout>
      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Changes to the Terms and Conditions'} />
        <LinearLayout gap={8}>
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={
              '1.1 We may need to amend the Conditions for several reasons, including but not limited to commercial reasons, compliance with new laws or regulations, or customer service improvements. The most current terms and conditions will always be accessible here, with the date they came into force noted.'
            }
          />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'1.2 If we make substantial changes to the Terms and Conditions, we will notify you as soon as reasonably practicable through one of the following methods:'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'• Email (to the email address you provided)\\n• A notice on the Website'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'1.3 It remains your responsibility to periodically check these Conditions to ensure continued agreement each time you use the Website and Services.'} />
        </LinearLayout>
      </LinearLayout>
      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Account Opening and Security'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'2.1 To register, open an account, and participate in the Games, you represent and warrant that:'} />
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={
              ' • You are at least 18 years old and meet the minimum age requirement in your local jurisdiction;\\n' +
              ' • You are not an employee or contractor of The Company or its related group company;\\n' +
              ' • You will always comply with these Conditions and any other agreements with us;\\n' +
              ' • You are legally capable of entering into contracts;\\n' +
              ' • You have not been excluded or are not currently self-excluded from gambling;\\n' +
              ' • You have not had your account previously closed by us for any reason;\\n' +
              ' • You acknowledge it is your responsibility to comply with applicable laws or regulations in your country or state;'
            }
          />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={' • Email (to the email address you provided)\\n • A notice on the Website'} />
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={'2.2 If you do not meet these eligibility requirements, you are not authorized to use the Website. We reserve the right to suspend or close any accounts that do not meet these requirements and void any bets made through such accounts.'}
          />
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={
              '2.3 You are only permitted to open one account. If you have multiple accounts, you must inform us immediately. If we find multiple accounts registered with your personal information, we reserve the right to block or close any or all such accounts, and any winnings from these accounts may be forfeited.'
            }
          />
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={'2.4 Any returns, winnings, or bonuses accrued during the time duplicate accounts were active will be forfeited and may be reclaimed by us. Additionally, an administrative fee may be applied.'}
          />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'2.5 You must keep your account details up-to-date.'} />
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={
              '2.6 If you wish to close your account, you must request this by emailing help@basepoker.com or using the permanent exclusion feature within the application. Closing your account will result in the forfeiture of any promotional bonuses, prizes, or benefits. If your account is closed due to gambling addiction or fraud, you are prohibited from opening a new account.'
            }
          />
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={'2.7 If you close your account due to gambling addiction and subsequently open a new account, the Company will not be liable for any actions or damages resulting from such behavior.'}
          />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'2.8 The Company reserves the right to close any new account opened in violation of these requirements.'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'2.9 You must provide all mandatory information requested by us accurately. We are not liable for incorrect entries.'} />
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={'2.10 You must not open an account if you are in a jurisdiction where it is unlawful to do so. We reserve the right to suspend your account if we discover it has been opened or used from a prohibited jurisdiction. Proof of age documentation may be requested at any time.'}
          />
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={'2.11 We do not accept players from certain countries and jurisdictions. If your country of residence is not available during registration, we cannot accept you as a player.'}
          />
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={'2.12 We may suspend or terminate your account at our absolute discretion if you breach any obligations under these Conditions. We reserve the right to retain any remaining balance in your account and to claim damages for the breach.'}
          />
        </LinearLayout>
      </LinearLayout>
      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Verification of your Identity; Anti-money Laundering Requirements'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'3.1 You warrant that:'} />
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={' • You are not younger than 18 years old or the legal age for gambling in your jurisdiction;\\n' + ' • The personal details provided during account opening are accurate;\\n' + ' • Any deposited funds are not derived from illegal activities;'}
          />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'3.2 All player transactions on our site are monitored to prevent money laundering and other illegal activities.'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'3.3 By agreeing to these terms, you authorize us to undertake verification checks to confirm your identity and contact details.'} />
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={
              '3.4 We must collect an ID document and proof of address if your withdrawal exceeds 10,000 USDT (or equivalent in other currencies) or if cumulative payments exceed this amount within 30 days. Acceptable ID includes a government-issued photo ID such as a passport or driving license, and a document proving residential address such as a utility bill dated within the last 90 days.'
            }
          />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'3.5 Withdrawals may be delayed if you reach 10,000 USDT threshold within the 30-day period pending full verification.'} />
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={'3.6 We may contact you directly for additional information to complete verification checks. Failure to provide the required information may result in suspension or permanent closure of your account.'}
          />
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={'3.7 If age verification fails, your account will remain frozen, and no gambling will be permitted until successful verification. If underage gambling is detected, all transactions will be voided, and deposited funds will be returned within five working days.'}
          />
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Risk'} />
        <LinearLayout gap={8}>
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={
              '4.1 Participation in the Game is at your own risk. You understand that playing real money games is speculative and involves risk. You acknowledge that you are financially prepared to handle such risks and can sustain the total loss of any bets you place. You are solely responsible for the information you provide, and we are not liable for incorrect entries.'
            }
          />
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={'4.2 It is your responsibility to ensure that all transaction details on Basepoker are correct. Transaction history is accessible on the Website. Once a transaction is confirmed by you, it cannot be canceled.'}
          />
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={'4.3 We reserve the right to refuse any transaction at our discretion. A transaction is not accepted until we confirm its acceptance. If you do not receive confirmation, contact our customer service at help@basepoker.com.'}
          />
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Your Basepoker Account'} />
        <LinearLayout gap={8}>
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={'5.1 Only bets placed from your account via basepoker.com will be accepted. Winnings will be credited to your account. Deposits and withdrawals are facilitated through the "Wallet" page inside “My Page” or “Cashier in the Website.'}
          />
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={'5.2 We reserve the right to refuse or limit any bets and/or payouts at our discretion. If a bet is declared void, the sum deducted from your account will be credited back.'}
          />
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={
              '5.3 Bets are valid only if accepted by our server. Until then, communications from you are not binding, and all displayed information on Basepoker constitutes an invitation to play. Our records are the final authority in determining the terms and circumstances of any bets you place.'
            }
          />
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={'5.4 Your account is personal to you. Do not allow others to use your account or accept any prizes or participate in games on your behalf. Violations will result in account suspension.'}
          />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'5.5 It is your responsibility to ensure the accuracy of account details. The Company is not responsible for incorrect entries.'} />
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={
              '5.6 You may not engage in criminal activities involving your account or participate in fraudulent, collusive, or cheating practices. Such actions will lead to account closure, and any winnings will be forfeited. We reserve the right to inform relevant authorities and suspend accounts involved in such activities. All account balances will be forfeited.'
            }
          />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'5.7 Account balances are not interest-bearing, and you should not consider Basepoker as a financial institution.'} />
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={'5.8 You are responsible for maintaining the confidentiality of your login credentials. Any actions taken using your account are your responsibility. Notify us immediately of any suspected unauthorized use of your account.'}
          />
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Customer Fund Protection'} />
        <LinearLayout gap={8}>
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={
              '6.1 Basepoker.com maintains funds equivalent to customer liabilities in the Designated Account, solely used for holding player balances. This account is not used for operational purposes and undergoes third-party audits to verify alignment with player balances, ensuring readiness for withdrawals.'
            }
          />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'6.2 Funds in your account do not accrue interest.'} />
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Restrictions'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'7.1 Users agree not to use Basepoker.com:'} />
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={
              ' • Beyond its intended purpose.\\n' +
              ' • To distribute, sell, license, or provide services to third parties without consent.\\n' +
              ' • To store or transmit infringing, libelous, defamatory, obscene, fraudulent, or unlawful material, or material violating any intellectual property or privacy rights.\\n' +
              ' • In violation of applicable laws, rules, or regulations.\\n' +
              ' • To transmit viruses, malware, or other malicious code.'
            }
          />
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={"7.2 Modification, reverse-engineering, or interference with Basepoker.com's software is prohibited. Basepoker.com reserves the right to block users from certain IP addresses to preserve Website and Games integrity."}
          />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'7.3 Basepoker.com may, at its discretion and without obligation to provide reasons, exclude users from Services or selected promotions.'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={"7.4 The Website and/or Games may be suspended, discontinued, modified, removed, or added to at Basepoker.com's discretion, without prior notice."} />
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Deposits'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'8.1 Deposits can be made via cryptocurrencies listed in the cashier page.'} />
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={'8.2 Users must deposit cryptocurrencies into their account to participate in betting or gaming. Basepoker.com may conduct verification checks on player deposits for regulatory compliance.'}
          />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'8.3 Minimum deposit requirements per method are detailed on the cashier page.'} />
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={"8.4 Deposits are made by transferring funds to Basepoker.com's account using accepted payment methods. Basepoker.com reserves the right to change accepted payment methods and conditions at its discretion, without warranty of availability at all times."}
          />
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Withdrawals'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'9.1 Withdrawal amounts may be subject to minimum and maximum limits based on the payment method or account limits.'} />
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={
              '9.2 Upon approval of a withdrawal request, users must provide necessary transfer details. Withdrawals are processed to the originating account unless proof of lost or canceled payment method is provided. An administration fee may apply to withdrawals of funds not used in gameplay.'
            }
          />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'9.3 Users are responsible for reporting winnings and losses to local tax authorities.'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'9.4 Additional information may be required before processing withdrawals.'} />
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Help Funds'} />
        <LinearLayout gap={8}>
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={'10.1 In cases of suspicious or collusive play, Basepoker.com reserves the right to block access to accounts and suspend fund withdrawals indefinitely for investigation.'}
          />
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Promotions'} />
        <LinearLayout gap={8}>
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={'11.1 Periodically, Basepoker.com offers bonuses and promotions governed by separate terms and conditions. Bonus credits must be used according to these terms and conditions.'}
          />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'11.2 In case of conflict between these Conditions and specific bonus or promotion terms, the latter prevail.'} />
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={'11.3 Basepoker.com may deny, withhold, or withdraw bonuses or promotions if abuse or personal benefit is suspected, and may terminate access to the Website and/or accounts. Original deposits may be the only balance refunded in such cases.'}
          />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'11.4 By participating in promotions, users agree to cooperate in advertising, marketing, and publicity activities as required by Basepoker.com.'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'11.5 Participants in sponsored events must not display or wear third-party branding or advertising of competitors. Violations may result in revoked prizes.'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'11.6 Participants in promotions confirm that submitted materials are original and do not infringe intellectual property rights.'} />
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Suspension, Restriction, or Termination of Account'} />
        <LinearLayout gap={8}>
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={'12.1 Basepoker.com may suspend, terminate, or take other actions at its discretion, including canceling bets, removing bonus funds, and prohibiting gameplay, if:'}
          />
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={' • Illegal or fraudulent activity is suspected.\\n' + ' • These Conditions or House Rules are violated.\\n' + " • Conduct detrimental to Basepoker.com's business occurs.\\n" + ' • Legal requirements mandate action.'}
          />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'12.2 Upon account termination, the sole remedy is reimbursement of any undisputed account balance. Basepoker.com bears no further liability.'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'12.3 Additional restrictions may apply during system, communication, or technological errors or failures as outlined in paragraphs 16 and 17.'} />
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Security Checks'} />
        <LinearLayout gap={8}>
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={'13.1 Basepoker.com implements security procedures to protect customers and the Website from fraud and collusion. Periodic checks may suspend account usage for verification purposes.'}
          />
        </LinearLayout>
      </LinearLayout>
      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Prohibited Uses of the Website and Services'} />
        <LinearLayout gap={8}>
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={
              "14.1 The Game must be used lawfully and in compliance with all applicable laws, statutes, and regulations. All bets must be placed through Basepoker.com's interface. Basepoker.com reserves the right to prohibit player participation, freeze chips, void bets, suspend or terminate accounts suspected of cheating, including collusion or software exploitation. Seating restrictions may be imposed, and players may be prohibited from playing together."
            }
          />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={"14.2 Attempts to circumvent Basepoker.com's security measures are prohibited. Breach may result in immediate termination of Services."} />
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Game Rules'} />
        <LinearLayout gap={8}>
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={'15.1 By participating in any game on Basepoker.com, you agree to abide by these Game Rules as well as all other terms and conditions set forth by Basepoker.com.'}
          />
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={'15.2 You must be of legal age in your jurisdiction to participate in our games. It is your responsibility to ensure that you comply with all laws and regulations applicable to online gaming in your jurisdiction.'}
          />
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={'15.3 All games are governed by the specific rules and regulations outlined on Basepoker.com. Players are expected to familiarize themselves with these rules before participating in any game.'}
          />
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={'15.4 Players may not make bets exceeding the value of chips they have on the table at the beginning of a hand. Additional chips may only be acquired between hands. If a player runs out of chips during a hand, they are considered "all-in."'}
          />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'15.5 It is prohibited to remove money from the table during a playing session. Players must keep all funds on the table until the session concludes.'} />
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={'15.6 "Table talk" or any form of disruptive conversation during a hand that could influence the gameplay of others is strictly prohibited. This applies whether you are actively playing in the hand or observing.'}
          />
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={'15.7 Abusive or offensive language or behavior that is deemed inappropriate by Basepoker.com will not be tolerated. Any instances of such behavior may result in disciplinary action, including suspension or loss of playing privileges.'}
          />
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={'15.8 Basepoker.com utilizes a random number generator software to ensure fair outcomes for all games. The RNG determines all game results impartially and without bias.'}
          />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'15.9 Players agree not to interfere with or attempt to manipulate the operations of Basepoker.com or the normal play of any games offered on our platform.'} />
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={"15.10 Any attempts to disrupt gameplay or manipulate the website's operations may result in immediate exclusion from Basepoker.com and could lead to further legal action."}
          />
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={"15.11 For specific rules pertaining to each game offered on Basepoker.com, players can refer to the rules section available on each game's page or consult our House Rules."}
          />
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Server Crash'} />
        <LinearLayout gap={8}>
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={'16.1 During a server crash affecting active gameplay, all affected chips and bets are returned to player accounts. Basepoker.com and affiliates are not liable for losses due to equipment failure, communication interruptions, or other external interferences.'}
          />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'16.2 System, technological, or communication errors affecting game results or bet settlement may result in voided bets and gameplay.'} />
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Intellectual Property Rights'} />
        <LinearLayout gap={8}>
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={'17. Basepoker.com’s Conditions permit use of the Website and Games under applicable licenses but do not convey ownership rights. Intellectual property rights remain with Basepoker.com.'}
          />
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Publicity of Tournament Results'} />
        <LinearLayout gap={8}>
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={
              '18.1 Tournament participants consent to use of play data in online streams and broadcasts by Basepoker.com. Results include player nicknames, finishing positions, hands played, and payouts. Personal information is not disclosed without written permission, except in specified cases.'
            }
          />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'18.2 Certain tournaments require consent for publicizing real names if prizes are won. Refusal allows opting out during registration.'} />
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Indemnification'} />
        <LinearLayout gap={8}>
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={'19. Users agree to indemnify Basepoker.com, subsidiaries, affiliates, officers, agents, and employees from third-party claims arising from breach of Conditions, law violations, or Website/Game use, including associated liabilities, expenses, and legal fees.'}
          />
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Limitation of Liability'} />
        <LinearLayout gap={8}>
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={'20. Basepoker.com provides Services "as is" without warranty of uninterrupted operation or error-free performance. Liability is limited to the sum of legitimate account balances and disputed bet stakes.'}
          />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'20.1 Basepoker.com is not liable for:'} />
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={
              ' • Direct, indirect, special, incidental, or consequential losses.\\n • Income, profits, goodwill, contracts, or money use losses.\\n • Business interruption, tort, contract, or other losses due to Website use.\\n • Technological harm, including viruses affecting user devices.\\n • Acts of God, power failures, disputes, government actions, or service interruptions.'
            }
          />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'20.2 Exceptions include liability for fraud, death, or personal injury from negligence.'} />
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Third-Party Content'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'21. Basepoker.com disclaims liability for third-party content, services, or links posted on or accessed through the Website.'} />
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Dormant Accounts'} />
        <LinearLayout gap={8}>
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={'22.1 Dormant Accounts, inactive for 12 months, may incur a monthly handling fee or closure with funds donated to charity if inactive for 12 additional months.'}
          />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'22.2 Personal Data from Dormant Accounts is deleted when no longer needed.'} />
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Responsible Gaming'} />
        <LinearLayout gap={8}>
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={'23.1 Basepoker.com promotes responsible gaming. Users can set deposit limits post-registration and use self-exclusion tools to suspend accounts temporarily or permanently. Self-excluded users are barred from bonuses, promotions, and marketing during exclusion.'}
          />
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={'23.2 We understand that some players may require a break from gaming. Basepoker.com offers a self-exclusion facility, allowing you to close your account temporarily for a period ranging from 24 hours up to 5 years, or permanently.'}
          />
          <StyledPolicyText>
            23.3 You may contact our Customer Service Support team at <StyledPolicyLink onClick={() => window.open('mailto:support@basepoker.com', '_blank')}>support@basepoker.com</StyledPolicyLink> to initiate a self-exclusion period
          </StyledPolicyText>
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={'23.4 During the self-exclusion period, you will not qualify for any bonuses or promotions offered by Basepoker.com. Additionally, you will be excluded from receiving any marketing materials from us.'}
          />
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={
              '23.5 At the end of the self-exclusion period, if you wish to reopen your account, you must contact our customer support team. A minimum 24-hour cooling-off period will be enforced before your account is reactivated, during which you will have the option to cancel the reactivation.'
            }
          />
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={'23.6 Basepoker.com cannot be held responsible for any consequences or losses incurred if you attempt to circumvent the self-exclusion process by providing misleading information or opening additional accounts.'}
          />
          <StyledPolicyText>
            23.7 We advise users to consider utilizing software that restricts access to gambling websites, such as <StyledPolicyLink onClick={() => window.open('https://cyberpatrol.com', '_blank')}>www.cyberpatrol.com</StyledPolicyLink> or{' '}
            <StyledPolicyLink onClick={() => window.open('https://gamblock.com', '_blank')}>www.gamblock.com</StyledPolicyLink>, if they require additional support in controlling their gambling habits.
          </StyledPolicyText>
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Customer Complaints'} />
        <LinearLayout gap={8}>
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={'24.1 If you have any concerns or disputes regarding bets placed on Basepoker.com, please contact us at complaints@basepoker.com. Your complaint will be promptly assigned to a customer support representative for review.'}
          />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'24.2 We aim to provide an initial response to customer complaints within 24 hours on weekdays and within 72 hours on weekends.'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'24.3 If you do not initiate follow-up contact within seven days after our initial response, we will consider the complaint resolved.'} />
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={
              '24.4 If you choose to follow up on your complaint, it will be re-examined by the designated customer support representative within 24 hours on weekdays and within 72 hours on weekends. Further responses or escalation to a customer support manager will be conducted according to our internal procedures until the complaint is resolved or deemed in dispute.'
            }
          />
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Miscellanous'} />
        <LinearLayout gap={8}>
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={'If any provision of these Terms and Conditions is found to be unenforceable, invalid, or unlawful by a court of competent jurisdiction, the remaining provisions shall remain in full force and effect.'}
          />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'These Terms and Conditions constitute the entire agreement between you and Basepoker.com regarding the use of our services.'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'Our failure to enforce any rights under these Terms and Conditions shall not be deemed a waiver of such rights.'} />
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Disclaimer'} />
        <LinearLayout gap={8}>
          <Text
            lineHeight={20}
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={'start'}
            textColor={'white'}
            text={
              'We strive to provide our services, software, and website to the highest standards possible, but we do not warrant that they will be uninterrupted, error-free, or free from defects. We reserve the right to suspend, remove, or modify our services at our discretion without prior notice. Basepoker.com disclaims any liability for losses incurred due to the use of our services, software, or website.'
            }
          />
        </LinearLayout>
      </LinearLayout>
    </LinearLayout>
  );
};

export default TermsOfService;
