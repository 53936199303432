import styled from '@emotion/styled';
import Checkbox from 'src/components/common/checkbox';
import Text from 'src/components/common/text/Text';
import useTableActions from 'src/hooks/useTableActions';
import { useTableContext } from '../../../../hooks/TableProvider';
import useDevice from '../../../../hooks/useDevice';
import { useTranslation } from 'react-i18next';

const ReserveSitOut = () => {
  const { isTournamentTable, reservedSitOut, myPlayerData } = useTableContext();
  const { isMobile } = useDevice();
  const { seatOut } = useTableActions();
  const { t } = useTranslation();

  const toggleSitOut = () => {
    seatOut();
  };

  return (
    <ReserveSitOutWrap>
      {!isTournamentTable && myPlayerData && (
        <>
          <Checkbox onChange={toggleSitOut} checked={reservedSitOut} borderColor={reservedSitOut ? 'error400' : 'gray600'} bgColor={reservedSitOut ? 'error600' : 'gray500'} size="sm" />
          <CursorText onClick={toggleSitOut} text={t('ACTIONS.SitOutNextHand')} fontStyle={isMobile ? 'TextSmall_Medium' : 'TextMd_Medium'} textColor="white" />
        </>
      )}
    </ReserveSitOutWrap>
  );
};

const ReserveSitOutWrap = styled.div`
  position: absolute;
  top: 76px;
  gap: 8px;
  right: 24px;
  display: flex;
  @media (max-width: 768px) {
    position: unset;
    top: unset;
    gap: 8px;
    margin-top: 24px;
    display: flex;
  }
`;

const CursorText = styled(Text)`
  cursor: pointer;
`;

export default ReserveSitOut;
