import { useEffect, useState } from 'react';

const usePageMessage = () => {
  const [tableJoinDetails, setTableJoinDetails] = useState<{ needJoin: boolean; seatId: number | undefined } | undefined>(undefined);
  const channel = new BroadcastChannel('table-join-channel');
  const getMessageEvent = (event: MessageEvent<any>) => {
    if (event.origin === window.origin && event.data.type === 'joinTable') {
      setTableJoinDetails({ needJoin: true, seatId: event.data.seatId ?? undefined });
    }
  };
  useEffect(() => {
    channel.onmessage = getMessageEvent;
    return () => {
      channel.close();
    };
  }, []);

  return {
    tableJoinDetails,
    resetPageMassage: () => {
      setTableJoinDetails(undefined);
    }
  };
};

export default usePageMessage;
