import React from 'react';
import { IconProps } from '../types';
import { Colors } from 'src/styles/Colors';

export const HelpCenter = React.memo<IconProps>(({ w = 20, h = 20, color = 'gray400' }) => (
  <svg width={w} height={h} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 0C8.13699 0 6.39298 0.509453 4.89966 1.39665C4.49676 1.63601 4.29531 1.7557 4.17581 1.98045C4.08039 2.15991 4.04713 2.42532 4.09532 2.62277C4.15567 2.87007 4.34608 3.06048 4.72691 3.4413L6.65265 5.36705C6.93798 5.65238 7.08065 5.79504 7.21774 5.86178C7.36804 5.93495 7.43951 5.95179 7.60666 5.95345C7.75913 5.95496 8.02561 5.86592 8.55858 5.68785C9.0115 5.53652 9.49618 5.45455 10 5.45455C10.5039 5.45455 10.9886 5.53653 11.4415 5.68788C11.9745 5.86597 12.241 5.95501 12.3935 5.95351C12.5606 5.95185 12.6321 5.93501 12.7824 5.86184C12.9195 5.7951 13.0621 5.65244 13.3475 5.3671L15.2732 3.44137C15.654 3.06055 15.8444 2.87014 15.9048 2.62285C15.953 2.4254 15.9197 2.15999 15.8243 1.98054C15.7048 1.75578 15.5034 1.63609 15.1005 1.39672C13.6071 0.509482 11.8631 0 10 0Z"
      fill={Colors[color]}
    />
    <path
      d="M14.6329 13.3473C14.3476 13.062 14.205 12.9194 14.1382 12.7823C14.0651 12.632 14.0482 12.5605 14.0466 12.3933C14.045 12.2409 14.1341 11.9744 14.3122 11.4414C14.4635 10.9885 14.5455 10.5038 14.5455 10C14.5455 9.49623 14.4635 9.0116 14.3122 8.55871C14.1341 8.02576 14.0451 7.75929 14.0466 7.60682C14.0483 7.43968 14.0651 7.36821 14.1383 7.21792C14.205 7.08083 14.3477 6.93817 14.633 6.65285L16.5588 4.72708C16.9396 4.34625 17.13 4.15583 17.3773 4.09548C17.5748 4.0473 17.8402 4.08056 18.0197 4.17598C18.2444 4.29549 18.3641 4.49695 18.6035 4.89986C19.4906 6.39313 20 8.13707 20 10C20 11.863 19.4905 13.607 18.6034 15.1003C18.364 15.5032 18.2443 15.7047 18.0195 15.8242C17.8401 15.9196 17.5747 15.9529 17.3772 15.9047C17.1299 15.8443 16.9395 15.6539 16.5587 15.2731L14.6329 13.3473Z"
      fill={Colors[color]}
    />
    <path
      d="M6.65286 14.633C6.93817 14.3477 7.08083 14.205 7.21792 14.1383C7.36821 14.0651 7.43968 14.0483 7.60682 14.0466C7.75929 14.0451 8.02576 14.1341 8.55871 14.3122C9.0116 14.4635 9.49623 14.5455 10 14.5455C10.5038 14.5455 10.9885 14.4635 11.4414 14.3122C11.9743 14.1341 12.2408 14.0451 12.3933 14.0466C12.5604 14.0482 12.6319 14.0651 12.7822 14.1382C12.9193 14.205 13.062 14.3476 13.3473 14.633L15.273 16.5587C15.6539 16.9396 15.8443 17.13 15.9046 17.3773C15.9528 17.5747 15.9196 17.8401 15.8241 18.0196C15.7046 18.2443 15.5032 18.364 15.1003 18.6034C13.607 19.4906 11.863 20 10 20C8.13707 20 6.39313 19.4906 4.89986 18.6035C4.49695 18.3641 4.29549 18.2444 4.17598 18.0197C4.08056 17.8402 4.0473 17.5748 4.09548 17.3773C4.15583 17.13 4.34625 16.9396 4.72708 16.5588L6.65286 14.633Z"
      fill={Colors[color]}
    />
    <path
      d="M5.36703 6.65272C5.65235 6.93805 5.79502 7.08071 5.86175 7.2178C5.93492 7.36809 5.95176 7.43957 5.95342 7.60671C5.95493 7.75918 5.8659 8.02566 5.68783 8.55862C5.53651 9.01153 5.45455 9.49619 5.45455 10C5.45455 10.5039 5.53653 10.9886 5.68788 11.4415C5.86597 11.9745 5.95501 12.241 5.95351 12.3935C5.95185 12.5606 5.93501 12.6321 5.86184 12.7824C5.7951 12.9195 5.65244 13.0621 5.3671 13.3475L3.44137 15.2732C3.06055 15.654 2.87014 15.8444 2.62285 15.9048C2.4254 15.953 2.15999 15.9197 1.98054 15.8243C1.75578 15.7048 1.63609 15.5034 1.39672 15.1005C0.509482 13.6071 0 11.8631 0 10C0 8.13702 0.509438 6.39303 1.39661 4.89973C1.63597 4.49682 1.75565 4.29537 1.98041 4.17587C2.15987 4.08045 2.42528 4.04719 2.62273 4.09538C2.87003 4.15573 3.06044 4.34614 3.44127 4.72696L5.36703 6.65272Z"
      fill={Colors[color]}
    />
  </svg>
));

HelpCenter.displayName = 'HelpCenter';
