import React from 'react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import LinearLayout from '../../layout/LinearLayout';
import TournamentPreview from './TournamentPreview';
import { RootState } from '../../../store/store';
import { selectTournamentById } from '../../../store/slices/tournamentSlice';

interface TournamentPreviewModalProps {
  tournamentId: number;
}

const TournamentPreviewModal: React.FC<TournamentPreviewModalProps> = ({ tournamentId }) => {
  const selectedTournamentData = useSelector((state: RootState) => selectTournamentById(state, tournamentId));

  return (
    <Background>
      <LinearLayout borderRadius={12} gap={16}>
        <TournamentPreview tournamentData={selectedTournamentData} />
      </LinearLayout>
    </Background>
  );
};

const Background = styled.div`
  width: 361px;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray800};
  border-radius: 12px;
  gap: 16px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export default TournamentPreviewModal;
