import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

export type SupportedLangType = 'en' | 'zh' | 'ja' | 'es' | 'ru' | 'fr' | 'id' | 'pt';
export const SUPPORTED_LANGUAGES = ['en', 'zh', 'ja', 'es', 'ru', 'fr', 'id', 'pt']; // 지원하는 언어 목록
export const DEFAULT_LANGUAGE = 'en'; // 기본 언어 설정

i18n
  .use(HttpBackend) // 번역 파일 로드
  .use(LanguageDetector) // 브라우저 언어 감지
  .use(initReactI18next) // React 연동
  .init({
    fallbackLng: DEFAULT_LANGUAGE, // 기본 언어
    supportedLngs: SUPPORTED_LANGUAGES, // 지원 언어 목록
    backend: {
      loadPath: '/locales/{{lng}}/translation.json' // 번역 파일 경로
    },
    detection: {
      order: ['cookie', 'localStorage'], // 감지 순서
      caches: ['cookie', 'localStorage'], // 캐싱 저장소
      lookupCookie: 'i18next', // 쿠키 이름 설정
      lookupLocalStorage: 'i18nextLng' // LocalStorage 키 이름 설정
    },
    interpolation: {
      escapeValue: false // React에서 안전하게 처리
    },
    load: 'all',
    preload: SUPPORTED_LANGUAGES,
    debug: process.env.NODE_ENV === 'development',
    react: {
      bindI18n: 'languageChanged',
      bindI18nStore: '',
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: true
    }
  });

export default i18n;
