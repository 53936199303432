import React, { ReactNode, useEffect, useState } from 'react';
import { ThemeProvider } from '@emotion/react';
import theme from '../styles/theme';
import i18n, { SupportedLangType } from '../i18n';
import { createTextStyle } from '../styles/textStyles';

const BpThemeProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [bpTheme, setBpTheme] = useState<any>(theme);
  useEffect(() => {
    setBpTheme((prevTheme: any) => ({
      ...prevTheme,
      textStyles: {
        ...createTextStyle(i18n.language as SupportedLangType)
      }
    }));
  }, [i18n.language]);

  return (
    <ThemeProvider theme={bpTheme} key={i18n.language}>
      {children}
    </ThemeProvider>
  );
};

export default BpThemeProvider;
