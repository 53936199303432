import { Card, CardSuit, cardSymbols } from '../store/slices/streamingTypes';
import { divideBigNumbers, getBigNumber, multiplyBigNumbers } from './BigNumberUtil';
import BigNumber from 'bignumber.js';

export const timeFormat = (time: number) => {
  const minutes = Math.floor((time / (1000 * 60)) % 60);
  const seconds = Math.floor((time / 1000) % 60);
  return `${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
};

export const splitEmail = (email: string | undefined) => {
  if (email?.includes('@')) {
    return email.split('@')[0];
  } else {
    return email;
  }
};

export function mapCardSymbol(cardType: CardSuit): string {
  return cardSymbols[cardType];
}

export function formatCard(card: string): string {
  let cardNumber = card.slice(0, -1);
  const cardType = card.slice(-1) as CardSuit;
  if (cardNumber === 'T') {
    cardNumber = '10';
  }
  return `${cardNumber}${mapCardSymbol(cardType)}`;
}

export function findTwoPairRanks(bestHand: Card[], isEnglish?: boolean): string {
  const rankCounts: Record<string, number> = {};

  // 카드 숫자들의 빈도수를 계산합니다.
  bestHand.forEach(card => {
    let rank = card.substring(0, card.length - 1);
    rank = rank === 'T' ? '10' : rank; // 'T'는 '10'으로 치환
    rankCounts[rank] = (rankCounts[rank] || 0) + 1;
  });
  // 두 번 이상 나온 카드 숫자를 찾아서 배열로 저장합니다.
  const pairs: any[] = [];
  Object.keys(rankCounts).forEach(rank => {
    if (rankCounts[rank] >= 2) {
      pairs.push(rank);
    }
  });

  if (pairs.length >= 2) {
    pairs.sort((a, b) => {
      const rankValues = { '2': 2, '3': 3, '4': 4, '5': 5, '6': 6, '7': 7, '8': 8, '9': 9, '10': 10, T: 10, J: 11, Q: 12, K: 13, A: 14 };
      // @ts-ignore
      return rankValues[b] - rankValues[a];
    });
    return isEnglish ? `${pairs[0]}s, ${pairs[1]}s` : `${pairs[0]}, ${pairs[1]}`;
  }
  return 'No Two Pair Found';
}

export const generateRandomPassword = (): string => {
  const randomNumber = Math.floor(1000 + Math.random() * 9000); // Ensures a 4-digit number
  return randomNumber.toString();
};

export const getTimestamp = (timestamp: string): string => {
  const date = new Date(timestamp);

  const padZero = (num: number): string => (num < 10 ? `0${num}` : num.toString());

  const day = padZero(date.getDate());
  const month = padZero(date.getMonth() + 1);
  const year = date.getFullYear();
  const hours = padZero(date.getHours());
  const minutes = padZero(date.getMinutes());

  return `${day}.${month}.${year} ${hours}:${minutes}`;
};

export const truncateString = (str: string | undefined) => {
  if (str) {
    if (str.length <= 10) {
      return str;
    }
    return str.substring(0, 6) + '....' + str.substring(str.length - 4, str.length);
  } else {
    return '';
  }
};

export const numberToDisplayString = (text: number | string | undefined, maxDecimal?: number, isConvertingBB?: boolean, bbAmount?: number) => {
  const parsedAmount = typeof text === 'string' ? getBigNumber(text).toNumber() : text ?? 0;

  const amount = isConvertingBB ? divideBigNumbers(parsedAmount, bbAmount ?? 1).toNumber() : parsedAmount;

  const hasDecimal = amount % 1 !== 0;

  return amount.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: isConvertingBB && hasDecimal ? 1 : maxDecimal ?? 10
  });
};

export const toFixedFloor = (num: number | undefined, decimal: number, zeroDecimal: boolean = false, addComma: boolean = false): string => {
  if (num === undefined) {
    return addCommas(Number(0).toFixed(decimal));
  }

  const factor = Math.pow(10, decimal);
  const multiplied = multiplyBigNumbers(num, factor);
  const floored = multiplied.integerValue(BigNumber.ROUND_FLOOR).toNumber();
  const divided = divideBigNumbers(floored, factor, decimal);

  let result = divided.toFixed(decimal);

  if (divided.toNumber() === 0 && zeroDecimal) {
    result = divided.toFixed(0);
  }

  return addComma ? addCommas(result) : result;
};

const addCommas = (numStr: string): string => {
  const parts = numStr.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};
