import React, { useEffect, useRef, useState } from 'react';
import { Avatar, Buttons, HeaderInner, HeaderProfile, HeaderWrap, HoverContainer, IconButtons, MenuItem, Menus, MobileIconButtons, SettingButtons, Settings, StyledLogo } from './styles';
import MobileSettingTemplate from '../settings/MobileSettingTemplate';
import { ModalType, showModal } from '../../../store/slices/modalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { SeatStatus } from '../../../store/api/responseTypes';
import authSlice, { selectCurrentUser, selectIsSoundEnabled, setActiveTab, SettingPages, supportNavigation, supportSettingPages } from '../../../store/slices/authSlice';
import { useUserTableSetting } from '../../../hooks/useUserTableSetting';
import DeckStyleSelector from '../settings/DeckStyleSelector';
import TableStyleSelector from '../settings/TableStyleSelector';
import useDevice from '../../../hooks/useDevice';
import { useParams } from 'react-router-dom';
import { AddChips, MenuIcon, SettingIcon } from '../../common/icon';
import { useUserProfile } from '../../../hooks/useUserProfile';
import MobileMenuTemplate from '../settings/MobileMenuTemplate';
import styled from '@emotion/styled';
import { TextStyles } from '../../../styles/textStyles';
import BaseButton from '../../common/button/BaseButton';
import { useTableContext } from '../../../hooks/TableProvider';
import ToggleSwitch from '../../common/switch/ToggleSwitch';
import HorizontalLayout from '../../layout/HorizontalLayout';
import Text from '../../common/text/Text';
import TournamentInfoBox from '../tournament/TournamentInfoBox';
import useLangNavigate from '../../../hooks/useLangNavigate';
import { useTranslation } from 'react-i18next';
import { showFailToast } from '../../common/toast/BpToast';
import i18n from 'i18next';
import useRouteMatch from '../../../hooks/useRouteMatch';

type OutType = 'Leave' | 'Stand';

type HeaderProps = {
  path?: string;
};

const TableHeader: React.FC<HeaderProps> = ({ path }) => {
  const { tableSettings, mySeatStatus, isAddChipAvailable, reservedSitOut, tableId } = useTableContext();
  const [isSettingVisible, setSettingVisible] = useState(false);
  const { isMobile } = useDevice();
  const { t } = useTranslation();
  const { user } = useUserProfile();
  const { shareCode } = useRouteMatch();
  const userSettings = useUserTableSetting();
  const isSoundModuleEnabled = useSelector(selectIsSoundEnabled);
  const [isSoundEnable, setSoundEnable] = useState(userSettings.isSoundEnabled);
  const leaveOrStand: OutType = mySeatStatus === undefined || mySeatStatus === SeatStatus.STAND ? 'Leave' : 'Stand';
  const [isMenuVisible, setMenuVisible] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const addChips = () => {
    dispatch(showModal(ModalType.BuyInModal));
  };

  const stand = () => {
    dispatch(showModal(ModalType.StandModal));
  };

  const onMenuClick = (menu: SettingPages) => {
    const langPath = i18n.language === 'en' ? '' : `${i18n.language}/`;
    switch (menu) {
      case 'Profile':
      case 'Settings':
      case 'Wallet':
      case 'History':
        window.open(`/${langPath}settings`, '_blank');
        dispatch(setActiveTab(menu));
        break;
      case 'Cash game':
        window.open(`/${langPath}cashgame`, '_blank');
        break;
    }
    setMenuVisible(false);
  };

  useEffect(() => {
    if (userSettings) {
      setSoundEnable(userSettings.isSoundEnabled);
    }
  }, [userSettings]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setMenuVisible(false);
      }
    };
    if (isMobile) {
      document.removeEventListener('mousedown', handleClickOutside);
    } else {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleSound = () => {
    if (shareCode && isSoundModuleEnabled) {
      setSoundEnable(!isSoundEnable);
      dispatch(
        authSlice.actions.setTableSetting({
          shareCode: shareCode,
          setting: { ...userSettings, isSoundEnabled: !isSoundEnable }
        })
      );
    } else {
      showFailToast('Sound currently disabled');
    }
  };
  const toggleBlindView = () => {
    dispatch(
      authSlice.actions.setTableSetting({
        shareCode: shareCode,
        setting: { ...userSettings, isBlindView: !userSettings.isBlindView }
      })
    );
  };

  const showAuthModal = () => dispatch(showModal(ModalType.AuthModal));

  const getTabName = (tabName: SettingPages) => {
    switch (tabName) {
      case 'Profile':
        return t('PROFILE.Profile');
      case 'Settings':
        return t('SETTINGS.Settings');
      case 'Wallet':
        return t('WALLET.Wallet');
      case 'History':
        return t('HISTORY.History');
      case 'Log out':
        return t('LOGOUT.LogOut');
    }
  };

  return (
    <TableHeaderWrap>
      <HeaderInner isInTable={true}>
        {isMobile && isSettingVisible && (
          <MobileSettingTemplate
            isAddChipAvailable={isAddChipAvailable}
            isStandAvailable={leaveOrStand === 'Stand'}
            toggleBlindView={toggleBlindView}
            isBlindView={userSettings.isBlindView}
            hasSound={isSoundEnable}
            toggleSound={toggleSound}
            setSettingVisible={setSettingVisible}
            requestAddChips={addChips}
            requestStand={stand}
          />
        )}
        {isMobile && isMenuVisible && <MobileMenuTemplate isInTable={true} setMenuVisible={setMenuVisible} onMenuClick={onMenuClick} />}
        <SettingButtons>
          {isMobile ? (
            <MobileIconButtons>
              <SettingIcon onClick={() => setSettingVisible(true)} />
              {path !== 'tournament' && (
                <AddChips
                  opacity={isAddChipAvailable ? 1 : 0.4}
                  onClick={() => {
                    if (isAddChipAvailable) {
                      addChips();
                    }
                  }}
                />
              )}
            </MobileIconButtons>
          ) : (
            leaveOrStand === 'Stand' &&
            path !== 'tournament' && (
              <Buttons>
                <BaseButton
                  onClick={() => {
                    if (isAddChipAvailable) {
                      addChips();
                    }
                  }}
                  w={107}
                  color={'primary'}
                  disabled={!isAddChipAvailable}
                  size={'btn36'}
                  textColor={isAddChipAvailable ? 'white' : 'gray500'}
                  textStyle="TextMd_Semibold"
                  text={t('CASHGAME.AddChips')}
                />
                <BaseButton onClick={stand} gap={8} leftIcon="LoginIcon" iconSize={16} w={99} color="grayOpacity" size="btn36" textColor="white" textStyle="TextMd_Semibold" text={leaveOrStand === 'Stand' ? t('ACTIONS.Stand') : t('ACTIONS.Leave')} disabled={reservedSitOut} />
              </Buttons>
            )
          )}
          {!isMobile && (
            <IconButtons>
              {path === 'tournament' && (
                <StyledTournamentInfoWrap>
                  <TournamentInfoBox />
                </StyledTournamentInfoWrap>
              )}
              <BaseButton disabled={!isSoundModuleEnabled} onClick={toggleSound} w={36} leftIcon={isSoundEnable ? 'VolumeIcon' : 'VolumeOff'} iconSize={24} size={'btn36'} />
              <HoverContainer onMouseEnter={() => setSettingVisible(true)} onMouseLeave={() => setSettingVisible(false)}>
                <BaseButton w={36} leftIcon="SettingIcon" iconSize={24} size={'btn36'} />
                <Settings isVisible={isSettingVisible}>
                  <DeckStyleSelector tableId={tableSettings?.tableId} />
                  <TableStyleSelector tableId={tableSettings?.tableId} />
                  <HorizontalLayout margin={'0'}>
                    <Text fontStyle={'TextSmall_Medium'} textColor={'white'} text={t('SETTINGS.DisplayStackBB')} />
                    <ToggleSwitch isActive={userSettings.isBlindView} onClick={toggleBlindView} />
                  </HorizontalLayout>
                </Settings>
              </HoverContainer>
            </IconButtons>
          )}
        </SettingButtons>
        {user ? (
          isMobile ? (
            <HeaderProfile onClick={() => setMenuVisible(!isMenuVisible)}>
              <MenuIcon />
            </HeaderProfile>
          ) : (
            <HeaderProfile>
              <HoverContainer onClick={() => setMenuVisible(!isMenuVisible)}>
                <Avatar src={user?.profile} alt="Profile" />
                {isMenuVisible && (
                  <Menus isVisible={isMenuVisible} ref={menuRef}>
                    {supportSettingPages
                      .filter(item => item !== 'Log out')
                      .map(item => (
                        <MenuItem key={item} onClick={() => onMenuClick(item)}>
                          {getTabName(item)}
                        </MenuItem>
                      ))}
                  </Menus>
                )}
              </HoverContainer>
            </HeaderProfile>
          )
        ) : (
          !isMobile && (
            <HeaderProfile>
              <BaseButton color="primary" textColor="white" textStyle="TextMd_Semibold" size="btn44" w={121} onClick={showAuthModal} text={t('BUTTON.GetStarted')} />
            </HeaderProfile>
          )
        )}
      </HeaderInner>
    </TableHeaderWrap>
  );
};

export default TableHeader;

const StyledTournamentInfoWrap = styled.div`
  width: 164px;
  position: relative;
`;

const TableHeaderWrap = styled(HeaderWrap)`
  ${TextStyles.TextMd}
  background-color: 'transparent';
  box-shadow: none;
  position: fixed;
  top: 0;
  gap: 20px;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  color: #fff;
  display: flex;
  align-items: start;
  z-index: 200;
  justify-content: space-between;
  @media (max-width: 768px) {
    background-color: transparent;
    padding: 4px;
  }
`;

const StyledAddChipsWrap = styled.div<{ isAddChipsAvailable: boolean }>`
  opacity: ${({ isAddChipsAvailable }) => (isAddChipsAvailable ? 1 : 0.4)};
  align-self: center;
`;
