import React, { useEffect, useState } from 'react';
import SettingTab from '../components/template/settings/SettingTab';
import SettingLayout from '../components/layout/SettingLayout';
import WalletTemplate from '../components/template/settings/WalletTemplate';
import GameSettingTemplate from '../components/template/settings/GameSettingTemplate';
import ProfileTemplate from '../components/template/settings/ProfileTemplate';
import styled from '@emotion/styled';
import HistoryTemplate from '../components/template/settings/HistoryTemplate';
import { useDispatch, useSelector } from 'react-redux';
import { selectLastPage, setActiveTab, SettingPages } from '../store/slices/authSlice';
import { ModalType, showModal } from '../store/slices/modalSlice';
import Footer from '../components/template/footer/Footer';
import { useUserProfile } from '../hooks/useUserProfile';
import useDevice from '../hooks/useDevice';

const SettingsScreen = () => {
  const { user } = useUserProfile();
  const { isMobile } = useDevice();
  const dispatch = useDispatch();
  const activeTab = useSelector(selectLastPage);
  const [activeTitle, setActiveTitle] = useState<SettingPages>();
  const [activeNode, setActiveNode] = useState<React.ReactNode>();

  const tabClick = (tabName: SettingPages) => {
    dispatch(setActiveTab(tabName));
    selectTab(tabName);
    if (tabName === 'Log out') {
      dispatch(showModal(ModalType.LogoutModal));
    }
  };

  useEffect(() => {
    dispatch(setActiveTab(activeTab));
    if (activeTab !== 'Log out') {
      selectTab(activeTab);
    }
  }, [activeTab]);

  const selectTab = (activeTab: SettingPages) => {
    switch (activeTab) {
      case 'Profile':
        setActiveNode(<ProfileTemplate />);
        setActiveTitle(activeTab);
        break;
      case 'Settings':
        setActiveNode(<GameSettingTemplate />);
        setActiveTitle(activeTab);
        break;
      case 'Wallet':
        setActiveNode(<WalletTemplate />);
        setActiveTitle(activeTab);
        break;
      case 'History':
        setActiveNode(<HistoryTemplate />);
        setActiveTitle(activeTab);
        break;
      case 'Log out':
        break;
    }
  };

  return (
    <Wrap>
      {user && activeTitle ? (
        <>
          <InnerWrap>
            <SettingTab onTabClick={tabClick} activeTab={activeTitle} />
            <SettingLayout title={activeTitle}>{activeNode}</SettingLayout>
          </InnerWrap>
          <Footer />
        </>
      ) : (
        <></>
      )}
    </Wrap>
  );
};

const InnerWrap = styled.div`
  overflow: scroll;
  height: 100%;
  flex: 1;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.gray900};
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  gap: 8px;

  padding-bottom: 20px;
  @media (max-width: 768px) {
    padding: 20px;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
`;
const Wrap = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-between;
  flex-direction: column;
  min-height: 100vh;
`;

export default SettingsScreen;
