import React, { useState } from 'react';
import styled from '@emotion/styled';
import { TournamentInfoBox } from '../../../pages/TournamentDetailScreen';
import TournamentInfoTabPrize, { TournamentPrizeData } from './TournamentInfoTabPrize';
import TournamentInfoTabPlayers from './TournamentInfoTabPlayers';

const Tabs = styled.div`
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black};

  flex-shrink: 0;
`;

const Tab = styled.button<{ isActive: boolean }>`
  background: none;
  height: 44px;
  padding: 8px 16px;
  width: 100%;
  ${({ theme }) => theme.textStyles.TextMd_Semibold}
  cursor: pointer;
  color: ${({ theme, isActive }) => (isActive ? theme.colors.white : theme.colors.gray400)};
  border-bottom: ${({ theme, isActive }) => (isActive ? `2px solid ${theme.colors.white}` : `2px solid transparent`)};

  &:hover {
    color: ${({ theme }) => theme.colors.white};
  }
`;

const TabContent = styled.div`
  box-sizing: border-box;
  //flex: 1;
  overflow: scroll;
`;

export type TournamentTab = 'Prize' | 'Players';

const TournamentInfoTab: React.FC<{ initialTab: TournamentTab }> = ({ initialTab }) => {
  const tabs: TournamentTab[] = ['Prize', 'Players'];
  const [activeTab, setActiveTab] = useState<TournamentTab>(initialTab);

  const handleTabClick = (tab: TournamentTab) => {
    setActiveTab(tab);
  };

  const renderTabContent = (tab: TournamentTab) => {
    switch (tab) {
      case 'Prize':
        return <TournamentInfoTabPrize />;
      case 'Players':
        return <TournamentInfoTabPlayers />;
      default:
        return <div>{tab} Empty Content</div>;
    }
  };

  return (
    <TournamentInfoBox>
      <Tabs>
        {tabs.map(tab => (
          <Tab key={tab} isActive={activeTab === tab} onClick={() => handleTabClick(tab)}>
            {tab}
          </Tab>
        ))}
      </Tabs>
      <TabContent>{renderTabContent(activeTab)}</TabContent>
    </TournamentInfoBox>
  );
};

export default TournamentInfoTab;
