import styled from '@emotion/styled';
import React, { useCallback, useEffect } from 'react';
import Input from 'src/components/common/input';
import { bpSocketService } from '../../../../services/BpWebSocketService';
import { useChats } from '../../../../hooks/useChats';
import useDevice from '../../../../hooks/useDevice';
import { logWarn } from '../../../../utils/ConsoleUtil';
import BaseButton from '../../../common/button/BaseButton';
import { useTableContext } from '../../../../hooks/TableProvider';
import { useTranslation } from 'react-i18next';

const Chat = () => {
  const { tableUsers, isChatEnable } = useTableContext();
  const messagesEndRef = React.useRef<HTMLDivElement>(null);
  const [isChatLoading, setIsChatLoading] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const { chats: chatMessages } = useChats();
  const { isMobile } = useDevice();
  const { t } = useTranslation();

  const sendMessage = () => {
    if (!message || isChatLoading) return;
    setIsChatLoading(true);
    bpSocketService.chatMessageSend(message);
    setMessage('');
    setIsChatLoading(false);
  };

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = event.target.value;
    if (inputValue.length > 200) {
      logWarn('Input exceeded 200 characters');
      inputValue = inputValue.slice(0, 200); // 200자까지 잘라내기
    }
    setMessage(inputValue);
  }, []);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });
  }, [chatMessages, messagesEndRef]);

  return (
    <Wrap>
      <MessageContainer>
        {chatMessages.map((chat, i) => (
          <React.Fragment key={chat.header.messageId + '-' + i}>
            <NewMessage isDealer={tableUsers?.find(user => user.userId === chat.payload.userId)?.name === undefined}>
              <BoldText>{(tableUsers?.find(user => user.userId === chat.payload.userId)?.name || t('DEALER.Dealer')) + ':'}&nbsp;&nbsp;</BoldText>
              {chat.payload.message}
            </NewMessage>
          </React.Fragment>
        ))}
        <div ref={messagesEndRef} />
      </MessageContainer>
      {isChatEnable && (
        <InputContainer>
          <StyledInput
            disabled={!isChatEnable}
            textStyles={'TextXl_Medium'}
            bgColor="gray600_30"
            color="white"
            radius={8}
            value={message}
            onChange={e => handleChange(e)}
            onKeyDown={e => {
              if (e.nativeEvent.isComposing) return;
              if (e.key === 'Enter') {
                sendMessage();
              }
            }}
            suffix={
              <SendButton color={'gray'} disabled={!message} textStyle={isMobile ? 'TextMd_Semibold' : 'TextMd_Semibold'} textColor={'white'} onClick={sendMessage} w={64} size={'btn60'}>
                {t('FEATURES.Send')}
              </SendButton>
            }
            placeholder={t('FEATURES.TypeYourMessage')}
            block
          />
        </InputContainer>
      )}
    </Wrap>
  );
};

const SendButton = styled(BaseButton)`
  height: 100%;
  width: 80px;
  @media (max-width: 2560px) {
    ${({ theme }) => theme.textStyles.TextMd_Semibold}
  }
  @media (max-width: 1440px) {
    ${({ theme }) => theme.textStyles.TextXm_Semibold}
  }
  @media (max-width: 1280px) {
    ${({ theme }) => theme.textStyles.TextXs_Semibold}
  }
`;

const NewMessage = styled.span<{ isDealer: boolean }>`
  width: 100%;
  word-break: keep-all;
  white-space: normal;
  overflow-wrap: break-word;
  align-items: center;
  ${({ theme }) => theme.textStyles.TextXl_Medium};
  @media (max-width: 2560px) {
    ${({ theme }) => theme.textStyles.TextMd_Medium}
  }
  @media (max-width: 1440px) {
    ${({ theme }) => theme.textStyles.TextXm_Medium}
  }
  @media (max-width: 1280px) {
    ${({ theme }) => theme.textStyles.TextXs_Medium}
  }
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextMd_Medium}
  }
  color: ${({ theme, isDealer }) => (isDealer ? theme.colors.gray300 : theme.colors.white)};
  @media (max-width: 768px) {
    max-width: calc(100vw - 24px);
  }
`;

const BoldText = styled.span`
  ${({ theme }) => theme.textStyles.TextXl_Semibold};
  @media (max-width: 2560px) {
    ${({ theme }) => theme.textStyles.TextMd_Semibold}
  }
  @media (max-width: 1440px) {
    ${({ theme }) => theme.textStyles.TextXm_Semibold}
  }
  @media (max-width: 1280px) {
    ${({ theme }) => theme.textStyles.TextXs_Semibold}
  }
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextMd_Semibold}
  }
  display: inline-block; // inline-block 설정
`;

const StyledInput = styled(Input)`
  padding: 4px 4px 4px 32px;
  height: 56px;
  box-sizing: border-box;
  @media (max-width: 2560px) {
    height: 40px;
  }
  @media (max-width: 1440px) {
    height: 36px;
  }
  @media (max-width: 768px) {
    padding: 8px 8px 8px 16px;
    background: rgba(71, 84, 103, 0.3);
    height: 48px;
  }

  .input-inner,
  .input-inner::placeholder {
    ${({ theme }) => theme.textStyles.TextXl_Medium};

    @media (max-width: 2560px) {
      ${({ theme }) => theme.textStyles.TextMd_Semibold}
    }
    @media (max-width: 1440px) {
      ${({ theme }) => theme.textStyles.TextXm_Semibold}
    }
    @media (max-width: 1280px) {
      ${({ theme }) => theme.textStyles.TextXs_Semibold}
    }
    @media (max-width: 768px) {
      ${({ theme }) => theme.textStyles.TextMd_Semibold}
    }
  }
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  width: 100%;
  max-height: 226px;
  z-index: 4;
  @media (max-width: 2560px) {
    max-height: 180px;
  }
  @media (max-width: 1440px) {
    max-height: 144px;
  }
  @media (max-width: 768px) {
    max-height: 100%;
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 12px;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.gray800};
  }
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto; // Changed from 'scroll' to 'auto' for better appearance
  flex: 1; // This ensures it takes up all available space
  gap: 4px;

  @media (max-width: 768px) {
    padding: 56px 12px 0 12px;
  }

  &::-webkit-scrollbar {
    display: none; // Hides the scrollbar
  }

  scrollbar-width: none; // For Firefox, hide scrollbar
`;

const InputContainer = styled.div`
  display: flex;
  margin-top: 6px;
  width: 100%;
  gap: 8px;
  @media (max-width: 768px) {
    padding: 0 12px;
    box-sizing: border-box;
  }
`;

export default Chat;
