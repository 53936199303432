import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { Setting } from '../store/api/responseTypes';
import authSlice, { selectCurrentUser, selectTableUserSettingByTableId } from '../store/slices/authSlice';
import { useTableId } from './useTableId';
import { useEffect, useMemo } from 'react';
import { logDebug } from '../utils/ConsoleUtil';
import useRouteMatch from './useRouteMatch';

export const defaultSetting: Setting = {
  deckStyle: 'CLASSIC',
  tableStyle: 'GRAY',
  isSoundEnabled: true,
  isChatEnabled: true,
  isEmojiEnabled: true,
  isBlindView: true
};

export const useUserTableSetting = (): Setting => {
  const dispatch = useDispatch();
  const { shareCode } = useRouteMatch();
  const tableUserSetting = useSelector((state: RootState) => selectTableUserSettingByTableId(state, shareCode));
  const user = useSelector(selectCurrentUser);

  useEffect(() => {
    if (user?.setting.isChatEnabled || user?.setting.isEmojiEnabled) {
      dispatch(
        authSlice.actions.setTableSetting({
          shareCode: shareCode,
          setting: { ...(tableUserSetting ?? user?.setting), isChatEnabled: user?.setting?.isChatEnabled, isEmojiEnabled: user?.setting?.isEmojiEnabled }
        })
      );
    }
  }, [user?.setting?.isChatEnabled, user?.setting?.isEmojiEnabled, dispatch, shareCode]);

  return useMemo(() => {
    if (tableUserSetting) {
      return tableUserSetting;
    } else if (user?.setting) {
      return user?.setting;
    } else {
      return defaultSetting;
    }
  }, [tableUserSetting, user?.setting]);
};
