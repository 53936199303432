import React, { useEffect, useRef } from 'react';
import Text from '../../common/text/Text';
import styled from '@emotion/styled';
import { AssetBalance } from '../../../store/api/responseTypes';
import { toFixedFloor } from '../../../utils/StringUtil';
import { ChevronDown } from '../../common/icon/icons/ChevronDown';
import TextInput from '../../common/input/TextInput';
import useDevice from '../../../hooks/useDevice';
import useAssets from '../../../hooks/useAssets';
import { useTranslation } from 'react-i18next';

interface CurrencyViewProps {
  balances: AssetBalance[];
  onChange: (assetBalance: AssetBalance) => void;
  defaultValue?: AssetBalance;
}

const CurrencySelect = ({ balances, onChange, defaultValue }: CurrencyViewProps) => {
  const [selectedCurrency, setSelectedCurrency] = React.useState<AssetBalance | null>(defaultValue || balances[0]);
  const [isOpen, setIsOpen] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState('');
  const { isMobile } = useDevice();
  const { t } = useTranslation();
  const { getNetworkDecimal } = useAssets();

  const clearSearch = () => {
    setSearchValue('');
  };

  const SearchedBalances = balances?.filter(item => {
    if (!searchValue) return true;
    return item.assetName.toLowerCase().includes(searchValue.toLowerCase()) || item.assetDisplayName.toLowerCase().includes(searchValue.toLowerCase());
  });

  const handleSearchCurrency = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    setSelectedCurrency(defaultValue || balances[0]);
    onChange(defaultValue || balances[0]);
  }, [defaultValue, balances]);

  useEffect(() => {
    if (!isOpen) {
      clearSearch();
    }
  }, [isOpen]);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [dropdownRef]);

  const handleSelect = (option: AssetBalance) => {
    setSelectedCurrency(option);
    onChange(option);
    setIsOpen(false);
  };

  return (
    <StyledCurrencies ref={dropdownRef}>
      <DropdownSelected onClick={() => setIsOpen(!isOpen)}>
        {selectedCurrency && (
          <SelectedCurrencyItem>
            <img src={selectedCurrency.symbolImage} alt={selectedCurrency.assetName} />
            <StyledCurrencyContent>
              <StyledCurrencyText>
                <Text text={selectedCurrency.assetName} fontStyle={'TextMd_Semibold'} textAlign={'start'} textColor={'white'} />
                <Text text={selectedCurrency.assetDisplayName} fontStyle={'TextSmall_Medium'} textAlign={'start'} textColor={'gray400'} />
              </StyledCurrencyText>
              <StyledCurrencyText>
                <Text text={toFixedFloor(Number(selectedCurrency?.amount ?? 0), getNetworkDecimal(selectedCurrency.assetName), false, true)} fontStyle={'TextMd_Semibold'} textColor={'white'} textAlign={'end'} />
                <Text text={`$${toFixedFloor(Number(selectedCurrency?.currencyAmount ?? 0), 2, false, true)}`} fontStyle={'TextSmall_Medium'} textColor={'gray400'} textAlign={'end'} />
              </StyledCurrencyText>
            </StyledCurrencyContent>
          </SelectedCurrencyItem>
        )}
        <ChevronDown w={16} h={16} color={'white'} />
      </DropdownSelected>

      {isOpen && (
        <StyledPanelWrap>
          <StyledPanel>
            <StyledSearchBox>
              <TextInput bgColor={'gray800'} prefixIcon={'SearchIcon'} value={searchValue} onClear={clearSearch} type={'text'} placeholder={t('DEPOSIT.Search')} maxLength={10} onChange={handleSearchCurrency} />
            </StyledSearchBox>
            <StyledCurrencyItemList>
              {SearchedBalances && SearchedBalances.length > 0 ? (
                SearchedBalances?.map((item, index) => (
                  <StyledCurrencyItem key={index} onClick={() => handleSelect(item)}>
                    <img src={item.symbolImage} alt={item.assetName} />
                    <StyledCurrencyContent>
                      <StyledCurrencyText>
                        <Text text={item.assetName} fontStyle={isMobile ? 'TextSmall_Semibold' : 'TextMd_Semibold'} textAlign={'start'} textColor={'white'} />
                        <Text text={item.assetDisplayName} fontStyle={'TextSmall_Medium'} textAlign={'start'} textColor={'gray400'} />
                      </StyledCurrencyText>
                      <StyledCurrencyText>
                        <Text text={toFixedFloor(Number(item?.amount ?? 0), getNetworkDecimal(item.assetName))} fontStyle={isMobile ? 'TextSmall_Semibold' : 'TextMd_Semibold'} textColor={'white'} textAlign={'end'} />
                        <Text text={`$${toFixedFloor(Number(item?.currencyAmount ?? 0), 2)}`} fontStyle={'TextSmall_Medium'} textColor={'gray400'} textAlign={'end'} />
                      </StyledCurrencyText>
                    </StyledCurrencyContent>
                  </StyledCurrencyItem>
                ))
              ) : (
                <StyledEmpty>
                  <Text text={'No results found'} fontStyle={'TextSmall_Medium'} textColor={'gray400'} />
                </StyledEmpty>
              )}
            </StyledCurrencyItemList>
          </StyledPanel>
        </StyledPanelWrap>
      )}
    </StyledCurrencies>
  );
};
const StyledEmpty = styled.div`
  display: flex;
  width: 100%;
  height: 60px;
  justify-content: center;
  align-items: center;
`;
const StyledCurrencyItemList = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-top: 64px;
  overflow: scroll;
  max-height: 234px;
  @media (max-width: 768px) {
    padding-top: 48px;
  }
`;
const StyledSearchBox = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  padding-bottom: 12px;
  background-color: ${({ theme }) => theme.colors.gray900};
  @media (max-width: 768px) {
    padding-bottom: 8px;
  }
`;
const StyledPanel = styled.div`
  position: relative;
`;

const DropdownSelected = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 16px 19px;
  background-color: ${({ theme }) => theme.colors.gray900};
  border-radius: 12px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid transparent;
  cursor: pointer;

  img {
    width: 32px;
    height: 32px;
  }

  @media (max-width: 768px) {
    padding: 12px 15px;
  }

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.gray600};
  }
`;

const StyledPanelWrap = styled.div`
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 4px;
  padding: 8px;
  z-index: 5;
  background-color: ${({ theme }) => theme.colors.gray900};
  ${({ theme }) => theme.shadow.lg};
`;
const StyledCurrencies = styled.div`
  position: relative;
  width: 100%;
`;
const StyledCurrencyItem = styled.div`
  display: flex;
  padding: 8px 11px;
  flex: 1;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray800};
  }

  @media (max-width: 768px) {
    gap: 8px;
  }

  img {
    width: 32px;
    height: 32px;
  }
`;
const SelectedCurrencyItem = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  gap: 16px;
`;
const StyledCurrencyContent = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

const StyledCurrencyText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export default CurrencySelect;
