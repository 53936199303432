import React from 'react';
import styled from '@emotion/styled';
import { StyledCell, StyledRow, StyledRowHeader, StyledTableBody, StyledTableHeader, TableSection } from './styles';
import useDevice from '../../../hooks/useDevice';
import { useTableContext } from '../../../hooks/TableProvider';
import { numberToDisplayString } from '../../../utils/StringUtil';

export interface TournamentPrizeData {
  minRank: number;
  maxRank: number;
  amount: number;
}

const TournamentInfoTabPrize = () => {
  const { isMobile } = useDevice();
  const { prizePool } = useTableContext();

  const renderRow = (prizeInfo: TournamentPrizeData, i: number) => (
    <TournamentTablesRow key={i} textStyles={'TextSmall_Medium'} selected={false}>
      <TournamentTablesCell color={'white'}>{`${prizeInfo.minRank}${prizeInfo.minRank !== prizeInfo.maxRank ? ' - ' + prizeInfo.maxRank : ''}`}</TournamentTablesCell>
      <TournamentTablesCell color={'white'}>{`${numberToDisplayString(prizeInfo.amount)}${prizePool?.asset.name ? ' ' + prizePool?.asset.name : ''}`}</TournamentTablesCell>
    </TournamentTablesRow>
  );

  return (
    <TournamentTablesContainer>
      <TableSection>
        <StyledTableHeader>
          <TournamentTablesHeader textStyles={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}>
            <TournamentTablesCell color={'gray400'}>Rank</TournamentTablesCell>
            <TournamentTablesCell color={'gray400'}>Award</TournamentTablesCell>
          </TournamentTablesHeader>
        </StyledTableHeader>
        <PrizeBody>{prizePool?.pool?.map(renderRow)}</PrizeBody>
      </TableSection>
    </TournamentTablesContainer>
  );
};

const TournamentTablesContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
`;

const TournamentTablesHeader = styled(StyledRowHeader)`
  grid-template-columns: 1fr 1fr;
  padding: 8px 0;
`;

const TournamentTablesRow = styled(StyledRow)`
  grid-template-columns: 1fr 1fr;
  padding: 8px 0;
  @media (max-width: 768px) {
    padding: 2px 0;
  }
`;

const TournamentTablesCell = styled(StyledCell)`
  padding: 0;
`;

const PrizeBody = styled(StyledTableBody)`
  padding-top: 36px;
`;

export default TournamentInfoTabPrize;
