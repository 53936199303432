import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { holdemApi } from '../api/holdem';
import { CardCombination, CardCombinationMessage, ChatEventPacket, EventName, HeaderAction, HeaderType, TableEventPacket, TableSelectedUser, TableSetting, TableSnapshot } from './streamingTypes';
import { SeatStatus, TableInfo } from '../api/responseTypes';
import { groupLog } from '../../utils/ConsoleUtil';
import uuid from 'react-uuid';
import { createSelector } from 'reselect';
import { findTwoPairRanks, formatCard, numberToDisplayString } from '../../utils/StringUtil';
import { userApi } from '../api/user';
import { checkErrorType, ErrorTypes } from '../api/api';
import i18n from 'i18next';

interface StreamingState {
  tableCodeIdMapper: Record<string, number>;
  tableSnapshot: Record<number, TableEventPacket>;
  tableChat: Record<number, ChatEventPacket[]>;
  tableResponse: Record<number, ErrorTypes | undefined>;
  tableSelectedUser: Record<number, TableSelectedUser>;
  userNote: Record<number, string>;
  tableList: TableInfo[];
}

const initialState: StreamingState = {
  tableList: [],
  tableCodeIdMapper: {},
  tableSnapshot: {},
  tableChat: {},
  tableSelectedUser: {},
  userNote: {},
  tableResponse: {}
};

export function isChatEventPacket(packet: any): packet is ChatEventPacket {
  return (packet as ChatEventPacket).payload?.message !== undefined;
}

export function isTableEventPacket(packet: any): packet is TableEventPacket {
  return (packet as TableEventPacket).payload?.snapshot !== undefined;
}

export function mergeSelectivePropsWithOverride<T>(prev: T | undefined, current: Partial<T> | undefined, overrideFields?: Array<keyof T>): T {
  if (current === undefined) {
    return prev || ({} as T);
  }

  const initialPrev: T = prev || ({} as T);

  const updatedProps = Object.keys(current).reduce((acc, key) => {
    const safeKey = key as keyof T;
    const prevValue = initialPrev[safeKey];
    const currentValue = current[safeKey];

    // If the field is in overrideFields, always override
    if (overrideFields?.includes(safeKey)) {
      acc[safeKey] = currentValue as T[keyof T];
      return acc;
    }

    // If both are objects, recursively merge
    if (typeof prevValue === 'object' && typeof currentValue === 'object' && prevValue !== null && currentValue !== null && !Array.isArray(prevValue) && !Array.isArray(currentValue)) {
      // @ts-ignore
      acc[safeKey] = mergeSelectivePropsWithOverride(prevValue, currentValue, overrideFields) as T[keyof T];
      return acc;
    }

    // If both are arrays, merge arrays
    // if (Array.isArray(prevValue) && Array.isArray(currentValue)) {
    //   acc[safeKey] = Array.from(new Set([...prevValue, ...currentValue])) as T[keyof T];
    //   return acc;
    // }

    // For other cases, use currentValue if defined, else fallback to prevValue
    acc[safeKey] = currentValue !== undefined ? (currentValue as T[keyof T]) : prevValue;

    return acc;
  }, {} as Partial<T>);

  return { ...initialPrev, ...updatedProps } as T;
}

export function mergeSelectivePropsWithOverrideAndRemoveWhenNotExist<T>(prev: T | undefined, current: Partial<T> | undefined, overrideFields?: Array<keyof T>): T {
  if (current === undefined) {
    return prev || ({} as T);
  }

  const initialPrev: T = prev || ({} as T);

  const updatedProps = Object.keys(current).reduce((acc, key) => {
    const safeKey = key as keyof T;
    if (overrideFields && overrideFields.includes(safeKey)) {
      acc[safeKey] = current[safeKey];
    } else if (current[safeKey] !== undefined || current.hasOwnProperty(safeKey)) {
      acc[safeKey] = current[safeKey];
    } else {
      acc[safeKey] = initialPrev[safeKey];
    }
    return acc;
  }, {} as Partial<T>);

  return { ...initialPrev, ...updatedProps };
}

const trimChatEntries = (tableId: number, state: StreamingState) => {
  const maxChatEntries = 200;
  if (state.tableChat[tableId].length > maxChatEntries) {
    state.tableChat[tableId] = state.tableChat[tableId].slice(-maxChatEntries);
  }
};

const streamingSlice = createSlice({
  name: 'Streaming',
  initialState,
  reducers: {
    storeChat: (state, payloadAction: PayloadAction<ChatEventPacket>) => {
      const { name, header, payload } = payloadAction.payload;
      const { tableId } = header;
      groupLog('Packet : Chat', payloadAction.payload);
      if (tableId && payload) {
        /**
         * Chat Snapshot
         */
        switch (name) {
          case EventName.CHAT_ACTION:
          case EventName.CHAT_EVENT:
          case EventName.OBSERVER_CHAT_EVENT:
            if (!state.tableChat[tableId]) {
              state.tableChat[tableId] = [];
            }
            state.tableChat[tableId].push(payloadAction.payload);
            trimChatEntries(tableId, state);
            break;
          case EventName.ERROR:
            break;
          default:
            break;
        }
      }
    },
    storeTable: (state, payloadAction: PayloadAction<TableEventPacket>) => {
      const { header, payload, name } = payloadAction.payload;
      const { tableId } = header;
      const { snapshot: receivedSnapshot, update } = payload;
      groupLog('Packet : Table', payloadAction.payload);
      /**
       * Check Update without Table
       */
      switch (header.action) {
        case HeaderAction.UPDATE_NOTE:
          const noteUserId = update?.noteUserId;
          const noteContents = update?.contents;
          if (noteUserId && noteContents) {
            state.userNote[noteUserId] = noteContents;
          }
          break;
        case HeaderAction.UPDATE_PROFILE:
          break;
        default:
          break;
      }
      /**
       * Check Response
       */
      if (tableId && header.type === HeaderType.RESPONSE && name === EventName.ERROR) {
        const errorType = checkErrorType(payload.error?.code);
        if (errorType) {
          state.tableResponse[tableId] = errorType;
        }
      } else {
        state.tableResponse[tableId] = undefined;
      }
      /**
       * Check Snapshot
       */
      if (tableId && receivedSnapshot) {
        const previousPacket = state.tableSnapshot[tableId] || {};
        /**
         * Key : ShareCode, Value : ID.
         */
        if (tableId && receivedSnapshot?.setting?.shareCode) {
          state.tableCodeIdMapper[receivedSnapshot?.setting?.shareCode] = tableId;
        }
        /**
         * Snapshot
         */
        const overrideFields: Array<keyof TableSetting> = ['tablePassword']; // Override Fields
        const tableSetting = mergeSelectivePropsWithOverrideAndRemoveWhenNotExist(previousPacket?.payload?.snapshot.setting, payloadAction.payload.payload.snapshot.setting, overrideFields);
        const tableSnapshot = mergeSelectivePropsWithOverrideAndRemoveWhenNotExist(previousPacket?.payload?.snapshot, payloadAction.payload.payload.snapshot);

        state.tableSnapshot[tableId] = {
          ...previousPacket,
          name: payloadAction.payload.name,
          header,
          payload: {
            ...payload,
            snapshot: {
              ...tableSnapshot,
              setting: tableSetting
            }
          }
        };
        /**
         * Dealer Message
         */
        if (!state.tableChat[tableId]) {
          state.tableChat[tableId] = [];
        }
        let message = '';
        const requestUserId = update?.requestUserId;
        const user = tableSnapshot.users?.find(user => user.userId === requestUserId);
        const name = user?.name ?? 'Player';
        switch (header.action) {
          case HeaderAction.CHAT_MESSAGE:
          case HeaderAction.CHAT_EMOJI:
          case HeaderAction.USER_JOIN:
          case HeaderAction.USER_SEAT_OUT:
          case HeaderAction.ROUND_HOLECARD:
          case HeaderAction.TABLE_RESERVED_CLOSE:
            break;
          case HeaderAction.TABLE_START:
            const handId = tableSnapshot.hand?.handId;
            message = i18n.t('DEALER.HandStarted', { handId: handId });
            break;
          case HeaderAction.TABLE_CLOSE:
            message = i18n.t('DEALER.TableClosed');
            break;
          case HeaderAction.HAND_BET:
            // const previousValue = previousPacket?.payload?.snapshot.hand?.players?.find(player => player.userId === requestUserId);
            // const actionPlayer = tableSnapshot.hand?.players?.find(player => player.userId === requestUserId);
            // const chipDiff = numberToDisplayString(previousValue?.stack !== undefined && actionPlayer?.stack !== undefined ? previousValue?.stack - actionPlayer?.stack : 0);
            // if (actionPlayer?.lastAction === PlayerAction.ALLIN) {
            //   message = `${name} raises to ${chipDiff} and goes all in`;
            // } else if (actionPlayer?.lastAction === PlayerAction.CHECK) {
            //   message = `${name} checks`;
            // } else if (actionPlayer?.lastAction === PlayerAction.CALL) {
            //   message = `${name} calls ${chipDiff}`;
            // } else if (actionPlayer?.lastAction === PlayerAction.RAISE) {
            //   message = `${name} raises to ${chipDiff}`;
            // } else if (actionPlayer?.lastAction === PlayerAction.BET) {
            //   message = `${name} bets ${chipDiff}`;
            // }
            break;
          case HeaderAction.HAND_FOLD:
            message = i18n.t('DEALER.Folds', { user: name });
            break;
          case HeaderAction.HAND_SHOWHAND:
            const handPlayer = tableSnapshot.hand?.players?.find(player => player.userId === requestUserId);
            const showCardType = update?.requestShowCards;
            let cardsToShow = [];
            if (handPlayer?.cards) {
              if (showCardType?.includes(0)) {
                cardsToShow.push(handPlayer.cards[0]);
              }
              if (showCardType?.includes(1)) {
                cardsToShow.push(handPlayer.cards[1]);
              }
              const showingCard = cardsToShow.slice(0, cardsToShow.length).map(formatCard);
              message = i18n.t('DEALER.Shows', { name: name, showingCard: showingCard.join(', ') });
            }
            break;
          case HeaderAction.USER_LEAVE:
            // message = `${name} leaves the table`;
            break;
          case HeaderAction.USER_STAND:
            // message = `${name} stands the table`;
            break;
          case HeaderAction.USER_SEAT_IN:
            // message = `${name} sits the table`;
            break;
          case HeaderAction.ROUND_PREFLOP:
            break;
          case HeaderAction.ROUND_FLOP:
            const flop = tableSnapshot.hand?.communityCards.slice(0, 3).map(formatCard);
            message = i18n.t('DEALER.Flop', { card: flop!.join(', ') });
            break;
          case HeaderAction.ROUND_TURN:
            const turn = tableSnapshot.hand?.communityCards.slice(0, 3).map(formatCard);
            const newTurnCard = formatCard(tableSnapshot.hand?.communityCards[3]!);
            message = i18n.t('DEALER.Turn', { communityCards: turn!.join(', '), turnCard: newTurnCard });
            break;
          case HeaderAction.ROUND_RIVER:
            const river = tableSnapshot.hand?.communityCards.slice(0, 4).map(formatCard);
            const newRiverCard = formatCard(tableSnapshot.hand?.communityCards[4]!);
            message = i18n.t('DEALER.Turn', { communityCards: river!.join(', '), turnCard: newRiverCard });
            break;
          case HeaderAction.ROUND_SETTLEMENT:
            const winners = tableSnapshot.hand?.winners;
            winners?.map((winner, index) => {
              const seat = tableSnapshot.seats?.find(seat => seat.seatId === winner.seatId);
              const user = tableSnapshot.users?.find(user => user.userId === seat?.userId);
              const player = tableSnapshot.hand?.players.find(player => player.seatId === winner.seatId);
              if (player) {
                const isCardRevealed = player.isCardRevealed;
                const combination = player.handStrength?.combination;
                const strength = combination ? i18n.t(`TABLE.${CardCombinationMessage[combination]}`) : '';
                let rank = player.handStrength?.rank;
                const card = player.handStrength?.bestHand;
                const isEnglish = i18n.language === 'en';
                if (strength && card && isCardRevealed) {
                  if (strength && card) {
                    switch (combination) {
                      case CardCombination.TWO_PAIR:
                        rank = `, ${findTwoPairRanks(card, isEnglish)}`;
                        break;
                      default:
                        break;
                    }
                  }
                  message += i18n.t('DEALER.Win', { user: user?.name, amount: numberToDisplayString(winner?.winAmount), strength: strength, rank: rank });
                } else {
                  message += i18n.t('DEALER.WinNoReveal', { user: user?.name, amount: numberToDisplayString(winner?.winAmount), strength: '', rank: '' });
                }
                if (index + 1 < winners?.length) {
                  message += '\n';
                }
              }
            });
            break;
        }
        if (message.length > 0) {
          const dealerChatFormat: ChatEventPacket = {
            name: EventName.CHAT_EVENT,
            header: {
              type: HeaderType.PRIVATE,
              tableId: tableId,
              userId: undefined,
              action: HeaderAction.CHAT_MESSAGE,
              messageId: uuid()
            },
            payload: {
              userId: -1,
              message: message,
              timestamp: Date.now().toString()
            }
          };
          state.tableChat[tableId].push(dealerChatFormat);
          trimChatEntries(tableId, state);
        }
      }
    },
    selectTableUser: (state, payloadAction: PayloadAction<{ tableId: number; userId: number | undefined }>) => {
      state.tableSelectedUser[payloadAction.payload.tableId] = { userId: payloadAction.payload.userId ?? -1, timestamp: Date.now().toString(), user: undefined };
    },
    updateUserNote: (state, payloadAction: PayloadAction<{ userId: number; contents: string }>) => {
      state.userNote[payloadAction.payload.userId] = payloadAction.payload.contents;
    }
  },
  extraReducers: builder => {
    builder.addMatcher(holdemApi.endpoints.holdemTableList.matchFulfilled, (state, { payload }) => {
      state.tableList = payload.data;
    });
    builder.addMatcher(userApi.endpoints.updateUserNote.matchFulfilled, (state, { payload }) => {
      state.userNote[payload.data.noteUserId] = payload.data.contents;
    });
    builder.addMatcher(userApi.endpoints.getUserNote.matchFulfilled, (state, { payload }) => {
      state.userNote[payload.data.noteUserId] = payload.data.contents;
    });
  }
});

const getTableSnapshots = (state: RootState) => state.streamingSlice.tableSnapshot;
export const selectTablePacket = createSelector([getTableSnapshots, (_: RootState, tableId: number) => tableId], (tableSnapshots, tableId) => {
  return tableSnapshots[tableId] || [];
});

export const selectedTableErrorType = (state: RootState, tableId?: number) => (tableId !== undefined ? state.streamingSlice.tableResponse[tableId] : undefined);
export const selectPasswordVerify = (state: RootState, tableId?: number) => (tableId !== undefined ? state.streamingSlice.tableSnapshot[tableId]?.header?.action === HeaderAction.VERIFY_PASSWORD : false);
export const selectedUser = (state: RootState, tableId?: number): TableSelectedUser | undefined => {
  if (tableId !== undefined) {
    return {
      userId: state.streamingSlice.tableSelectedUser[tableId]?.userId,
      timestamp: state.streamingSlice.tableSelectedUser[tableId]?.timestamp,
      user: state.streamingSlice.tableSnapshot[tableId]?.payload?.snapshot?.users?.find(user => user.userId === state.streamingSlice.tableSelectedUser[tableId]?.userId)
    };
  } else {
    return undefined;
  }
};
export const selectedUserNote = (state: RootState, userId?: number) => (userId ? state.streamingSlice.userNote[userId] : undefined);
export const { selectTableUser, updateUserNote } = streamingSlice.actions;

export const selectTableList = (state: RootState) => state.streamingSlice.tableList;

export const selectTableIdByShareCode = (state: RootState, shareCode: string) => state.streamingSlice.tableCodeIdMapper[shareCode];

const getSeatsByTableId = (state: RootState, tableId: number) => state.streamingSlice.tableSnapshot[tableId]?.payload?.snapshot?.seats;
export const selectIsSeatInPrePared = createSelector([getSeatsByTableId, (_: RootState, __: number, userId: number) => userId], (seats, userId) => {
  const seat = seats?.find(seat => seat.userId === userId);
  return seat?.status === SeatStatus.RESERVED || seat?.status === SeatStatus.SEAT_OUT;
});

const getTableChat = (state: RootState) => state.streamingSlice.tableChat;
export const selectTableChatByTableId = createSelector([getTableChat, (_: RootState, tableId: number) => tableId], (tableChat, tableId) => tableChat[tableId] || []);

export default streamingSlice;
