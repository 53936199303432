import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum ModalStatus {
  Approve = 'APPROVE',
  Cancel = 'CANCEL'
}

export interface ModalRequestDto {
  type: ModalType;
  data: any;
}

export interface ModalRequest {
  data: any;
}

export interface ModalResponse {
  type: ModalType;
  status: ModalStatus;
  data: any;
}

export enum ModalType {
  AuthModal = 'AUTH_MODAL',
  CodeVerificationModal = 'CODE_VERIFICATION_MODAL',
  ProfileModal = 'PROFILE_MODAL',
  CashierModal = 'CASHIER_MODAL',
  BuyInModal = 'JOIN_MODAL',
  LogoutModal = 'LOGOUT_MODAL',
  StandModal = 'STAND_MODAL',
  LeaveModal = 'LEAVE_MODAL',
  RestrictedModal = 'RESTRICTED_MODAL',
  ClosedByOwnerModal = 'TABLE_CLOSED_OWNER_MODAL',
  ClosedByServerModal = 'TABLE_CLOSED_SERVER_MODAL',
  CloseModal = 'TABLE_CLOSE_MODAL',
  ChatModal = 'CHAT_MODAL',
  SeedModal = 'SEED_MODAL',
  CreateTableModal = 'CREATE_TABLE',
  PasswordVerifyModal = 'PASSWORD_MODAL',
  TablePreviewModal = 'TABLE_PREVIEW_MODAL',
  TableDisconnectedModal = 'TABLE_DISCONNECTED',
  ConnectingWalletModal = 'CONNECTING_WALLET_MODAL',
  DepositModal = 'DEPOSIT_MODAL',
  WithdrawModal = 'WITHDRAW_MODAL',
  SendModal = 'SEND_MODAL',
  BreakTimeModal = 'BREAK_TIME_MODAL',
  TournamentInfoModal = 'TOURNAMENT_INFO_MODAL',
  TournamentResultModal = 'TOURNAMENT_RESULT_MODAL',
  TournamentRegisterModal = 'TOURNAMENT_REGISTER_MODAL',
  TournamentUnregisterModal = 'TOURNAMENT_UNREGISTER_MODAL',
  TournamentDeleteModal = 'TOURNAMENT_DELETE_MODAL',
  TournamentPreviewModal = 'TournamentPreviewModal'
}

type ModalsState = {
  modals: Record<ModalType, boolean>;
  currentModal: ModalType | null;
  modalRequest: ModalRequest | null;
  modalResponse: ModalResponse | null;
};

const initialState: ModalsState = {
  modals: Object.values(ModalType).reduce(
    (acc, modal) => {
      acc[modal] = false;
      return acc;
    },
    {} as Record<ModalType, boolean>
  ),
  currentModal: null,
  modalRequest: null,
  modalResponse: null
};

const closeAllModals = (modals: Record<ModalType, boolean>) => {
  Object.keys(modals).forEach(modal => {
    modals[modal as ModalType] = false;
  });
};

const modalStateSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    showModal: (state, action: PayloadAction<ModalType>) => {
      closeAllModals(state.modals);
      state.modals[action.payload] = true;
      state.currentModal = action.payload;
    },
    showModalWithRequest: (state, action: PayloadAction<ModalRequestDto>) => {
      closeAllModals(state.modals);
      state.modals[action.payload.type] = true;
      state.currentModal = action.payload.type;
      state.modalRequest = { data: action.payload.data };
    },
    dismissModal: state => {
      closeAllModals(state.modals);
      state.currentModal = null;
    },
    closeModal: (state, action: PayloadAction<ModalType>) => {
      if (state.currentModal === action.payload) {
        closeAllModals(state.modals);
        state.modals[action.payload] = false;
        state.currentModal = null;
        state.modalResponse = null;
      }
    },
    toggleModal: (state, action: PayloadAction<ModalType>) => {
      const isOpen = !state.modals[action.payload];
      closeAllModals(state.modals);
      state.modals[action.payload] = isOpen;
      state.currentModal = isOpen ? action.payload : null;
    },
    setModalResponse: (state, action: PayloadAction<ModalResponse | null>) => {
      state.modalResponse = action.payload;
    }
  }
});

export const { showModal, showModalWithRequest, dismissModal, toggleModal, setModalResponse, closeModal } = modalStateSlice.actions;

export default modalStateSlice;
