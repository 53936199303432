import React, { useEffect, useState, useCallback } from 'react';
import styled from '@emotion/styled';
import { LeftText } from '../../common/text/styles';
import LinearLayout from '../../layout/LinearLayout';
import TextInput from '../../common/input/TextInput';
import { useUserProfile } from '../../../hooks/useUserProfile';
import { useLazyCheckValidUsernameQuery, useUpdateProfileMutation } from '../../../store/api/user';
import { Profile } from '../../../store/api/responseTypes';
import CountrySelect from '../../common/select/CountrySelect';
import ToggleSwitch from '../../common/switch/ToggleSwitch';
import Text from '../../common/text/Text';
import ProfileImageSelectionTemplate from './ProfileImageSelectionTemplate';
import { logError } from '../../../utils/ConsoleUtil';
import { showFailToast, showSuccessToast } from '../../common/toast/BpToast';
import { ReactComponent as GoogleIcon } from 'src/assets/img/auth_google.svg';
import { ReactComponent as TwitterIcon } from 'src/assets/img/auth_x.svg';
import { ReactComponent as WalletIcon } from 'src/assets/img/auth_wallet.svg';
import { getErrorMessageByCode } from '../../../store/api/api';
import { debounce } from 'lodash';
import KycTemplate from './KycTemplate';
import BaseButton from '../../common/button/BaseButton';
import HorizontalLayout from '../../layout/HorizontalLayout';
import useDevice from '../../../hooks/useDevice';
import { truncateString } from '../../../utils/StringUtil';
import { useTranslation } from 'react-i18next';

const ProfileTemplate = () => {
  const { user } = useUserProfile();
  const { t: translation } = useTranslation();
  const [updateProfile] = useUpdateProfileMutation();
  const [checkUsername] = useLazyCheckValidUsernameQuery();
  const [tempProfile, setTempTempProfile] = useState<Profile>(user!);
  const [isProfileSelectionView, setIsProfileSelectionView] = useState<boolean>(false);
  const [mailErrorMsg, setMailErrorMsg] = useState<string | undefined>(undefined);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const [isProfileChanged, setProfileChanged] = useState<boolean>(false);
  const [isNotificationChanged, setNotificationChanged] = useState<boolean>(false);

  const { isMobile } = useDevice();

  const check = useCallback(
    debounce((username: string, prevUsername: string) => {
      if (!/^[a-zA-Z_][a-zA-Z0-9_.-]{3,11}$/.test(username)) {
        setMailErrorMsg('Invalid username. It must be 4-12 characters long and can only contain letters, numbers, and underscores.');
        return;
      }
      checkUsername({ name: username.trim().toLowerCase() })
        .unwrap()
        .then(response => {
          if (response) {
            setMailErrorMsg(undefined);
          } else {
            setMailErrorMsg('This username is not allowed. Please choose a different username.');
          }
        })
        .catch(error => {
          setMailErrorMsg('This username is not allowed. Please choose a different username.');
        });
    }, 250),
    []
  );

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    check.cancel();
    const username = e.target.value;
    handleProfileChanged('name', username);

    const prevUsername = tempProfile.name;
    check(username, prevUsername);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) || email === '') {
      handleProfileChanged('email', email);
      setIsEmailValid(true);
    } else {
      handleProfileChanged('email', email);
      setIsEmailValid(false);
    }
  };

  const handleProfileChanged = (key: keyof Profile, value: string) => {
    setTempTempProfile(prevProfile => ({
      ...prevProfile,
      [key]: value
    }));
  };

  const toggleSetting = () => {
    setTempTempProfile(prevProfile => {
      return {
        ...prevProfile,
        isNotificationEnabled: !prevProfile['isNotificationEnabled']
      };
    });
  };

  const saveProfile = () => {
    const isUsernameChanged = user?.name !== tempProfile.name;
    updateProfile({ ...tempProfile })
      .unwrap()
      .then(data => {
        showSuccessToast(isUsernameChanged ? 'Profile updated' : 'Flag updated');
      })
      .catch(error => {
        if (error && 'code' in error) {
          showFailToast(getErrorMessageByCode(error.code));
        }
      });
  };

  const saveNotification = () => {
    updateProfile({ ...tempProfile })
      .unwrap()
      .then(data => {
        showSuccessToast('Email settings updated');
      })
      .catch(error => {
        if (error && 'code' in error) {
          showFailToast(getErrorMessageByCode(error.code));
        }
      });
  };

  const profileClear = () => {
    if (user) {
      setTempTempProfile(prev => ({ ...prev, name: user.name, country: user.country }));
      setMailErrorMsg(undefined);
    }
  };
  const notificationClear = () => {
    if (user) {
      setTempTempProfile(prev => ({ ...prev, isNotificationEnabled: user.isNotificationEnabled, email: user.email }));
      setIsEmailValid(true);
    }
  };

  const getAuthIcon = () => {
    switch (user?.accountType) {
      case 'EMAIL':
        return <GoogleIcon width={20} height={20} />;
      case 'WALLET_CONNECT':
        return <WalletIcon width={20} height={20} />;
      case 'OAUTH_GOOGLE':
        return <GoogleIcon width={20} height={20} />;
      case 'OAUTH_TWITTER':
        return <TwitterIcon width={20} height={20} />;
      default:
        return;
    }
  };

  const getAuthMethod = () => {
    switch (user?.accountType) {
      case 'EMAIL':
        return 'Email';
      case 'WALLET_CONNECT':
        return 'Wallet';
      case 'OAUTH_GOOGLE':
        return 'Google';
      case 'OAUTH_TWITTER':
        return 'Twitter';
      default:
        return '';
    }
  };

  useEffect(() => {
    setProfileChanged(user?.country !== tempProfile?.country || user?.name !== tempProfile?.name);
    setNotificationChanged(user?.isNotificationEnabled !== tempProfile?.isNotificationEnabled || user?.email !== tempProfile?.email);
  }, [tempProfile, user]);

  if (isProfileSelectionView) {
    return <ProfileImageSelectionTemplate setProfileImageView={setIsProfileSelectionView} />;
  }
  return (
    <Wrap>
      <LinearLayout backgroundColor={'gray800'} borderRadius={12} padding={40}>
        <AvatarSection>
          <AvatarLabel>{translation('PROFILE.YourAvatar')}</AvatarLabel>
          <Avatar src={user?.profile} onClick={() => setIsProfileSelectionView(true)} />
        </AvatarSection>
        <LinearLayout>
          <LeftText fontStyle={'TextMd_Medium'} text={translation('PROFILE.Username')} marginTop={24} marginBottom={isMobile ? 2 : 4} />
          <TextInput disabled={user?.isNameEditable === false} type={'text'} value={tempProfile.name ?? user?.name} onChange={handleUsernameChange} />
          {mailErrorMsg === undefined && user?.isNameEditable && <Text fontStyle={'TextSmall_Medium'} textColor={'gray400'} textAlign={'start'} text={translation('MESSAGE.UsernameChangeWarning')} marginTop={4} />}
          {mailErrorMsg !== undefined && <Text fontStyle={'TextSmall_Medium'} textColor={'error500'} textAlign={'start'} text={mailErrorMsg} marginTop={4} />}
          <LeftText fontStyle={'TextMd_Medium'} text={translation('PROFILE.Flag')} marginTop={24} marginBottom={isMobile ? 2 : 4} />
          <CountrySelect
            value={tempProfile?.country ?? user?.country}
            onChange={value => {
              handleProfileChanged('country', value);
            }}
          />
          {user?.accountType !== 'EMAIL' && (
            <>
              <Text fontStyle={isMobile ? 'TextSmall_Medium' : 'TextMd_Medium'} textAlign={'start'} text={translation('PROFILE.AuthConnected', { METHOD: '' })} textColor={'gray300'} marginTop={24} />
              <StyledAuthWrap>
                {getAuthIcon()}
                <Text fontStyle={isMobile ? 'TextSmall_Medium' : 'TextMd_Medium'} text={getAuthMethod()} />
                {user?.accountType === 'WALLET_CONNECT' && user?.walletAddress && <StyledWalletAddressView>{truncateString(user?.walletAddress)}</StyledWalletAddressView>}
              </StyledAuthWrap>
            </>
          )}
        </LinearLayout>
        {mailErrorMsg === undefined && isProfileChanged && (
          <HorizontalLayout gap={isMobile ? 8 : 12} margin={'16px 0 0 0'} justifyContent={'end'}>
            <BaseButton w={124} color={'text'} textStyle={isMobile ? 'TextSmall_Semibold' : 'TextMd_Semibold'} text={'Cancel'} size={isMobile ? 'btn40' : 'btn48'} onClick={profileClear} />
            <BaseButton w={124} text={'Save'} textStyle={isMobile ? 'TextSmall_Semibold' : 'TextMd_Semibold'} color={'primary'} size={isMobile ? 'btn40' : 'btn48'} disabled={!isProfileChanged} onClick={saveProfile} />
          </HorizontalLayout>
        )}
      </LinearLayout>
      <LinearLayout backgroundColor={'gray800'} borderRadius={12} padding={40} marginTop={8}>
        <LeftText fontStyle={'TextMd_Medium'} text={translation('PROFILE.Email')} marginBottom={isMobile ? 2 : 4} />
        <TextInput type={'email'} value={tempProfile.email ?? user?.email} placeholder={translation('PROFILE.EnterEmailHint')} onChange={handleEmailChange} />
        {!isEmailValid && <Text fontStyle={'TextSmall_Medium'} textColor={'error500'} textAlign={'start'} text={'Please enter a valid email address'} marginTop={4} />}
        <SettingItem marginTop={24}>
          <LinearLayout>
            <NotificationTitle>{translation('PROFILE.AlarmTitle')}</NotificationTitle>
            <SettingLabel>{translation('PROFILE.AlarmDescription')}</SettingLabel>
          </LinearLayout>
          <ToggleSwitch
            isActive={tempProfile?.isNotificationEnabled ?? false}
            onClick={() => {
              toggleSetting();
            }}
          />
        </SettingItem>
        {isNotificationChanged && (
          <HorizontalLayout gap={isMobile ? 8 : 12} margin={'16px 0 0 0'} justifyContent={'end'}>
            <BaseButton w={124} color={'text'} textStyle={isMobile ? 'TextSmall_Semibold' : 'TextMd_Semibold'} text={translation('BUTTON.Cancel')} size={isMobile ? 'btn40' : 'btn48'} onClick={notificationClear} />
            <BaseButton
              w={124}
              text={'Save'}
              textStyle={isMobile ? 'TextSmall_Semibold' : 'TextMd_Semibold'}
              color={'primary'}
              size={isMobile ? 'btn40' : 'btn48'}
              disabled={!(isNotificationChanged && isEmailValid)}
              onClick={() => {
                if (isNotificationChanged && isEmailValid) {
                  saveNotification();
                }
              }}
            />
          </HorizontalLayout>
        )}
      </LinearLayout>
      <KycTemplate />
    </Wrap>
  );
};

export default ProfileTemplate;

const StyledWalletAddressView = styled.div`
  ${({ theme }) => theme.textStyles.TextMd_Medium};
  color: ${({ theme }) => theme.colors.gray400};
  margin-left: 8px;
  padding: 4px 12px;
  border-radius: 100px;
  background-color: ${({ theme }) => theme.colors.gray900};
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextSmall_Medium};
  }
`;

const Wrap = styled.div`
  width: 100%;
`;

const AvatarSection = styled.div`
  flex-direction: column;
  align-self: center;
`;

const Avatar = styled.img`
  margin-top: 4px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.gray600};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;

  img {
    width: 65%;
    height: auto;
  }

  svg {
    width: 65%;
    height: auto;
    fill: currentColor;
  }
`;

const AvatarLabel = styled.div`
  ${({ theme }) => theme.textStyles.TextMd_Medium};
  color: ${({ theme }) => theme.colors.gray300};
  text-align: center;
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextSmall_Medium};
  }
`;

const StyledAuthWrap = styled.div`
  width: 100%;
  display: flex;
  gap: 4px;
  justify-content: start;
  align-content: center;
  align-items: center;
  box-sizing: border-box;
  margin-top: 8px;
`;

const StyledText = styled(Text)`
  justify-content: start;
  margin-left: 13px;
  color: ${({ theme }) => theme.colors.gray400};
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.gray900};
  padding: 4px 12px;
`;

const SettingItem = styled.div<{ marginTop: number }>`
  width: 100%;
  display: flex;
  gap: 16px;
  min-height: 68px;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  margin-top: ${({ marginTop }) => marginTop}px;
  box-sizing: border-box;
`;

const NotificationTitle = styled.label`
  ${({ theme }) => theme.textStyles.TextMd_Semibold};
  color: ${({ theme }) => theme.colors.white};
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextSmall_Semibold};
  }
`;

const SettingLabel = styled.label`
  ${({ theme }) => theme.textStyles.TextSmall_Medium};
  color: ${({ theme }) => theme.colors.gray400};
`;

const ButtonWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: end;
  flex-direction: row;
  gap: 12px;
  @media (max-width: 768px) {
    justify-content: space-between;
  }
`;

const Cancel = styled.button`
  ${({ theme }) => theme.textStyles.TextMd_Semibold};
  min-width: 124px;
  background-color: transparent;
  border-style: none;
  color: ${({ theme }) => theme.colors.gray200};
  cursor: pointer;
  padding: 12px 20px;
  user-select: none;
  @media (max-width: 768px) {
    flex: 1;
  }
  transition: background-color 0.2s;

  &:hover {
    color: ${({ theme }) => theme.colors.gray600};
  }
`;

const SaveButton = styled.button<{ hasChanges: boolean }>`
  width: 124px;
  height: 48px;
  margin-top: 40px;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  color: ${({ hasChanges, theme }) => (hasChanges ? theme.colors.white : theme.colors.gray500)};
  cursor: pointer;
  ${({ theme }) => theme.textStyles.TextMd_Medium};
  background-color: ${({ hasChanges, theme }) => (hasChanges ? theme.colors.primary600 : theme.colors.gray700)};
  align-self: end;
  @media (max-width: 768px) {
    margin-top: 24px;
  }
  transition: background-color 0.2s;

  &:hover {
    background-color: ${({ theme, hasChanges }) => (hasChanges ? theme.colors.primary500 : theme.colors.gray700)};
  }
`;
