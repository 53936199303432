import React, { useCallback, useEffect } from 'react';
import TextInput from '../../common/input/TextInput';
import useDevice from '../../../hooks/useDevice';
import styled from '@emotion/styled';
import { useLazySearchRecipientUserQuery } from '../../../store/api/user';
import { debounce } from 'lodash';
import { FoundUser } from '../../../store/api/responseTypes';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { ClearIcon } from '../../common/icon';
import { useTranslation } from 'react-i18next';

interface SearchUserInputProps {
  selectedUser: FoundUser | null;
  onSelect: (user: FoundUser | null) => void;
}

const SearchUserInput = ({ onSelect, selectedUser }: SearchUserInputProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState('');
  const [foundUsers, setFoundUsers] = React.useState<FoundUser[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const { isMobile } = useDevice();
  const { t } = useTranslation();
  const ref = React.useRef<HTMLDivElement>(null);

  const [getSearchRecipients] = useLazySearchRecipientUserQuery();

  const search = useCallback(
    debounce((userName: string) => {
      getSearchRecipients({ userName: userName.trim().toLowerCase() })
        .unwrap()
        .then(response => {
          setFoundUsers(response.data);
          setIsLoading(false);
        })
        .catch(error => {});
    }, 250),
    []
  );

  const handleSearchUser = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const validPattern = /^[a-zA-Z_][a-zA-Z0-9_.-]*$/;
    if (inputValue.length === 0) {
      search.cancel();
      setSearchValue('');
      setFoundUsers([]);
      setIsLoading(false);
      setIsOpen(false);
    } else if (inputValue.length <= 12 && validPattern.test(inputValue)) {
      search.cancel();
      setSearchValue(inputValue);
      setIsOpen(true);
      setIsLoading(true);
      search(inputValue);
    }
  };

  const clearSearch = () => {
    setSearchValue('');
    setFoundUsers([]);
    setIsOpen(false);
    onSelect(null);
  };

  const selectUser = (user: FoundUser) => {
    onSelect(user);
    setSearchValue(user.userName);
    setIsOpen(false);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [ref]);

  return (
    <DropdownContainer ref={ref}>
      {selectedUser ? (
        <>
          <SelectedItem>
            <DropdownImage src={selectedUser.profileImageUrl} alt={selectedUser.userId + ''} />
            <DropdownText>{selectedUser.userName}</DropdownText>
          </SelectedItem>
          <StyledClearIconWrap onClick={clearSearch}>
            <ClearIcon w={isMobile ? 20 : 24} />
          </StyledClearIconWrap>
        </>
      ) : (
        <TextInput value={searchValue} onClear={clearSearch} type={'text'} placeholder={t('SEND.EnterUserName')} onChange={handleSearchUser} />
      )}

      {isOpen && (
        <DropdownList>
          {isLoading && (
            <SkeletonTheme enableAnimation={false}>
              {new Array(3).fill(null).map((_, index) => (
                <StyledSkeletonWrap key={index}>
                  <Skeleton height={24} width={24} circle={true} style={{ background: 'linear-gradient(45deg, #101828 0%, #475467 100%)' }} />
                  <Skeleton height={12} width={104} style={{ background: 'linear-gradient(45deg, #101828 0%, #475467 100%)', verticalAlign: 'middle' }} />
                </StyledSkeletonWrap>
              ))}
            </SkeletonTheme>
          )}
          {foundUsers.map(user => (
            <DropdownItem key={user.userId} onClick={() => selectUser(user)}>
              <DropdownImage src={user.profileImageUrl} alt={user.userId + ''} />
              <DropdownText>{user.userName}</DropdownText>
            </DropdownItem>
          ))}
          {foundUsers.length === 0 && !isLoading && <StyledEmptyText>No users found</StyledEmptyText>}
        </DropdownList>
      )}
    </DropdownContainer>
  );
};

const StyledClearIconWrap = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 16px;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 20px;
    height: 20px;
  }
`;

const StyledEmptyText = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.textStyles.TextMd_Medium};
  color: ${({ theme }) => theme.colors.gray400};
  height: 160px;
  @media (max-width: 768px) {
    max-height: 150px;
  }
`;

const DropdownContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 52px;
  background-color: ${({ theme }) => theme.colors.gray900};
  border-radius: 8px;
  @media (max-width: 768px) {
    height: 40px;
  }
`;

const DropdownList = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 12px 8px;
  box-sizing: border-box;
  max-height: 160px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray900};
  border-radius: 8px;
  overflow-y: auto;
  z-index: 15;
  top: calc(100% + 5px);
  gap: 8px;
  @media (max-width: 768px) {
    max-height: 150px;
    gap: 4px;
  }
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.3);
`;
const DropdownItemParent = styled.div`
  display: flex;
  flex: 1;
  cursor: pointer;
  min-height: 40px;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  border-radius: 6px;
  @media (max-width: 768px) {
    min-height: 40px;
  }
`;

const SelectedItem = styled(DropdownItemParent)`
  padding-left: 16px;
  cursor: default;
`;

const DropdownItem = styled(DropdownItemParent)`
  padding: 0 8px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray800};
  }
`;

const StyledSkeletonWrap = styled(DropdownItemParent)`
  padding: 0 8px;
  gap: 8px;
  cursor: default;
`;

const DropdownImage = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 8px;
  @media (max-width: 768px) {
    width: 20px;
    height: 20px;
  }
`;

const DropdownText = styled.div`
  ${({ theme }) => theme.textStyles.TextMd_Medium};
  color: ${({ theme }) => theme.colors.white};
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextSmall_Medium};
  }
`;

export default SearchUserInput;
