import { AssetType, TableStatus, SeatStatus, Setting, MoneyType } from '../api/responseTypes';
import { TournamentInfo, TableInformation, CashGameInfo } from './tournamentTableTypes';
import { TournamentPlayer } from './tournamentTypes';

export type CardNum = 'A' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | 'J' | 'Q' | 'K';

export type CardSuit = 'S' | 'H' | 'D' | 'C';

export type Card = `${CardNum}${CardSuit}` | 'XX';

export const cardSymbols: Record<CardSuit, string> = {
  S: '♠',
  H: '♥',
  D: '♦',
  C: '♣'
};

export enum GameStyle {
  HOLDEM = 'HOLDEM',
  ROYAL = 'ROYAL',
  OMAHA = 'OMAHA',
  OMAHA_HILO = 'OMAHA_HILO',
  SEVEN_CARD_STUD = 'SEVEN_CARD_STUD',
  SEVEN_CARD_STUD_HILO = 'SEVEN_CARD_STUD_HILO'
}

export enum GameLimit {
  NO_LIMIT = 'NO_LIMIT',
  FIXED_LIMIT = 'FIXED_LIMIT',
  POT_LIMIT = 'POT_LIMIT'
}

export enum GameType {
  HDM = 'HDM',
  OHM = 'OHM'
}

export enum CardCombination {
  HIGH_CARD = 'HIGH_CARD',
  ONE_PAIR = 'ONE_PAIR',
  TWO_PAIR = 'TWO_PAIR',
  THREE_OF_A_KIND = 'THREE_OF_A_KIND',
  STRAIGHT = 'STRAIGHT',
  FLUSH = 'FLUSH',
  FULL_HOUSE = 'FULL_HOUSE',
  FOUR_OF_A_KIND = 'FOUR_OF_A_KIND',
  STRAIGHT_FLUSH = 'STRAIGHT_FLUSH',
  ROYAL_STRAIGHT_FLUSH = 'ROYAL_STRAIGHT_FLUSH'
}

export const CardCombinationMessage: Record<CardCombination, string> = {
  [CardCombination.HIGH_CARD]: 'HighCard',
  [CardCombination.ONE_PAIR]: 'OnePair',
  [CardCombination.TWO_PAIR]: 'TwoPairs',
  [CardCombination.THREE_OF_A_KIND]: 'ThreeOfAKind',
  [CardCombination.STRAIGHT]: 'Straight',
  [CardCombination.FLUSH]: 'Flush',
  [CardCombination.FULL_HOUSE]: 'FullHouse',
  [CardCombination.FOUR_OF_A_KIND]: 'FourOfAKind',
  [CardCombination.STRAIGHT_FLUSH]: 'StraightFlush',
  [CardCombination.ROYAL_STRAIGHT_FLUSH]: 'RoyalFlush'
};

export enum GameRound {
  PRE_FLOP = 'PREFLOP',
  FLOP = 'FLOP',
  TURN = 'TURN',
  RIVER = 'RIVER',
  SHOWDOWN = 'SHOWDOWN',
  SETTLEMENT = 'SETTLEMENT'
}

export enum EventName {
  TOURNAMENT_ACTION = 'TOURNAMENT_ACTION',
  TOURNAMENT_TABLE_ACTION = 'GAME_TABLE_ACTION',

  CHAT_ACTION = 'CHAT_ACTION',
  CHAT_EVENT = 'CHAT_EVENT',
  TABLE_ACTION = 'TABLE_ACTION',
  TABLE_EVENT = 'TABLE_EVENT',
  HAND_PROGRESS_EVENT = 'HAND_PROGRESS_EVENT',
  HAND_ACTION = 'HAND_ACTION',
  HAND_EVENT = 'HAND_EVENT',
  ERROR = 'ERROR',

  OBSERVER_EVENT = 'OBSERVER',
  GENERAL_EVENT = 'GENERAL',
  OBSERVER_CHAT_EVENT = 'OBSERVER_CHAT'
}

export enum HeaderType {
  EVENT = 'EVENT',
  REQUEST = 'REQUEST',
  PRIVATE = 'PRIVATE_EVENT',
  RESPONSE = 'RESPONSE'
}

export enum HeaderAction {
  CONNECT = 'CONNECT',
  REGISTER = 'REGISTER',
  UNREGISTER = 'UNREGISTER',

  USER_JOIN = 'JOIN',
  USER_SEAT_OUT = 'SEAT_OUT',
  USER_SEAT_IN = 'SEAT_IN',
  USER_STAND = 'STAND',
  USER_LEAVE = 'LEAVE',
  TABLE_START = 'START',
  TABLE_PAUSE = 'PAUSE',
  TABLE_RESERVED_CLOSE = 'RESERVE_CLOSED',
  TABLE_CLOSE = 'CLOSE',
  ROUND_PREFLOP = 'PREFLOP',
  ROUND_HOLECARD = 'HOLECARD',
  ROUND_FLOP = 'FLOP',
  ROUND_TURN = 'TURN',
  ROUND_RIVER = 'RIVER',
  ROUND_SETTLEMENT = 'SETTLEMENT',
  HAND_BET = 'BET',
  HAND_FOLD = 'FOLD',
  HAND_SHOWHAND = 'SHOW_CARD',
  CHAT_MESSAGE = 'MESSAGE',
  CHAT_EMOJI = 'EMOJI',
  UPDATE_NOTE = 'UPDATE_NOTE',
  UPDATE_PROFILE = 'UPDATE_PROFILE',
  VERIFY_PASSWORD = 'VERIFY_PASSWORD',
  ADD_CHIPS = 'ADD_CHIPS',
  ALLOW_ADD_CHIPS = 'ALLOW_ADD_CHIPS',

  // TODO : ADD ON TOURNAMENT
  TOURNAMENT_CONNECT = 'CONNECT',
  TOURNAMENT_REBALANCING = 'REBALANCING'
}

export enum PlayerStatus {
  WAIT = 'WAIT',
  ASK = 'ASK',
  FOLD = 'FOLD',
  CASHGAME_ALL_IN = 'CASHGAME_ALL_IN', // TODO : Be Deprecated
  ALLIN = 'ALLIN'
}

export enum PlayerAction {
  BET = 'BET',
  RAISE = 'RAISE',
  CHECK = 'CHECK',
  FOLD = 'FOLD',
  CALL = 'CALL',
  ALLIN = 'ALLIN'
}

export interface HandStrength {
  bestHand: Card[];
  combination: CardCombination;
  kickers: string[];
  rank: string;
  strength: number;
}

export interface TableUserSettingRequestDto {
  shareCode: string | null;
  setting: Setting;
}

/**
 * GATEWAY
 */
export interface ChatEventPacket {
  name: EventName;
  header: EventHeader;
  payload: ChatEventPayload;
}

export interface TableEventPacketWithDelay extends TableEventPacket {
  delay: number;
}

export interface TableEventPacket {
  name: EventName;
  header: EventHeader;
  payload: EventPayload;
}

export interface EventPayload {
  snapshot: TableSnapshot;
  updates?: TableSnapshot;

  update?: TableUpdate;
  error?: EventError;
  status?: string;
  scheduledUntil?: string;
}

export interface TableSnapshot {
  addChipsRequests?: AddChipRequests[];
  setting?: TableSetting;
  shareCode?: string;
  status?: TableStatus;
  hand?: Hand;
  users?: TableUser[];
  seats?: Seat[];
  information: TableInformation;
  tournamentInfo?: TournamentInfo;
  cashgameInfo?: CashGameInfo;
  knockedOutByInfo?: TournamentPlayer;
  knockingOutInfo?: KnockingOutInfo[];
  inTheMoneyInfo?: InTheMoneyInfo;
}

export interface Winner {
  seatId: number;
  winAmount: number;
}

export interface EventHeader {
  type: HeaderType;
  action: HeaderAction;
  tableId: number;
  userId?: number;
  messageId?: string;
  // ADDED ON TOURNAMENT
  tournamentId?: string;
  timestamp?: string;
  gameFormat?: 'CSG' | 'MTT' | 'SNG' | 'AOF' | 'FFP' | string;
  gameType?: GameType;
  bettingLimit?: 'NL' | 'FL' | 'PL' | 'ML' | string;
}

export interface EventError {
  error: ErrorResponse;
  code: string;
}

export interface ErrorResponse {
  code: string;
}

export interface ChatEventPayload {
  userId: number;
  message: string;
  timestamp: string;
}

export interface TableUpdate {
  isLastHandEvent?: boolean;
  timerPaused?: number;
  timerResumeAt?: string;
  requestUserId?: number;
  requestShowCards?: (0 | 1)[];
  contents?: string;
  noteUserId?: number;
  subject?: string;
  // ADD_CHIPS
  requesterUserId?: number;
  requesterSeatId?: number;
  amount?: number;
  isAllowed?: boolean;
}

interface KnockingOutInfo {
  // 참여자가 탈락 시킨 참여자의 정보
  id: number;
  userId: number;
  flag: string;
  nickName: string;
  profileImage: string;
}

interface InTheMoneyInfo {
  entrantId: number;
  rank: number;
  amount: string;
  assetInfo: {
    assetId: number;
    assetName: AssetType;
  };
}

export interface AddChipRequests {
  userId: number;
  amount: number;
  requestAt: string;
}

export interface TableSetting {
  actionTimeout: number;
  betUnit: number;
  ante: number;
  game: GameStyle;
  gameType: GameType;
  tableId: number;
  owner: number;
  name: string;
  limit: GameLimit;
  asset: TableAssetInfo;
  buyIn: BuyIn;
  bettingLimit: 'NL' | string;
  gameFormat: 'CSG' | string;
  shareCode: string;
  seatsCount: number;
  remainsSeatsCount: number;
  blindAmount: Blinds;
  chipValueMultiplier: number;
  tablePassword: string;
  tablePasswordEncrypt: string;
}

export interface TableAssetInfo {
  id: number;
  name: AssetType;
  type: MoneyType;
  displayName: string;
}

export interface Hand {
  ask: Ask;
  blinds: Blinds;
  communityCards: Card[];
  handId: number;
  lastRaiseAmount: number;
  tableCallAmount: number;
  pot: Pot;
  roles: Roles;
  round: GameRound;
  players: Player[];
  winners?: Winner[];
}

export interface Player {
  status?: PlayerStatus;
  lastAction?: PlayerAction;
  allowedActions: PlayerAction[];
  cards?: Card[];
  chips?: number;
  amountToCall: number;
  callAmount?: number;
  minRaiseAmount: number | null;
  isCardRevealed: boolean;
  maxAllInAmount: number;
  stack?: number;
  handStrength?: HandStrength;
  seatId?: number;
  userId: number;
  winningRate?: number;
  tieRate?: number;
  refund?: ChipRefund;

  // ADD ON TOURNAMENT
  remainedTimeBankSeconds?: number;
  startTime?: number;
  timeout?: number;
  extraTimeout?: number;
}

interface ChipRefund {
  amount: number;
  stack: PrevCurrentChip;
  callAmount: PrevCurrentChip;
  chips: PrevCurrentChip;
}

interface PrevCurrentChip {
  previous: number;
  current: number;
}

export interface TableUser {
  countryCode: string;
  isNotificationEnabled: boolean;
  isPasswordVerified: boolean;
  name: string;
  profileImage: string;
  stack: number;
  userId: number;
  note?: string;
  useCustomSeed: boolean;
  additionalStack?: number;
  passwordFailedCnt?: number;
  passwordReentryAt?: string;
}

export interface TableSelectedUser {
  userId: number | undefined;
  user: TableUser | undefined;
  timestamp: string;
}

export interface Seat {
  seatId: number;
  userId: number;
  stack: number;
  status: SeatStatus;
  reservedSeatStatus: SeatStatus | 'NONE';
  autoStandAt: string;
}

export interface Blinds {
  small: number;
  big: number;
}

export interface Roles {
  ante: number[];
  dealer: number;
  smallBlind: number;
  bigBlind: number;
}

export interface Ask {
  seatId: number;
  endExtraTimeout: string;
  endTimeout: string;
  playerId: number;
  startTimeout: string;
}

export interface Pot {
  total: number;
  sides: SidePot[];
}

export interface SidePot {
  minChips: number;
  pot: number;
}

interface BuyIn {
  min: number;
  max: number;
  default: number;
}
