import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import LoadingPage from '../components/common/loading/LoadingPage';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { selectRedirectUrl, setRedirectUrl } from '../store/slices/authSlice';
import useLangNavigate from '../hooks/useLangNavigate';

const AuthScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { result } = useParams<{ result: string }>();
  const navigate = useLangNavigate();
  const redirectUrl = useSelector(selectRedirectUrl);

  useEffect(() => {
    _.delay(() => {
      if (result === 'success') {
        if (redirectUrl?.includes('holdem')) {
          navigate(redirectUrl);
        } else {
          navigate('/cashgame');
        }
      } else if (result === 'refresh') {
        navigate(-1);
      } else {
        navigate('/error');
      }
      dispatch(setRedirectUrl(undefined));
    }, 500);
  }, [result, navigate]);

  return <LoadingPage />;
};

export default AuthScreen;
