import { Blinds, Player, Seat } from '../slices/streamingTypes';

export interface HealthResponse {
  status: string;
}

export interface Emoji {
  name: string;
  alias: string;
  emojiUrl: string;
}

export interface Country {
  name: string;
  code: string;
  flagUrl: string;
  callingCode: string;
}

export interface EmailSignInInRequestDto {
  email: string;
}

export interface EmailSignInVerifyCodeRequestDto {
  email: string;
  code: string;
}

export interface WalletConnectSignInRequestDto {
  chainNetwork: string;
  publicKey: string;
  signature: string;
  timestamp: string;
}

export interface RequestWithdrawRequestDto {
  chainNetwork: ChainNetworkType;
  asset: AssetType | string;
  amount: number;
  address: string;
}

export interface SendRequestDto {
  assetId: number;
  amount: number;
  toUserId: number;
}

export interface SendResponse {
  asset: string;
  amount: number;
  toUserName: string;
  toUserProfileImageUrl: string;
}

export enum MoneyType {
  PLAY_MONEY = 'PLAY_MONEY'
}

export enum TableStatus {
  WAITING = 'WAITING',
  PLAYING = 'PLAYING',
  PAUSED = 'PAUSED',
  CLOSED = 'CLOSED'
}

export enum MoneyType {
  CRYPTO = 'CRYPTO',
  BPP = 'BPP',
  POINT = 'POINT'
}

export enum AssetType {
  BP = 'BP',
  BPP = 'BPP',
  USDT = 'USDT',
  MNT = 'MNT',
  BRETT = 'BRETT'
}

export enum SeatStatus {
  SEAT_IN = 'SEAT_IN',
  SEAT_OUT = 'SEAT_OUT',
  STAND = 'STAND',
  RESERVED = 'RESERVED',
  EMPTY = 'EMPTY'
}

export interface FoundUser {
  userId: number;
  userName: string;
  profileImageUrl: string;
}

export interface UserNote {
  noteUserId: number;
  contents: string;
}

export enum ChainNetworkType {
  ETHEREUM = 'ETHEREUM',
  POLYGON = 'POLYGON',
  BITCOINCASH = 'BITCOINCASH'
}

export type AssetBalance = {
  assetName: string;
  assetDisplayName: string;
  amount: number;
  currencyAmount: number;
  symbolImage: string;
  symbolUnicode: string;
};

export interface BalanceResponse {
  balances: AssetBalance[];
  estimateBalance: number;
}

export interface Asset {
  id: number;
  name: AssetType | string;
  displayName: string;
  type: 'CRYPTO' | 'POINT' | 'BPP';
  symbolImage: string;
  symbolUnicode: string;
  decimals: number;
  displayDecimals: number;
  minSendAmount: number;
  sortWeight?: number;
  cryptoInfos: {
    chainNetworkName: ChainNetworkType;
    chainNetworkDisplayName: string;
    chainId: number;
    contractAddress: string;
    depositConfirmCount: number;
    withdrawFeeAmount: number;
    withdrawConfirmCount: number;
    minWithdrawAmount: number;
    minDepositAmount: number;
    symbolImage: string;
    decimals: number;
    displayDecimals: number;
  }[];
}

export interface AssetConfig {
  assets: Asset[];
}

export interface CheckAddressRequestDto {
  chainNetwork: string;
  address: string;
}

export interface CheckUsernameRequestDto {
  name: string;
}

export interface TableCreateRequestDto {
  assetId: number; // 에셋 ID
  tableTitle: string; // 테이블 이름
  tablePassword: string; // 테이블 패스워드 (숫자 4자리)
  minPlayersCnt: number; // 게임 시작 최소 플레이어 수
  stakesSeq: number; // 스태이크 셋
  rakeRate?: number;
}

export interface TableCloseRequestDto {
  gameType: MoneyType; // 게임 타입
  tableId: number; // 테이블 아이디
}

// 테이블 리스트 응답 DTO : TableListResponse
export interface TableInfo {
  tableId: number; // 테이블 아이디
  gameStatus: TableStatus; // 테이블 상태
  ownerUserId: number; // 테이블 소유자 유저 아이디
  asset: AssetType; // 테이블 화폐 단위
  buyInMinAmount: number; // 최소 바이인 금액
  buyInMaxAmount: number; // 최대 바이인 금액
  buyInAmount: number; // 고정 바이인 금액
  shareCode: string; // 공유 URL
  tableTitle: string; // 방 이름
  activeSeatCnt: number; // 현재 점유 중 좌석 수
  maxSeatCnt: number; // 최대 좌석 수
  blindAmount: Blinds;
}

export interface LobbyTableInfo {
  activeSeatCnt: number;
  asset: AssetType;
  bigBlindAmount: number;
  gameStatus: TableStatus;
  isSeated: boolean;
  maxBuyInAmount: number;
  maxSeatCnt: number;
  minBuyInAmount: number;
  shareCode: string;
  smallBlindAmount: number;
  tableId: number;
  tableTitle: string;
  hasPassword: boolean;
}

// 로비 테이블 리스트 DTO : LobbyTableListResponse
export interface LobbyTableList {
  my: LobbyTableInfo[];
  joined: LobbyTableInfo[];
  general: LobbyTableInfo[];
}

// 테이블 참여 응답 DTO
export interface TableJoinResponse {
  description: string; // 테이블 정보에 대한 설명
  gameType: MoneyType; // 게임 타입
  tableId: number; // 테이블 아이디
  gameStatus: TableStatus; // 테이블 상태
  ownerUserId: number; // 테이블 소유자 유저 아이디
  asset: AssetType; // 테이블 화폐 단위
  buyInMinAmount: number; // 최소 바이인 금액
  buyInMaxAmount: number; // 최대 바이인 금액
  buyInAmount: number; // 고정 바이인 금액
  players: Player[]; // 테이블에 참여한 플레이어들의 정보 배열
  seats: Seat[]; // 테이블의 좌석 정보 배열
  rakeRate?: number;
}

// 테이블 생성 응답 DTO
export interface TableCreateResponse {
  gameType: MoneyType.PLAY_MONEY; // 게임 타입
  tableId: number; // 테이블 아이디
  gameStatus: TableStatus; // 테이블 상태
  ownerUserId: number; // 테이블 소유자 유저 아이디
  asset: AssetType; // 테이블 화폐 단위
  buyInMinAmount: number; // 최소 바이인 금액
  buyInMaxAmount: number; // 최대 바이인 금액
  buyInAmount: number; // 고정 바이인 금액
  shareCode: string; // 공유 URL
  tableTitle: string; // 방 이름
}

export interface User {
  gameSeed: string;
  isGameSeedAutoGenerated: boolean;
  profile: Profile;
  stats: Stats;
  setting: Setting;
  kycInfo: KycInfo;
}

export interface KycBios {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  countryCode: string;
  phoneNumber: string;
}

export interface KycAddress {
  country: string;
  address: string;
  city: string;
  postalCode: string;
}

export interface KycInfo {
  bios: KycBios;
  address: KycAddress;
}

export interface Profile {
  name: string;
  country: string;
  email: string;
  isNotificationEnabled: boolean;
  id: number;
  profile: string;
  createdAt: string;
  accountType: 'EMAIL' | 'OAUTH_GOOGLE' | 'OAUTH_TWITTER' | 'WALLET_CONNECT';
  walletAddress: string;
  isNameEditable: boolean;
}

interface Stats {
  handsPlayed: number;
  gamesPlayed: number;
  handsWon: number;
}

export interface Setting {
  deckStyle: DeckStyleOptions;
  tableStyle: TableStyleOptions;
  isSoundEnabled: boolean;
  isChatEnabled: boolean;
  isEmojiEnabled: boolean;
  isBlindView: boolean;
}

export type TableStyleOptions = 'GREEN' | 'BLUE' | 'GRAY';
export type DeckStyleOptions = 'CLASSIC' | 'FOUR_COLOR_WHITE' | 'FOUR_COLOR_COLORED';

export interface SearchUserRequestDto {
  userName: string;
}

export interface UserNoteRequestDto {
  noteUserId: number;
}

export interface ProfilePresetSelectRequestDto {
  index: number;
}

export interface UpdateUserProfileDto {
  name?: string;
  country?: string;
  email?: string;
  isNotificationEnabled?: boolean;
  deckStyle?: DeckStyleOptions;
  tableStyle?: TableStyleOptions;
  isSoundEnabled?: boolean;
  isChatEnabled?: boolean;
  isEmojiEnabled?: boolean;
  gameSeed?: string;
}

export interface TableCreateConfig {
  stakes: TableCreateAssetConfig[];
  minPlayers: number[];
  rakeRates: number[];
}

export interface TableCreateAssetConfig {
  asset: AssetType;
  assetId: number;
  assetDisplayName: string;
  assetType: string;
  symbolImage: string;
  symbolUnicode: string;
  sortWeight?: number;
  values: TableCreateAssetValueConfig[];
}

export interface TableCreateAssetValueConfig {
  seq: number;
  smallBlindAmount: number;
  bigBlindAmount: number;
  minBuyInAmount: number;
  maxBuyInAmount: number;
}
