import React from 'react';

import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import useDevice from '../../../hooks/useDevice';
import { ModalType, showModal } from '../../../store/slices/modalSlice';
import Text from '../../common/text/Text';
import { checkErrorType, ErrorTypes } from '../../../store/api/api';
import MainHeader from '../header/MainHeader';
import HorizontalLayout from '../../layout/HorizontalLayout';
import useLangNavigate from '../../../hooks/useLangNavigate';

type ErrorProps = {
  errorType: string;
};

const ErrorTemplate: React.FC<ErrorProps> = ({ errorType }) => {
  const { isMobile } = useDevice();
  const navigate = useLangNavigate();
  const dispatch = useDispatch();

  const getTitle = () => {
    const type = checkErrorType(errorType);
    switch (type) {
      case ErrorTypes.UNKNOWN:
        return 'This access is invalid';
      case ErrorTypes.MAINTENANCE:
        return 'Maintenance';
      case ErrorTypes.RESTRICTED_IP:
        return 'Oops! Access denied';
      case ErrorTypes.CLOSED_TABLE:
        return "Sorry, You're too late!";
      case ErrorTypes.NOT_FOUND_TABLE:
        return 'Table not found';
      case ErrorTypes.UNAUTHORIZED:
        return 'Join the table';
      case undefined:
      default:
        return '';
    }
  };

  const getImage = () => {
    const type = checkErrorType(errorType);
    switch (type) {
      case ErrorTypes.UNKNOWN:
        return require('src/assets/img/img_ghost.png');
      case ErrorTypes.MAINTENANCE:
        return require('src/assets/img/img_maintenance.png');
      case ErrorTypes.RESTRICTED_IP:
        return require('src/assets/img/img_ghost.png');
      case ErrorTypes.CLOSED_TABLE:
        return require('src/assets/img/img_cards.png');
      case ErrorTypes.NOT_FOUND_TABLE:
        return require('src/assets/img/img_cards.png');
      case ErrorTypes.UNAUTHORIZED:
        return require('src/assets/img/login.png');
      case undefined:
      default:
        return require('src/assets/img/img_cards.png');
    }
  };

  const getDescription = () => {
    const type = checkErrorType(errorType);
    switch (type) {
      case ErrorTypes.UNKNOWN:
        return 'Unknown description';
      case ErrorTypes.MAINTENANCE:
        return 'The site is currently down for maintenance.\nWe apologize for any inconveniences caused.';
      case ErrorTypes.RESTRICTED_IP:
        return "We're sorry. Basepoker is not available in your current location.";
      case ErrorTypes.CLOSED_TABLE:
        return 'This table has closed.';
      case ErrorTypes.NOT_FOUND_TABLE:
        return "We're sorry. There is no active table found with this URL";
      case ErrorTypes.UNAUTHORIZED:
        return 'Please login or sign up to join this table!';
      case undefined:
      default:
        return '';
    }
  };

  const getButtonText = () => {
    const type = checkErrorType(errorType);
    switch (type) {
      case ErrorTypes.UNKNOWN:
        return 'Refresh';
      case ErrorTypes.MAINTENANCE:
        return '';
      case ErrorTypes.RESTRICTED_IP:
        return '';
      case ErrorTypes.CLOSED_TABLE:
        return 'Go to Lobby';
      case ErrorTypes.NOT_FOUND_TABLE:
        return 'Go to Lobby';
      case ErrorTypes.UNAUTHORIZED:
        return 'Login or Sign up';
      case undefined:
      default:
        return '';
    }
  };

  const onButtonAction = () => {
    const type = checkErrorType(errorType);
    switch (type) {
      case ErrorTypes.UNKNOWN:
        window.location.reload();
        break;
      case ErrorTypes.MAINTENANCE:
        break;
      case ErrorTypes.RESTRICTED_IP:
        break;
      case ErrorTypes.CLOSED_TABLE:
        navigate('/cashgame');
        break;
      case ErrorTypes.NOT_FOUND_TABLE:
        navigate('/cashgame');
        break;
      case ErrorTypes.UNAUTHORIZED:
        dispatch(showModal(ModalType.AuthModal));
        break;
      case undefined:
      default:
        // window.location.reload();
        navigate(window.location.origin);
        break;
    }
  };

  if (errorType === ErrorTypes.UNAUTHORIZED) {
    return (
      <Background>
        <MainHeader errorType={errorType} />
        <Wrap>
          <HorizontalLayout margin={'0'}>
            <StyledTitle fontStyle={isMobile ? 'TextLg_Bold' : 'TextXl_Bold'} text={getTitle()} />
          </HorizontalLayout>
          <ErrorImage src={getImage()} alt="error_img" />
          <StyledDescription fontStyle={isMobile ? 'TextMd_Medium' : 'TextLg_Medium'} text={getDescription()} marginTop={24} marginBottom={24} />
          {getButtonText().length > 0 && <StyledLoginButton onClick={onButtonAction}>{getButtonText()}</StyledLoginButton>}
        </Wrap>
      </Background>
    );
  } else {
    return (
      <Background>
        <MainHeader errorType={errorType} />
        <ErrorImage src={getImage()} alt="error_img" />
        <StyledTitle fontStyle={isMobile ? 'DisplayXs_Bold' : 'DisplayMd_Bold'} text={getTitle()} />
        <StyledDescription fontStyle={isMobile ? 'TextMd_Medium' : 'TextXl_Medium'} text={getDescription()} />
        {getButtonText().length > 0 && <StyledButton onClick={onButtonAction}>{getButtonText()}</StyledButton>}
      </Background>
    );
  }
};

const Background = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  z-index: 200;
  background-color: ${({ theme }) => theme.colors.gray900};
  gap: 40px;
  @media (max-width: 768px) {
    background-color: ${({ theme }) => theme.colors.gray900};
  }
`;

const Wrap = styled.div`
  width: 500px;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray800};
  padding: 32px;
  border-radius: 12px;
  gap: 24px;
  @media (max-width: 768px) {
    padding: 20px;
    width: 80vw;
  }
`;

const ErrorImage = styled.img`
  width: 240px;
  height: 240px;
  align-self: center;
  @media (max-width: 768px) {
    width: 180px;
    height: 180px;
  }
`;

const StyledTitle = styled(Text)``;

const StyledDescription = styled(Text)`
  color: ${({ theme }) => theme.colors.gray300};
`;

const StyledLoginButton = styled.button`
  ${({ theme }) => theme.textStyles.TextMd_Semibold};
  background-color: ${({ theme }) => theme.colors.primary600};
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.white};
  border: none;
  cursor: pointer;
  padding: 12px 20px;
  user-select: none;
  align-self: center;
  flex: 1;
  width: 100%;
`;

const StyledButton = styled.button`
  ${({ theme }) => theme.textStyles.TextMd_Semibold};
  min-width: 124px;
  background-color: ${({ theme }) => theme.colors.primary50};
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.primary700};
  border: none;
  cursor: pointer;
  padding: 12px 20px;
  user-select: none;
`;

export default ErrorTemplate;
