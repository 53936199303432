import React from 'react';
import { IconProps } from '../types';
import { Colors } from 'src/styles/Colors';

export const Tournament = React.memo<IconProps>(({ w = 20, h = 20, color = 'gray400' }) => (
  <svg width={w} height={h} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_22810_120542)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3964 4.33873C15.4173 4.20533 15.3405 4.07564 15.2127 4.03206C11.952 2.92018 8.39145 2.91194 5.12181 4.01285L5.06261 4.03278C4.93229 4.07666 4.85281 4.20833 4.87471 4.34408L6.10323 11.9621C6.11469 12.0332 6.153 12.0972 6.21025 12.1409L7.56433 13.1743C7.66105 13.2482 7.69933 13.3758 7.6592 13.4907L6.89392 15.6809C6.83004 15.8637 6.96575 16.0549 7.15942 16.0549L13.1567 16.0549C13.3531 16.0549 13.489 15.8587 13.4201 15.6749L12.6031 13.4968C12.559 13.3792 12.5982 13.2466 12.6992 13.1719L14.093 12.1415C14.1525 12.0975 14.1923 12.0319 14.2037 11.9588L15.3964 4.33873ZM12.9351 11.4856C12.9946 11.4416 13.0343 11.376 13.0458 11.3029L14.005 5.17479C14.027 5.03403 13.9404 4.89874 13.8025 4.86262C11.4048 4.23427 8.87335 4.23369 6.47257 4.86332C6.33431 4.89958 6.24767 5.03562 6.27042 5.17673L7.2603 11.3149C7.27177 11.386 7.31007 11.45 7.36732 11.4937L9.02462 12.7586C9.12134 12.8324 9.15962 12.96 9.11949 13.0749L8.62774 14.4823C8.56386 14.6651 8.69957 14.8563 8.89323 14.8563L11.382 14.8563C11.5784 14.8563 11.7143 14.6601 11.6453 14.4763L11.1217 13.0801C11.0776 12.9625 11.1168 12.8299 11.2178 12.7552L12.9351 11.4856Z"
        fill={Colors[color]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6167 2.87382C15.5596 3.01512 15.4003 3.0849 15.2566 3.03409C11.9477 1.86411 8.31328 1.86411 5.00436 3.03409C4.86066 3.0849 4.70139 3.01512 4.64424 2.87382L4.4034 2.27834C4.34407 2.13165 4.41738 1.96465 4.56654 1.91183C8.15774 0.640069 12.1032 0.640069 15.6944 1.91183C15.8436 1.96465 15.9169 2.13165 15.8576 2.27834L15.6167 2.87382Z"
        fill={Colors[color]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0076 11.3767C12.9779 11.5909 13.1904 11.7556 13.3859 11.6631C13.7022 11.5132 14.0057 11.3449 14.2952 11.1598C16.1852 9.95122 17.4773 8.0269 17.8299 5.8651C17.8791 5.56348 17.91 5.25724 17.9217 4.94767C17.9275 4.79246 17.8011 4.6665 17.6457 4.6665H14.183C14.0426 4.6665 13.9237 4.77005 13.9044 4.90911L13.0076 11.3767ZM15.0758 8.89467C14.903 9.0932 14.6029 8.94082 14.6391 8.6801L14.9958 6.10771C15.0151 5.96865 15.1339 5.8651 15.2743 5.8651H16.2241C16.4009 5.8651 16.5342 6.02651 16.4912 6.19802C16.2415 7.19414 15.7531 8.11663 15.0758 8.89467Z"
        fill={Colors[color]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.26588 11.3767C7.29558 11.5909 7.083 11.7556 6.88757 11.6631C6.57125 11.5132 6.26772 11.3449 5.97823 11.1598C4.0882 9.95122 2.79611 8.0269 2.44354 5.8651C2.39434 5.56348 2.36344 5.25724 2.35176 4.94767C2.3459 4.79246 2.47237 4.6665 2.62769 4.6665H6.09047C6.23087 4.6665 6.34976 4.77005 6.36904 4.90911L7.26588 11.3767ZM5.1976 8.89467C5.37042 9.0932 5.67053 8.94082 5.63437 8.6801L5.27767 6.10771C5.25839 5.96865 5.1395 5.8651 4.9991 5.8651H4.04932C3.87251 5.8651 3.73926 6.02651 3.78224 6.19802C4.03192 7.19414 4.52032 8.11663 5.1976 8.89467Z"
        fill={Colors[color]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9326 18.7694C14.9326 18.9247 14.8066 19.0506 14.6513 19.0506H5.62498C5.46966 19.0506 5.34375 18.9247 5.34375 18.7694V17.5339C5.34375 17.3786 5.46966 17.2527 5.62498 17.2527H14.6513C14.8066 17.2527 14.9326 17.3786 14.9326 17.5339V18.7694Z"
        fill={Colors[color]}
      />
      <g clipPath="url(#clip1_22810_120542)">
        <path
          d="M18.2953 11.6561C18.3799 11.5752 18.4222 11.5347 18.4609 11.531C18.4947 11.5278 18.5281 11.5399 18.5518 11.5641C18.5791 11.5919 18.5855 11.65 18.5983 11.7663L18.7196 12.87C18.7234 12.9043 18.7252 12.9215 18.7316 12.9366C18.7373 12.95 18.7455 12.9622 18.7558 12.9724C18.7674 12.984 18.7826 12.9922 18.813 13.0086L19.791 13.5355C19.894 13.591 19.9455 13.6188 19.961 13.6545C19.9745 13.6856 19.9732 13.7211 19.9576 13.7512C19.9396 13.7857 19.8862 13.8097 19.7796 13.8578L18.7675 14.3136C18.736 14.3278 18.7202 14.3349 18.7078 14.3456C18.6968 14.3552 18.6877 14.3667 18.6811 14.3797C18.6737 14.3944 18.6706 14.4114 18.6644 14.4454L18.4666 15.5375C18.4457 15.6527 18.4353 15.7103 18.4061 15.7361C18.3807 15.7586 18.3466 15.7684 18.3131 15.7628C18.2747 15.7563 18.2354 15.713 18.1566 15.6264L17.4099 14.8046C17.3867 14.779 17.375 14.7662 17.361 14.7577C17.3485 14.7502 17.3347 14.7452 17.3203 14.743C17.3041 14.7404 17.2869 14.7427 17.2527 14.7474L16.1525 14.8969C16.0365 14.9127 15.9785 14.9206 15.9449 14.9008C15.9157 14.8836 15.8958 14.8541 15.8908 14.8206C15.885 14.7821 15.9141 14.7313 15.9721 14.6296L16.5225 13.6659C16.5397 13.6358 16.5483 13.6208 16.552 13.6048C16.5553 13.5906 16.5558 13.5759 16.5535 13.5615C16.5509 13.5453 16.5434 13.5297 16.5284 13.4986L16.0461 12.4989C15.9952 12.3935 15.9698 12.3408 15.9782 12.3028C15.9855 12.2697 16.0074 12.2417 16.0377 12.2266C16.0726 12.2091 16.1298 12.221 16.2444 12.2447L17.3323 12.4697C17.3661 12.4767 17.3831 12.4802 17.3994 12.4788C17.4139 12.4775 17.428 12.4734 17.4409 12.4668C17.4556 12.4593 17.468 12.4474 17.493 12.4235L18.2953 11.6561Z"
          fill={Colors[color]}
        />
      </g>
      <g clipPath="url(#clip2_22810_120542)">
        <path
          d="M1.39849 11.7648C1.41127 11.6485 1.41767 11.5903 1.44497 11.5626C1.46873 11.5384 1.50213 11.5262 1.53586 11.5295C1.57463 11.5332 1.61692 11.5737 1.70148 11.6545L2.50378 12.422C2.52875 12.4459 2.54123 12.4578 2.55585 12.4653C2.56878 12.4719 2.58289 12.476 2.59737 12.4772C2.61372 12.4786 2.63064 12.4752 2.66447 12.4682L3.75238 12.2432C3.86695 12.2195 3.92423 12.2076 3.95907 12.225C3.98938 12.2402 4.01124 12.2682 4.01856 12.3013C4.02697 12.3393 4.00156 12.392 3.95073 12.4974L3.46843 13.4971C3.45339 13.5282 3.44588 13.5438 3.44327 13.56C3.44097 13.5744 3.44148 13.5891 3.44478 13.6033C3.4485 13.6193 3.45708 13.6343 3.47424 13.6643L4.02467 14.6281C4.08272 14.7297 4.11175 14.7806 4.10599 14.8191C4.10097 14.8526 4.08109 14.8821 4.05189 14.8993C4.01832 14.9191 3.96033 14.9112 3.84433 14.8954L2.74412 14.7459C2.70984 14.7412 2.6927 14.7389 2.67647 14.7414C2.66209 14.7437 2.64829 14.7487 2.63582 14.7562C2.62175 14.7647 2.61012 14.7775 2.58685 14.8031L1.84016 15.6249C1.76143 15.7115 1.72207 15.7548 1.68365 15.7613C1.65022 15.7668 1.61605 15.757 1.59066 15.7346C1.56148 15.7088 1.55105 15.6512 1.53018 15.536L1.33235 14.4439C1.32618 14.4099 1.3231 14.3929 1.31566 14.3782C1.30907 14.3652 1.30002 14.3536 1.28902 14.3441C1.27659 14.3334 1.26082 14.3263 1.22927 14.312L0.217216 13.8562C0.110549 13.8082 0.0572153 13.7842 0.0392183 13.7496C0.0235598 13.7196 0.0222979 13.6841 0.0357838 13.653C0.0512839 13.6173 0.102778 13.5895 0.205766 13.534L1.18377 13.0071C1.21419 12.9907 1.22939 12.9825 1.24102 12.9709C1.2513 12.9606 1.2595 12.9485 1.26516 12.9351C1.27155 12.92 1.27343 12.9028 1.27721 12.8684L1.39849 11.7648Z"
          fill={Colors[color]}
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_22810_120542">
        <rect width={w} height={h} fill="white" />
      </clipPath>
      <clipPath id="clip1_22810_120542">
        <rect width="5.3937" height="5.3937" fill="white" transform="translate(16.1797 10.0596) rotate(20)" />
      </clipPath>
      <clipPath id="clip2_22810_120542">
        <rect width="5.3937" height="5.3937" fill="white" transform="translate(-1.25 11.9028) rotate(-20)" />
      </clipPath>
    </defs>
  </svg>
));

Tournament.displayName = 'Tournament';
