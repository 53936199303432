import React from 'react';
import { PasswordIcon } from '../../common/icon';
import styled from '@emotion/styled';
import useDevice from '../../../hooks/useDevice';
import { LobbyTableInfo } from '../../../store/api/responseTypes';
import { ModalType, showModalWithRequest } from '../../../store/slices/modalSlice';
import { useDispatch } from 'react-redux';
import useAsset from '../../../hooks/useAsset';
import { numberToDisplayString } from '../../../utils/StringUtil';
import useOpenTable from '../../../hooks/useOpenTable';

interface TableItemProps {
  tableInfo: LobbyTableInfo;
  selectedTable?: number;
  isMyTable?: boolean;
  setSelectedTable?: (tableId: number) => void;
}

const TableItem: React.FC<TableItemProps> = ({ tableInfo, setSelectedTable, selectedTable, isMyTable }) => {
  const { isMobile, isTablet } = useDevice();
  const dispatch = useDispatch();
  const { openTable } = useOpenTable();
  const { network, displayDecimal } = useAsset(tableInfo.asset);

  const handleItemClick = (tableId: number) => {
    if (isMobile || isTablet) {
      dispatch(showModalWithRequest({ type: ModalType.TablePreviewModal, data: { tableId } }));
      return;
    }
    setSelectedTable?.(tableId);
  };

  return (
    <StyledRow onDoubleClick={() => openTable(tableInfo.shareCode)} onClick={() => handleItemClick(tableInfo.tableId)} isSelected={selectedTable === tableInfo.tableId} isMyTable={isMyTable} key={tableInfo.tableId}>
      <StyledCell>{tableInfo.hasPassword && <PasswordIcon w={isMobile ? 16 : 20} h={isMobile ? 16 : 20} color={selectedTable === tableInfo.tableId ? 'white' : 'gray300'} tooltip="Password required" />}</StyledCell>
      <StyledCell isTitle>
        <TextContainer>{tableInfo.tableTitle}</TextContainer>
      </StyledCell>
      <StyledCell>
        {network?.type === 'CRYPTO' && <StyledSymbol src={network.symbolImage} alt={network.displayName} />}
        <TextContainer>{`${network?.type === 'CRYPTO' ? '' : 'P'}${numberToDisplayString(tableInfo.smallBlindAmount, displayDecimal)} / ${network?.type === 'CRYPTO' ? '' : 'P'}${numberToDisplayString(tableInfo.bigBlindAmount, displayDecimal)}`}</TextContainer>
      </StyledCell>
      <StyledCell>
        {network?.type === 'CRYPTO' && <StyledSymbol src={network.symbolImage} alt={network.displayName} />}
        <TextContainer>{`${network?.type === 'CRYPTO' ? '' : 'P'}${numberToDisplayString(tableInfo.minBuyInAmount, displayDecimal)}`}</TextContainer>
      </StyledCell>
      <StyledCell>
        {!isMobile ? (
          [...Array(tableInfo.maxSeatCnt)].map((_, i) => <StyledSeatIndicator key={i} isActive={i < tableInfo.activeSeatCnt} isJoined={tableInfo.isSeated && i === 0} />)
        ) : (
          <StyledCountText isSeated={tableInfo.isSeated} isFull={tableInfo.maxSeatCnt === tableInfo.activeSeatCnt} isEmpty={tableInfo.activeSeatCnt === 0}>
            {`${tableInfo.activeSeatCnt} / ${tableInfo.maxSeatCnt}`}
          </StyledCountText>
        )}
      </StyledCell>
    </StyledRow>
  );
};

const StyledRow = styled.div<{ isSelected?: boolean; isMyTable?: boolean }>`
  display: grid;
  grid-template-columns: 20fr 206fr 154fr 74fr 134fr;
  padding: 12px 24px;
  gap: 16px;
  cursor: pointer;
  background-color: ${({ theme, isSelected, isMyTable }) => (isSelected ? theme.colors.table_selected : isMyTable ? theme.colors.gray900 : 'transparent')};
  color: ${({ theme, isSelected }) => (isSelected ? theme.colors.white : theme.colors.gray300)};

  ${({ theme }) => theme.textStyles.TextMd_Medium}
  &:hover {
    background-color: ${({ theme, isSelected }) => (isSelected ? theme.colors.table_selected : theme.colors.gray750)};
  }

  &:first-of-type {
    margin-top: 8px;
  }

  @media (max-width: 768px) {
    grid-template-columns: 16fr 100fr 80fr 47fr 55fr;
    ${({ theme }) => theme.textStyles.TextXm_Medium};
    padding: 8px 16px;
    gap: 12px;
    // background-color: ${({ theme, isSelected, isMyTable }) => (isSelected ? theme.colors.table_selected : isMyTable ? theme.colors.gray750 : 'transparent')};
  }
`;

const StyledCell = styled.div<{ isTitle?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  color: ${({ theme, isTitle }) => (isTitle ? theme.colors.warning200 : theme.colors.gray300)};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &:first-of-type {
    justify-content: center;
  }

  &:nth-of-type(4) {
    color: ${({ theme }) => theme.colors.white};
  }

  &:last-of-type {
    justify-content: flex-end;
  }
`;

const StyledSymbol = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 4px;
`;

const StyledSeatIndicator = styled.div<{ isActive?: boolean; isJoined?: boolean }>`
  width: 14px;
  height: 14px;
  border-radius: 2px;
  background-color: ${({ theme, isActive, isJoined }) => (isJoined ? theme.colors.warning400 : isActive ? theme.colors.primary500 : theme.colors.gray500)};
  margin-right: 2px;
`;

const StyledCountText = styled.div<{ isEmpty?: boolean; isFull?: boolean; isSeated?: boolean }>`
  ${({ theme }) => theme.textStyles.TextXs_Medium}
  color: ${({ theme, isEmpty, isFull, isSeated }) => (isEmpty ? theme.colors.gray400 : isFull ? theme.colors.error500 : isSeated ? theme.colors.warning500 : theme.colors.success500)};
  flex: 1;
  @media (max-width: 768px) {
    text-align: right; // 모바일 환경에서 오른쪽 정렬
    ${({ theme }) => theme.textStyles.TextXm_Medium};
  }
`;

const TextContainer = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
`;

export default TableItem;
