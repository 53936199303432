import { useGetAssetConfigQuery } from '../store/api/assets';
import { Asset, AssetType } from '../store/api/responseTypes';
import { useMemo } from 'react';

const useAssets = () => {
  const { data: assetConfig, isLoading } = useGetAssetConfigQuery();

  const getNetwork = (assetType: AssetType | string): Asset | undefined => {
    return assetConfig?.data.assets.find(asset => asset.name === assetType) ?? undefined;
  };

  const getNetworks = useMemo(() => {
    const assets = assetConfig?.data.assets;
    if (assets && assets.every(asset => asset.sortWeight !== undefined)) {
      // Create a shallow copy before sorting
      return [...assets].sort((a, b) => (a.sortWeight ?? 0) - (b.sortWeight ?? 0));
    }
    return assets || [];
  }, [assetConfig]);

  const getNetworkDecimal = (assetType: AssetType | string): number => {
    const asset = assetConfig?.data.assets.find((a: Asset) => a.name === assetType);
    return asset?.displayDecimals ?? 0;
  };

  const getNetworkDetails = (assetType: AssetType | string) => {
    const asset = assetConfig?.data.assets.find((a: Asset) => a.name === assetType);
    if (!asset || asset.cryptoInfos.length === 0) return undefined;
    return asset.cryptoInfos.map(info => ({
      chainNetworkName: info.chainNetworkName,
      chainNetworkDisplayName: info.chainNetworkDisplayName,
      chainId: info.chainId,
      contractAddress: info.contractAddress,
      depositConfirmCount: info.depositConfirmCount,
      withdrawFeeAmount: info.withdrawFeeAmount,
      withdrawConfirmCount: info.withdrawConfirmCount,
      minWithdrawAmount: info.minWithdrawAmount,
      minDepositAmount: info.minDepositAmount,
      symbolImage: info.symbolImage,
      decimals: info.decimals,
      displayDecimals: info.displayDecimals
    }));
  };

  return {
    isLoading,
    getNetwork,
    getNetworkDecimal,
    getNetworkDetails,
    getNetworks
  };
};

export default useAssets;
