import React, { useEffect, useState } from 'react';
import HorizontalLayout from '../../layout/HorizontalLayout';
import LinearLayout from '../../layout/LinearLayout';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { incrementRequestCodeCount, incrementVerifyCodeErrorCount, resetRequestCodeCount, resetVerifyCodeErrorCount, selectCurrentEmail, selectIsEmailValid, selectLastRequestedTime, selectLastVerifyCodeTime, selectRequestCodeCount, selectVerifyCodeErrorCount } from '../../../store/slices/authSlice';
import { dismissModal, ModalType, showModal } from '../../../store/slices/modalSlice';
import { XCloseIcon } from '../../common/icon/icons/XCloseIcon';
import { ArrowLeft } from '../../common/icon';
import VerificationInput from 'react-verification-input';
import { useEmailAuthMutation, useEmailAuthVerifyMutation } from '../../../store/api/auth';
import { getErrorMessage } from '../../../store/api/api';
import LottieAnimation from '../../common/loading/LottieAnimation';
import { logWarn } from '../../../utils/ConsoleUtil';
import Text from '../../common/text/Text';
import useDevice from '../../../hooks/useDevice';
import { useTranslation } from 'react-i18next';

const CodeVerificationTemplate = () => {
  const { isMobile } = useDevice();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const email: string | null = useSelector(selectCurrentEmail);
  const isEmailValid = useSelector(selectIsEmailValid);

  const reqCodeCount = useSelector(selectRequestCodeCount);
  const lastReqCodeTime = useSelector(selectLastRequestedTime);
  const verifyCodeCount = useSelector(selectVerifyCodeErrorCount);
  const lastVerifyCodeTime = useSelector(selectLastVerifyCodeTime);
  const oneHourAgo = new Date().getTime() - 3600000;
  const isRequestEnable = verifyCodeCount < 5 && reqCodeCount < 10;

  const [snackbarMessage, setSnackbarMessage] = useState<undefined | string>(undefined);
  const [code, setCode] = useState<string>('');
  const [isCooldownActive, setIsCooldownActive] = useState(true);

  const [requestCode] = useEmailAuthMutation();
  const [requestCodeVerify, { isLoading: isLoadingVerifyCode }] = useEmailAuthVerifyMutation();

  const reqCode = async (e?: React.FormEvent<HTMLFormElement>) => {
    if (lastReqCodeTime > oneHourAgo && reqCodeCount >= 10) {
      return;
    } else {
      if (isEmailValid && email !== null) {
        e?.preventDefault();
        await requestCode({ email: email });
        dispatch(incrementRequestCodeCount());
        setSnackbarMessage(undefined);
        setCode('');
      }
    }
  };

  const reqCodeVerify = async (code: string) => {
    if (email !== null && lastVerifyCodeTime > oneHourAgo && verifyCodeCount >= 5) {
      return;
    } else {
      requestCodeVerify({
        email: email!,
        code: code!
      })
        .unwrap()
        .then(() => {
          dispatch(dismissModal());
          dispatch(resetVerifyCodeErrorCount());
          dispatch(resetRequestCodeCount());
        })
        .catch(error => {
          dispatch(incrementVerifyCodeErrorCount());
          if (error && 'code' in error) {
            showError(getErrorMessage(error.code));
          }
        });
    }
  };

  const showError = (msg: string) => setSnackbarMessage(msg);

  const backToAuthModal = () => dispatch(showModal(ModalType.AuthModal));

  const closeModal = () => dispatch(dismissModal());

  const check = () => {
    // 로깅 정보 출력
    logWarn(`\nVerify : ${verifyCodeCount}\nReq : ${reqCodeCount}\nVerify Remains :${(lastVerifyCodeTime - oneHourAgo) / 60000} mins\nReq Remains :${(lastReqCodeTime - oneHourAgo) / 60000} mins`);

    // 인증 코드 에러 및 요청 횟수 확인
    if (lastVerifyCodeTime > oneHourAgo && verifyCodeCount >= 5) {
      setSnackbarMessage('The code you entered is incorrect. Please try again in 1 hour');
      return false;
    }
    if (lastReqCodeTime > oneHourAgo && reqCodeCount >= 10) {
      setSnackbarMessage("You've requested codes too often. Please try again in 1 hour");
      return false;
    }
    if (!isRequestEnable) {
      dispatch(resetRequestCodeCount());
      dispatch(resetVerifyCodeErrorCount());
      setSnackbarMessage(undefined);
    }
    return true;
  };
  useEffect(() => {
    if (check()) {
      reqCode().then();
    }
  }, [email]);

  useEffect(() => {
    check();
  }, [reqCodeCount, verifyCodeCount, lastReqCodeTime, lastVerifyCodeTime]);

  useEffect(() => {
    if (reqCodeCount < 10) {
      const thirtySecondsAgo = new Date().getTime() - 30000;
      if (lastReqCodeTime > thirtySecondsAgo) {
        setIsCooldownActive(true);
        const timer = setTimeout(() => {
          setIsCooldownActive(false);
        }, lastReqCodeTime - thirtySecondsAgo);
        return () => clearTimeout(timer);
      }
    }
    setIsCooldownActive(false);
  }, [reqCodeCount, lastReqCodeTime]);

  return (
    <Background>
      <HorizontalLayout>
        <LeftIconDiv>
          <ArrowLeft onClick={backToAuthModal} />
        </LeftIconDiv>
        <Title>{t('EMAIL.VerifyCode')}</Title>
        <RightIconDiv>
          <XCloseIcon onClick={closeModal} />
        </RightIconDiv>
      </HorizontalLayout>
      {snackbarMessage !== undefined && (
        <Snackbar>
          <ErrorText>{snackbarMessage}</ErrorText>
        </Snackbar>
      )}
      <LinearLayout>
        <Image src={require('src/assets/img/email.png')} alt="Email" />
        {isLoadingVerifyCode && <LottieAnimation />}
        <Text textColor={'gray300'} fontStyle={isMobile ? 'TextMd_Medium' : 'TextLg_Semibold'} text={t('EMAIL.SentEmail', { email: email ?? '' })} />
        <StyledWrap>
          <VerificationInput
            value={code}
            onChange={code => setCode(code)}
            length={6}
            autoFocus={true}
            passwordMode={false}
            validChars={'0-9'}
            inputProps={{ inputMode: 'numeric' }}
            placeholder={''}
            onComplete={reqCodeVerify}
            classNames={{
              container: 'container',
              character: 'character',
              characterInactive: 'character--inactive',
              characterSelected: 'character--selected',
              characterFilled: snackbarMessage !== undefined ? 'character--filled--error' : 'character--filled'
            }}
          />
        </StyledWrap>
        <Text textColor={'gray500'} fontStyle={'TextMd_Medium'} text={t('EMAIL.NotReceivedCode')} />
        <Link
          isActive={isRequestEnable && !isCooldownActive}
          fontStyle={'TextMd_Semibold'}
          onClick={() => {
            reqCode().then();
          }}
          text={t('EMAIL.ResendCode')}
        />
      </LinearLayout>
    </Background>
  );
};

export default CodeVerificationTemplate;

const StyledWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding-bottom: 16px;

  .character {
    display: flex;
    justify-content: center;
    align-items: center;
    ${({ theme }) => theme.textStyles.DisplaySm_Medium};
    border-radius: 8px;
    border-color: #475467;
    color: #ffffff;
    background-color: #344054;
    outline: transparent;
    //box-shadow: 0 2px 0 #e4e2f5;
  }

  .container {
    padding: 32px 0 32px 0;
    align-self: center;
  }

  .character--inactive {
  }

  .character--selected {
    position: relative;
  }

  .character--selected:not(.character--filled) {
    color: transparent;
  }

  .character--selected:not(.character--filled)::after {
    content: '';
    width: 1px;
    height: 25px;
    background-color: ${({ theme }) => theme.colors.white};
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: blink 1s infinite step-end;
  }

  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }

  .character--filled {
    border-radius: 8px;
    color: #6ce9a6;
    border-color: #018f69;
    background-color: rgba(0, 112, 82, 0.4);
  }

  .character--filled--error {
    border-radius: 8px;
    color: #f97066;
    border-color: #d92d20;
    background-color: rgba(217, 45, 32, 0.3);
  }
`;

const Background = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray800};
  padding: 0 0 32px 0;
  border-radius: 12px;
  min-width: 600px;
  @media (max-width: 768px) {
    min-width: 0;
  }
`;

const LeftIconDiv = styled.div`
  display: flex;
  align-items: center;
  padding-left: 20px;
  @media (max-width: 768px) {
    padding-left: 16px;
  }
`;

const RightIconDiv = styled.div`
  display: flex;
  align-items: center;
  padding-right: 20px;
  @media (max-width: 768px) {
    padding-right: 16px;
  }
`;

const Title = styled.h1`
  ${({ theme }) => theme.textStyles.TextXl_Bold};
  color: ${({ theme }) => theme.colors.white};
  text-align: start;
  display: flex;
  flex: 1;
  margin-left: 24px;
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextLg_Bold};
    margin-left: 8px;
  }
`;

const Image = styled.img`
  height: 72px;
  width: 72px;
  align-self: center;
  margin-bottom: 16px;
  @media (max-width: 768px) {
    height: 64px;
    width: 64px;
  }
`;

const Link = styled(Text)<{ isActive: boolean }>`
  color: ${({ theme, isActive }) => (isActive ? theme.colors.primary500 : theme.colors.gray600)};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const Snackbar = styled.div`
  background-color: ${({ theme }) => `rgba(${hexToRgb(theme.colors.error600)}, 0.3)`};
  color: ${({ theme }) => theme.colors.error400};
  flex-direction: column;
  width: 100%;
  display: flex;
  padding: 0;
  margin-bottom: 16px;
`;

const ErrorText = styled.span`
  ${({ theme }) => theme.textStyles.TextMd_Medium};
  color: ${({ theme }) => theme.colors.error400};
  align-self: center;
  padding: 10px 16px;
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextSmall_Medium};
  }
`;

function hexToRgb(hex: string) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `${r}, ${g}, ${b}`;
}
