import styled from '@emotion/styled';
import React, { useState } from 'react';

import * as Icons from '../icon';
import { ButtonProps } from './types';
import gtag from 'react-ga4/types/gtag';
import ReactGA from 'react-ga4';
import { logDebug } from '../../../utils/ConsoleUtil';
import LottieAnimation from '../loading/LottieAnimation';
import LinearLayout from '../../layout/LinearLayout';

const BaseButton = ({ leftIcon, iconSize = 16, text, onClick, isLoading, loadingText, disabled, ...props }: ButtonProps) => {
  const [isClicked, setIsClicked] = useState(false);
  const Icon = leftIcon ? Icons[leftIcon] : null;
  const formatTextWithLineBreaks = (text: string) => {
    const lines = text.split('\\n');
    return lines.map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index !== lines.length - 1 && <br />}
      </React.Fragment>
    ));
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!isClicked) {
      setIsClicked(true);
      ReactGA.gtag('event', 'button_click', {
        event_category: 'base_button',
        event_label: text ?? props.children?.toLocaleString(),
        value: 1
      });
      onClick?.(event);
      // 클릭 후 일정 시간 동안 버튼을 비활성화 상태로 설정
      setTimeout(() => setIsClicked(false), 500); // 500ms 동안 클릭 불가
    }
  };
  return (
    <StyledButton {...props} disabled={disabled || isLoading} onClick={handleClick}>
      {isLoading ? (
        <>
          <StyledLoading>
            <LottieAnimation type={'spinner'} width={20} height={20} />
          </StyledLoading>
          {loadingText && formatTextWithLineBreaks(loadingText)}
        </>
      ) : (
        <>
          {Icon && <Icon w={iconSize} h={iconSize} />}
          {text && formatTextWithLineBreaks(text)}
          {props.children}
        </>
      )}
    </StyledButton>
  );
};

const StyledLoading = styled.div`
  width: fit-content;
`;

const StyledButton = styled.button<ButtonProps>`
  flex-direction: ${({ orientation }) => (orientation === 'vertical' ? 'column' : 'row')};
  display: flex;
  overflow: hidden;
  width: 100%;
  ${({ block, w }) => (block ? `flex: 1;` : w && `width: ${w}px;`)}
  ${({ minWidth }) => minWidth && `min-width: ${minWidth}px;`}
    height: ${({ theme, size }) => theme.ButtonSize[size || 'auto']};
  align-items: center;
  justify-content: center;
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : '8')}px;
  gap: ${({ gap }) => gap || 0}px;
  ${({ theme, color, bgColor }) => (color ? theme.ButtonColors[color] : bgColor ? `background-color: ${theme.colors[bgColor]};` : 'background-color: transparent;')}
  ${({ theme, textColor }) => textColor && `color: ${theme.colors[textColor]};`}
    text-align: center;
  ${({ theme, textStyle }) => textStyle && theme.textStyles[textStyle]}
  ${({ theme, shadow }) => shadow && theme.shadow[shadow]};
  cursor: pointer;
  user-select: none;
  transition:
    background 0.2s,
    color 0.2s,
    transform 0.1s ease;
  padding: ${({ padding }) => (padding ? padding : '0')};

  &:disabled {
    cursor: default;
  }

  &:active:not(:disabled) {
    transform: scale(0.97);
  }
`;

export default BaseButton;
