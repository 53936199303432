import { TournamentPayoutInfo } from '../store/slices/tournamentTypes';

export const getTournamentDate = (date: number | string | undefined) => {
  const parsedDate = date ? new Date(date) : new Date();
  return parsedDate.toLocaleString('en-US', {
    minute: '2-digit',
    hour: '2-digit',
    day: 'numeric',
    month: 'short'
    // year: 'numeric'
  });
};

export const getTournamentDuration = (minutes: number | undefined): string => {
  if (minutes === undefined || minutes <= 0) return '0m';

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  const hoursPart = hours > 0 ? `${hours}h` : '';
  const minutesPart = remainingMinutes > 0 ? `${remainingMinutes}` : '';
  if (hoursPart === '' && minutesPart !== '') return `${minutesPart} min`;
  return `${hoursPart} ${minutesPart}m`.trim();
};

export const getTournamentPayout = (payout: TournamentPayoutInfo | undefined): string => {
  if (payout === undefined) return 'No payout';
  return payout.type === 'PERCENTAGE' ? `Top ${payout.value}%` : `Top ${payout.value} players`;
};

export const getTournamentValue = (typeValue: string | undefined): string => {
  if (!typeValue) return '';

  return typeValue.charAt(0).toUpperCase() + typeValue.slice(1).toLowerCase();
};
