import React from 'react';
import { IconProps } from '../types';
import { Colors } from 'src/styles/Colors';

export const Blog = React.memo<IconProps>(({ w = 20, h = 20, color = 'gray400' }) => (
  <svg width={w} height={h} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.5013 2.5H5.83464C5.33464 2.5 5.0013 2.83333 5.0013 3.33333V5.83333H2.5013C2.0013 5.83333 1.66797 6.16667 1.66797 6.66667V15C1.66797 16.4167 2.7513 17.5 4.16797 17.5H15.0013C16.8346 17.5 18.3346 16 18.3346 14.1667V3.33333C18.3346 2.83333 18.0013 2.5 17.5013 2.5ZM5.0013 15C5.0013 15.5 4.66797 15.8333 4.16797 15.8333C3.66797 15.8333 3.33464 15.5 3.33464 15V7.5H5.0013V15ZM10.0013 14.1667H9.16797C8.66797 14.1667 8.33464 13.8333 8.33464 13.3333C8.33464 12.8333 8.66797 12.5 9.16797 12.5H10.0013C10.5013 12.5 10.8346 12.8333 10.8346 13.3333C10.8346 13.8333 10.5013 14.1667 10.0013 14.1667ZM10.0013 10.8333H9.16797C8.66797 10.8333 8.33464 10.5 8.33464 10C8.33464 9.5 8.66797 9.16667 9.16797 9.16667H10.0013C10.5013 9.16667 10.8346 9.5 10.8346 10C10.8346 10.5 10.5013 10.8333 10.0013 10.8333ZM14.168 14.1667H13.3346C12.8346 14.1667 12.5013 13.8333 12.5013 13.3333C12.5013 12.8333 12.8346 12.5 13.3346 12.5H14.168C14.668 12.5 15.0013 12.8333 15.0013 13.3333C15.0013 13.8333 14.668 14.1667 14.168 14.1667ZM14.168 10.8333H13.3346C12.8346 10.8333 12.5013 10.5 12.5013 10C12.5013 9.5 12.8346 9.16667 13.3346 9.16667H14.168C14.668 9.16667 15.0013 9.5 15.0013 10C15.0013 10.5 14.668 10.8333 14.168 10.8333ZM14.168 7.5H9.16797C8.66797 7.5 8.33464 7.16667 8.33464 6.66667C8.33464 6.16667 8.66797 5.83333 9.16797 5.83333H14.168C14.668 5.83333 15.0013 6.16667 15.0013 6.66667C15.0013 7.16667 14.668 7.5 14.168 7.5Z"
      fill={Colors[color]}
    />
  </svg>
));

Blog.displayName = 'Blog';
