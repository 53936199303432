import React from 'react';
import { useLocation } from 'react-router-dom';
import TableHeader from './TableHeader';
import MainHeader from './MainHeader';
import { SUPPORTED_LANGUAGES } from '../../../i18n';

const Index = () => {
  const location = useLocation();
  const pathSegments = location.pathname.split('/').filter(Boolean); // 빈 값 제거
  const isTablePage = pathSegments.includes('table');
  const mainPath = SUPPORTED_LANGUAGES.includes(pathSegments[0]) ? pathSegments[1] : pathSegments[0];

  return isTablePage ? <TableHeader path={mainPath || ''} /> : <MainHeader path={mainPath || ''} />;
};

export default Index;
