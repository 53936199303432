import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { selectTournamentLobbyPacket } from '../store/slices/tournamentSlice';
import { useUserProfile } from './useUserProfile';
import { getBigNumber } from '../utils/BigNumberUtil';
import useAssets from './useAssets';
import { TournamentStatus } from '../store/slices/tournamentTypes';

export const useTournamentLobby = () => {
  const { user } = useUserProfile();
  const { getNetwork } = useAssets();

  const tournamentMatch = useMemo(() => location.pathname.match(/\/tournament\/([^/]+)/), [location.pathname]);
  const tournamentId = tournamentMatch ? tournamentMatch[1] : null;

  const tournamentData = useSelector((state: RootState) => {
    return tournamentId ? selectTournamentLobbyPacket(state, tournamentId) : null;
  });

  const isRegistered = useMemo(() => {
    return tournamentData?.payload?.snapshot?.players?.some(player => player.userId === user?.id);
  }, [user, tournamentData]);

  const isRegisterEnable = useMemo(() => {
    const listed = isRegistered ? [TournamentStatus.CREATED, TournamentStatus.REGISTERING] : [TournamentStatus.CREATED, TournamentStatus.REGISTERING, TournamentStatus.PREPARING, TournamentStatus.LATE_REGISTRATION];
    return listed.includes(tournamentData?.payload?.snapshot?.information?.dynamic?.status ?? TournamentStatus.UNKNOWN);
  }, [isRegistered, tournamentData]);

  const myTournamentTableShareCode = useMemo(() => {
    return tournamentData?.payload?.snapshot?.players?.find(tourUser => tourUser.userId === user?.id)?.shareCode;
  }, [user, tournamentData?.payload?.snapshot?.players]);

  return useMemo(
    () => ({
      tournamentId,
      tournamentData,
      myTournamentTableShareCode,
      latestPacketName: tournamentData?.name,
      latestPacketHeader: tournamentData?.header,
      tournamentInfo: tournamentData?.payload?.snapshot?.information,
      tournamentStructure: tournamentData?.payload?.snapshot?.structure,
      tournamentPrizePool: tournamentData?.payload?.snapshot?.prizePool,
      tournamentPlayers: tournamentData?.payload?.snapshot?.players,
      tournamentSummary: tournamentData?.payload?.snapshot?.summary,
      tournamentTables: tournamentData?.payload?.snapshot?.tables,
      tournamentInTheMoney: tournamentData?.payload?.snapshot?.inTheMoney,
      isRegistered: isRegistered,
      isRegisterEnable: isRegisterEnable,
      totalPrizeAmount: tournamentData?.payload?.snapshot?.prizePool?.pool?.reduce((total, prize) => total + getBigNumber(prize.prizeAmount).toNumber(), 0) ?? 0,
      tournamentStatus: tournamentData?.payload?.snapshot?.information?.dynamic?.status,
      tournamentAsset: getNetwork(tournamentData?.payload?.snapshot?.information?.static?.assetName ?? 'USDT')
    }),
    [tournamentId, tournamentData, getNetwork, user]
  );
};
