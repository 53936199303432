import React from 'react';
import { IconProps } from '../types';
import { Colors } from 'src/styles/Colors';

export const LiveSupport = React.memo<IconProps>(({ w = 20, h = 20, color = 'gray400' }) => (
  <svg width={w} height={h} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_22810_120572)">
      <path
        d="M17.2904 18.3334C17.2904 18.3334 14.8352 17.3673 12.8492 16.5938H4.49714C3.50911 16.5938 2.70703 15.7428 2.70703 14.6944V3.56466C2.70703 2.51727 3.50911 1.66675 4.49714 1.66675H15.4997C16.4883 1.66675 17.2898 2.51675 17.2898 3.56519V13.1506L17.2904 18.3334ZM15.1346 12.0928C15.0956 12.0427 15.0467 12.0013 14.9908 11.9711C14.935 11.941 14.8735 11.9228 14.8102 11.9176C14.7469 11.9125 14.6833 11.9205 14.6233 11.9413C14.5633 11.962 14.5083 11.995 14.4617 12.0381C14.4461 12.0516 12.8914 13.4345 9.99818 13.4345C7.14088 13.4345 5.56224 12.0615 5.53411 12.0365C5.48749 11.9937 5.43247 11.9609 5.37255 11.9403C5.31263 11.9198 5.24909 11.9119 5.18596 11.9171C5.12283 11.9224 5.06146 11.9406 5.00575 11.9708C4.95003 12.0009 4.90116 12.0423 4.86224 12.0923C4.77887 12.1963 4.7381 12.3281 4.74818 12.461C4.75698 12.5933 4.81628 12.7171 4.9138 12.8069C4.98776 12.8735 6.75599 14.4475 9.99818 14.4475C13.2409 14.4475 15.0091 12.8735 15.0831 12.8069C15.1804 12.717 15.2395 12.5932 15.2482 12.461C15.2583 12.3283 15.2177 12.1968 15.1346 12.0928Z"
        fill={Colors[color]}
      />
    </g>
    <defs>
      <clipPath id="clip0_22810_120572">
        <rect width="14.5833" height="16.6667" fill="white" transform="translate(2.70703 1.66675)" />
      </clipPath>
    </defs>
  </svg>
));

LiveSupport.displayName = 'LiveSupport';
