import { useEffect } from 'react';
import { useUserTableSetting } from './useUserTableSetting';
import SoundManager from '../utils/SoundUtil';
import _ from 'lodash';
import { useTableContext } from './TableProvider';
import authSlice from '../store/slices/authSlice';
import { useDispatch } from 'react-redux';
import { TableValidState } from './useTableInit';
import useRouteMatch from './useRouteMatch';

const useTableSound = (tableValidState?: TableValidState) => {
  const setting = useUserTableSetting();
  const { myPlayerData } = useTableContext();
  const userSettings = useUserTableSetting();
  const { shareCode } = useRouteMatch();
  const dispatch = useDispatch();

  useEffect(() => {
    if (tableValidState === 'SUCCESS')
      SoundManager.create()
        .then(() => {
          dispatch(authSlice.actions.setSoundEnabled(true));
        })
        .catch(() => {
          dispatch(authSlice.actions.setSoundEnabled(false));
          if (shareCode) {
            dispatch(
              authSlice.actions.setTableSetting({
                shareCode: shareCode,
                setting: { ...userSettings, isSoundEnabled: false }
              })
            );
          }
        });
  }, [tableValidState]);

  useEffect(() => {
    /**
     * 사운드 설정 새로고침
     */
    if (!SoundManager._instance) return;
    if (setting.isSoundEnabled !== undefined) {
      SoundManager._instance?.setSoundsVolume(setting.isSoundEnabled ? 1 : 0);
    }
  }, [setting.isSoundEnabled, SoundManager._instance]);

  useEffect(() => {
    /**
     * 플레이어 상태에 따른 보이스 볼륨 조절
     */
    if (!SoundManager._instance) return;
    _.delay(() => {
      SoundManager._instance?.setVoiceVolume(myPlayerData && myPlayerData?.status !== 'FOLD' ? 1 : 0);
    }, 1000);
  }, [myPlayerData?.status, SoundManager._instance]);
};

export default useTableSound;
