import React from 'react';
import { IconProps } from '../types';

export const TournamentFinished = React.memo<IconProps>(({ w = 24, h = 24, color = 'white' }) => (
  <svg width="87" height="87" viewBox="0 0 87 87" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M83.5 43.5C83.5 65.5914 65.5914 83.5 43.5 83.5C21.4086 83.5 3.5 65.5914 3.5 43.5C3.5 21.4086 21.4086 3.5 43.5 3.5C65.5914 3.5 83.5 21.4086 83.5 43.5Z" stroke="#475467" strokeWidth="7" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M66.0696 27.1026C67.614 28.6469 67.614 31.1508 66.0696 32.6952L38.8821 59.8827C37.3378 61.427 34.8339 61.427 33.2896 59.8827L20.9316 47.5247C19.3873 45.9804 19.3873 43.4765 20.9316 41.9321C22.476 40.3878 24.9798 40.3878 26.5242 41.9321L36.0858 51.4938L60.4771 27.1026C62.0214 25.5582 64.5253 25.5582 66.0696 27.1026Z"
      fill="#475467"
    />
  </svg>
));

TournamentFinished.displayName = 'TournamentFinished';
