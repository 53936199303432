import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { LobbyTableList } from '../../../store/api/responseTypes';
import { ReactComponent as EmptyIcon } from 'src/assets/img/lobby-empty.svg';
import Text from '../../common/text/Text';
import { useDispatch } from 'react-redux';
import { ModalType, showModal, showModalWithRequest } from '../../../store/slices/modalSlice';
import BaseButton from '../../common/button/BaseButton';
import { useAuthContext } from '../../../hooks/AuthContext';
import TableItem from './TableItem';
import { useTranslation } from 'react-i18next';

interface TableListTemplateProps {
  tableList?: LobbyTableList;
  selectedTable?: number;
  setSelectedTable?: (tableId: number) => void;
  assetFilter?: string;
  type?: 'lobby' | 'landing';
}

const TableListTemplate: React.FC<TableListTemplateProps> = ({ tableList, selectedTable, setSelectedTable, assetFilter, type = 'lobby' }) => {
  const { isAuthorized } = useAuthContext();
  const { t } = useTranslation();
  const [isScrolling, setIsScrolling] = useState(false);
  const dispatch = useDispatch();

  const onScrollHandler = (event: React.UIEvent<HTMLDivElement>) => {
    setIsScrolling(event.currentTarget.scrollTop !== 0);
  };

  const showAuthModal = () => {
    dispatch(showModal(ModalType.AuthModal));
  };

  const showCreateTableModal = useCallback(() => {
    dispatch(showModalWithRequest({ type: ModalType.CreateTableModal, data: { chip: assetFilter } }));
  }, [assetFilter]);

  const onCreateTableClick = () => {
    isAuthorized ? showCreateTableModal() : showAuthModal();
  };

  const isEmpty =
    tableList?.my.filter(info => (assetFilter === 'ALL' ? true : info.asset === assetFilter)).length === 0 &&
    tableList?.joined.filter(info => (assetFilter === 'ALL' ? true : info.asset === assetFilter)).length === 0 &&
    tableList?.general.filter(info => (assetFilter === 'ALL' ? true : info.asset === assetFilter)).length === 0;

  return (
    <StyledWrap type={type}>
      <StyledTableHeader>
        <ListRowHeader>
          <StyledCell></StyledCell>
          <StyledCell>{t('TABLE.TableName')}</StyledCell>
          <StyledCell>{t('TABLE.Stakes')}</StyledCell>
          <StyledCell>{t('TABLE.BuyIn')}</StyledCell>
          <StyledCell>{t('TABLE.Players')}</StyledCell>
        </ListRowHeader>
      </StyledTableHeader>
      <StyledTableBody onScroll={onScrollHandler}>
        {tableList && !isEmpty ? (
          Object.keys(tableList).map(key =>
            tableList[key as keyof typeof tableList]
              .filter(info => (assetFilter === 'ALL' ? true : info.asset === assetFilter))
              .map(tableInfo => <TableItem key={tableInfo.tableId} tableInfo={tableInfo} selectedTable={selectedTable} setSelectedTable={setSelectedTable} isMyTable={key !== 'general'} />)
          )
        ) : (
          <StyledEmpty>
            <EmptyIcon />
            <Text fontStyle="TextSmall_Medium" textColor="white" text={t('CASHGAME.CreateTableGuide')} marginBottom={10} />
            <BaseButton textStyle="TextSmall_Semibold" size="btn40" color="primary" textColor="white" onClick={onCreateTableClick}>
              {t('BUTTON.CreateTable')}
            </BaseButton>
          </StyledEmpty>
        )}
      </StyledTableBody>
    </StyledWrap>
  );
};

const StyledWrap = styled.div<{ type: 'lobby' | 'landing' }>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: ${({ type }) => (type === 'lobby' ? '12px' : '12px 12px 0 0')};
  overflow: hidden;
  min-height: ${({ type }) => (type === 'lobby' ? '618px' : '100%')};
  max-height: ${({ type }) => (type === 'lobby' ? '618px' : '100%')};
  background-color: ${({ theme }) => theme.colors.gray800};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  @media (max-width: 768px) {
    max-height: 100%;
    border-radius: 12px 12px 0 0;
  }
`;

const StyledTableHeader = styled.div`
  background-color: ${({ theme }) => theme.colors.gray750};
  display: grid;
  width: 100%;
  flex: 0 0 auto;
`;

const StyledTableBody = styled.div`
  display: grid;
  width: 100%;
  overflow-y: auto;
  grid-auto-rows: min-content;
  gap: 8px;
  flex: 1 1 auto;
`;

const ListRowHeader = styled.div`
  display: grid;
  grid-template-columns: 20fr 206fr 154fr 74fr 134fr;
  color: ${({ theme }) => theme.colors.gray400};
  ${({ theme }) => theme.textStyles.TextMd_Medium};
  padding: 12px 24px;
  gap: 16px;

  @media (max-width: 768px) {
    grid-template-columns: 16fr 100fr 80fr 47fr 55fr;
    ${({ theme }) => theme.textStyles.TextXs_Medium};
    padding: 8px 16px;
    gap: 12px;
  }
`;

const StyledCell = styled.div`
  text-align: left;
  color: ${({ theme }) => theme.colors.gray400};

  &:nth-of-type(5) {
    text-align: right;
  }
`;

const StyledEmpty = styled.div`
  width: 164px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`;

export default TableListTemplate;
