import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import TitleValueHorizontal from '../../common/text/TitleValueHorizontal';
import Text from '../../common/text/Text';
import useDevice from '../../../hooks/useDevice';
import TitleValueVertical from '../../common/text/TitleValueVertical';
import { TextStyles } from '../../../styles/textStyles';
import { Colors } from '../../../styles/Colors';
import { HorizontalLine } from '../../common/line/HorizontalLine';
import TournamentInfoTab from './TournamentInfoTab';
import { XCloseIcon } from '../../common/icon/icons/XCloseIcon';
import HorizontalLayout from '../../layout/HorizontalLayout';
import { motion } from 'framer-motion';
import { useDispatch } from 'react-redux';
import { dismissModal } from '../../../store/slices/modalSlice';
import { useTableContext } from '../../../hooks/TableProvider';
import { numberToDisplayString, timeFormat } from '../../../utils/StringUtil';
import useAsset from '../../../hooks/useAsset';

type InfoType = { label: string; fontStyle: keyof typeof TextStyles; value: string; valueColor: keyof typeof Colors };

type SecondContent = {
  title: 'Avg. Stack' | 'Min. Stack' | 'Late Reg.';
  value: string;
};

const TournamentInfoBox = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const { isMobile } = useDevice();
  const ref = useRef<HTMLDivElement>(null);
  const { tournamentInformation, tournamentTableInformation, tournamentSummary, prizeInfo, entrants, blindInfo, prizePool } = useTableContext();
  const [secondContent, setSecondContent] = useState<SecondContent>();
  const [myRank, setMyRank] = useState<string>('-');

  const { network } = useAsset(tournamentTableInformation?.static.asset.name);

  useEffect(() => {
    switch (tournamentSummary?.myRank) {
      case -1:
        setMyRank('-');
        break;
      case 1:
        setMyRank('1st');
        break;
      case 2:
        setMyRank('2nd');
        break;
      case 3:
        setMyRank('3rd');
        break;
      default:
        setMyRank(`${tournamentSummary?.myRank}th`);
        break;
    }
  }, [tournamentSummary]);

  useEffect(() => {
    if (tournamentSummary?.myRank === tournamentSummary?.prizeInfo?.placesPaid) {
      setSecondContent({ title: 'Min. Stack', value: numberToDisplayString(tournamentSummary?.stackStatus.stacks.smallest, 1) + ' BB' });
    } else {
      setSecondContent({ title: 'Avg. Stack', value: numberToDisplayString(tournamentSummary?.stackStatus.stacks.average, 1) + ' BB' });
    }
    // TODO:late reg 가 가능할때 로직 추가
  }, [tournamentSummary]);

  useEffect(() => {
    if (tournamentInformation?.blindInfo?.current.endAt) {
      const stateEndTime = new Date(tournamentInformation?.blindInfo.current.endAt);
      const end = stateEndTime;
      interval.current = setInterval(() => {
        stateEndTime.getTime();
        const now = new Date().getTime();
        const diff = end.getTime() - now;

        setStateDiffTime(diff);
        if (diff < 0) {
          clearInterval(interval.current!);
          setStateDiffTime(0);
        }
      }, 500);
    } else {
      clearInterval(interval.current!);
      setStateDiffTime(0);
    }
    return () => {
      clearInterval(interval.current!);
    };
  }, [tournamentInformation?.blindInfo]);

  const closeModal = () => dispatch(dismissModal());

  const interval = useRef<NodeJS.Timeout>();
  const [stateDiffTime, setStateDiffTime] = useState<number>(0);

  // React.useEffect(() => {
  //   if (lateRegEndTime) {
  //     const end = lateRegEndTime;
  //     interval.current = setInterval(() => {
  //       lateRegEndTime.getTime();
  //       const now = new Date().getTime();
  //       const diff = end.getTime() - now;
  //
  //       setLateRegDiffTime(diff);
  //       if (diff < 0) {
  //         clearInterval(interval.current!);
  //         setLateRegDiffTime(0);
  //       }
  //     }, 500);
  //   } else {
  //     clearInterval(interval.current!);
  //     setLateRegDiffTime(0);
  //   }
  //   return () => {
  //     clearInterval(interval.current!);
  //   };
  // }, [lateRegEndTime]);

  const handleOpen = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    if (isMobile) {
      document.removeEventListener('mousedown', handleClickOutside);
    } else {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const prizeInfos: InfoType[] = [
    {
      label: 'Total Prize',
      fontStyle: 'DisplayXs_Semibold',
      value: numberToDisplayString(prizeInfo?.total, 0),
      valueColor: 'warning300'
    },
    {
      label: blindInfo?.current.type === 'BREAK' ? 'Break Time' : `Level ${blindInfo?.current.level}`,
      fontStyle: 'DisplayXs_Semibold',
      value: timeFormat(stateDiffTime),
      valueColor: 'white'
    },
    {
      label: 'Players Left',
      fontStyle: 'DisplayXs_Semibold',
      value: `${tournamentSummary?.playerStatus?.live || 0}/${entrants?.length || 0}`,
      valueColor: 'blue500'
    },
    {
      label: '1st Place',
      fontStyle: 'TextMd_Semibold',
      value: numberToDisplayString(prizeInfo?.first, 2),
      valueColor: 'warning300'
    },
    {
      label: `Blinds`,
      fontStyle: 'TextMd_Semibold',
      value: `${blindInfo?.current.sb} | ${blindInfo?.current.bb}`,
      valueColor: 'white'
    },
    {
      label: 'Places Paid',
      fontStyle: 'TextMd_Semibold',
      value: String(prizeInfo?.placesPaid || 0),
      valueColor: 'blue500'
    },
    {
      label: 'Next Prize',
      fontStyle: 'TextMd_Semibold',
      value: numberToDisplayString(prizeInfo?.next, 2),
      valueColor: 'warning300'
    },
    {
      label: `Next Blind`,
      fontStyle: 'TextMd_Semibold',
      value: `${blindInfo?.next.sb} | ${blindInfo?.next.bb}`,
      valueColor: 'white'
    },
    {
      label: 'My Rank',
      fontStyle: 'TextMd_Semibold',
      value: myRank,
      valueColor: 'blue500'
    }
  ];

  const stackInfos: InfoType[] = [
    {
      label: 'Largest Stack',
      fontStyle: 'TextMd_Semibold',
      value: numberToDisplayString(tournamentSummary?.stackStatus.stacks.largest, 1) + ' BB',
      valueColor: 'success400'
    },
    {
      label: 'Average Stack',
      fontStyle: 'TextMd_Semibold',
      value: numberToDisplayString(tournamentSummary?.stackStatus.stacks.average, 1) + ' BB',
      valueColor: 'success400'
    },
    {
      label: 'Smallest Stack',
      fontStyle: 'TextMd_Semibold',
      value: numberToDisplayString(tournamentSummary?.stackStatus.stacks.smallest, 1) + ' BB',
      valueColor: 'success400'
    }
  ];

  return (
    <StyledContainer ref={ref}>
      {!isMobile && (
        <StyledWrap layoutId={'tournamentInfo'} onClick={handleOpen} transition={{ duration: 0 }}>
          <TitleValueHorizontal labelFontStyle={'TextSmall_Medium'} valueFontStyle={'TextSmall_Bold'} label={blindInfo?.current.type === 'BREAK' ? 'Break Time' : `Level ${blindInfo?.current.level}`} value={timeFormat(stateDiffTime)} />
          <TitleValueHorizontal labelFontStyle={'TextSmall_Medium'} valueFontStyle={'TextSmall_Bold'} label={secondContent?.title} value={secondContent?.value} />
          <TitleValueHorizontal labelFontStyle={'TextSmall_Medium'} valueFontStyle={'TextSmall_Bold'} label={`Prize`} imageUrl={network?.symbolImage} value={numberToDisplayString(prizeInfo?.total, 0)} valueColor={'warning300'} />
          {secondContent?.title === 'Min. Stack' && (
            <TitleValueHorizontal labelFontStyle={'TextSmall_Medium'} valueFontStyle={'TextSmall_Bold'} label={`My Rank`}>
              <StyledTextWrap>
                <Text fontStyle={'TextSmall_Bold'} text={String(tournamentSummary?.myRank || 0)} textColor={'blue500'} />
                <Text fontStyle={'TextSmall_Bold'} text={`/${entrants?.length || 0}`} textColor={'white'} />
              </StyledTextWrap>
            </TitleValueHorizontal>
          )}
        </StyledWrap>
      )}
      {(isMobile || isOpen) && (
        <StyledPanel
          layoutId={'tournamentInfo'}
          transition={{
            duration: 0.2,
            type: 'spring',
            stiffness: 240,
            damping: 20
          }}
        >
          {isMobile && (
            <HorizontalLayout margin={'0'} padding={36}>
              <Title>{'Tournament Info'}</Title>
              <RightIconDiv>
                <XCloseIcon onClick={closeModal} />
              </RightIconDiv>
            </HorizontalLayout>
          )}
          <StyledPrizeInfo>
            {prizeInfos.map((prizeInfo, index) => (
              <TitleValueVertical
                key={index}
                labelFontStyle={'TextSmall_Medium'}
                iconSize={index === 0 ? 20 : 16}
                imageUrl={prizeInfo.valueColor === 'warning300' ? network?.symbolImage : undefined}
                valueFontStyle={prizeInfo.fontStyle}
                label={prizeInfo.label}
                value={prizeInfo.value}
                valueColor={prizeInfo.valueColor}
              />
            ))}
          </StyledPrizeInfo>
          <StyledHorizontalLine />
          <StyledPrizeInfo>
            {stackInfos.map((stackInfo, index) => (
              <TitleValueVertical key={index} labelFontStyle={'TextSmall_Medium'} valueFontStyle={stackInfo.fontStyle} label={stackInfo.label} value={stackInfo.value} valueColor={stackInfo.valueColor} />
            ))}
          </StyledPrizeInfo>
          <TournamentInfoTab initialTab={'Prize'} />
        </StyledPanel>
      )}
    </StyledContainer>
  );
};

const Title = styled.h1`
  ${({ theme }) => theme.textStyles.TextXl_Semibold};
  color: ${({ theme }) => theme.colors.white};
  text-align: start;
  display: flex;
  flex: 1;
  margin: 0 24px 0 0;
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextMd_Semibold};
    margin: 0 8px;
  }
`;

const RightIconDiv = styled.div`
  display: flex;
  align-items: center;
`;

const StyledHorizontalLine = styled(HorizontalLine)`
  margin: 0 16px;
  display: flex;
  width: auto;
  box-sizing: border-box;
`;

const StyledPrizeInfo = styled.div`
  display: grid;
  grid-gap: 24px;
  padding: 0 16px;
  box-sizing: border-box;
  grid-template-columns: repeat(3, 1fr);
`;

const StyledPanel = styled(motion.div)`
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  z-index: 10;
  height: 572px;
  width: 368px;
  background-color: ${({ theme }) => theme.colors.gray800};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.gray700};
  ${({ theme }) => theme.shadow['2xl']};
  padding-top: 16px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    height: auto;
    width: auto;
    border-radius: 0;
    padding-top: 0;
  }
`;

const StyledContainer = styled.div`
  position: relative;
  width: 100%;
  @media (max-width: 768px) {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
`;

const StyledTextWrap = styled.div`
  display: flex;
`;

const StyledWrap = styled(motion.div)`
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  right: 0;
  left: 0;
  box-sizing: border-box;

  border-radius: 8px;
  gap: 4px;
  border: 1px solid ${({ theme }) => theme.colors.gray750};
  background: ${({ theme }) => theme.colors.gray700_50};
  cursor: pointer;
`;

export default TournamentInfoBox;
