import { io, Socket } from 'socket.io-client';
import { EventName, GameRound, HeaderAction, HeaderType } from '../store/slices/streamingTypes';
import uuid from 'react-uuid';
import { baseWss } from '../utils/Util';
import { logDebug, logError, logWarn } from '../utils/ConsoleUtil';
import CryptoJS from 'crypto-js';

export enum SocketNamespace {
  CHAT = 'CHAT',
  TABLE = 'TABLE',
  LOBBY = 'OBSERVER',
  GENERAL = 'GENERAL',
  TOURNAMENT = 'TOURNAMENT',
  GAME_TABLE = 'GAME_TABLE',
  MESSAGE = 'message',
  DISCONNECT = 'disconnect'
}

class SocketService {
  private socket: Socket | null = null;
  private static instance: SocketService;

  readonly url = baseWss;
  readonly options = {
    autoConnect: false,
    protocols: [],
    timestampParam: 'timestamp',
    timestampRequests: false,
    withCredentials: true,
    reconnection: false,
    timeout: 3000
  };

  private constructor() {}

  public static getInstance(): SocketService {
    if (!SocketService.instance) {
      logDebug('New Socket Service instance created!!');
      SocketService.instance = new SocketService();
    }
    return SocketService.instance;
  }

  public connect(onConnected?: () => void, onDisconnected?: () => void) {
    if (!this.url) {
      return;
    }
    if (this.isConnected()) {
      logWarn('Socket is already connected');
      onConnected?.();
    } else {
      this.socket = io(this.url, this.options);
      this.socket.connect();

      this.socket.on('connect', () => {
        logDebug('Connected to socket server :', this.socket?.connected);
        onConnected?.();
      });

      this.socket.on('disconnect', reason => {
        logWarn('Disconnected from socket server : ', reason);
        onDisconnected?.();
      });

      this.socket.on('connect_error', error => {
        logError('Connection error : ', error);
      });
    }
  }

  public isConnected(): boolean {
    return this.socket?.connected ?? false;
  }

  public disconnect(): void {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }

  public emit(eventName: string, data: any): void {
    this.socket?.emit(eventName, data);
  }

  public on(eventName: string, callback: (data: any) => void): void {
    if (this.socket) {
      this.socket.off(eventName, callback);
      this.socket.on(eventName, callback);
    }
  }

  public off(eventName: string, callback?: (data: any) => void): void {
    if (this.socket) {
      if (callback) {
        this.socket.off(eventName, callback);
      } else {
        this.socket.off(eventName);
      }
    }
  }

  public offAll(): void {
    logDebug('Disconnect and Remove all listeners.');
    this.socket?.removeAllListeners();
    this.disconnect();
  }

  gameBet(amount: number = 0, handId?: number, round?: GameRound, isTournament?: boolean): void {
    if (this.socket && this.socket.connected) {
      logDebug('BET', {
        handId: handId,
        round: round,
        amount: amount
      });
      this.socket.emit(
        isTournament ? SocketNamespace.GAME_TABLE : SocketNamespace.TABLE,
        JSON.stringify({
          name: EventName.HAND_ACTION,
          header: {
            type: HeaderType.REQUEST,
            action: HeaderAction.HAND_BET,
            messageId: uuid(),
            requestTime: new Date().getTime()
          },
          payload: {
            handId: handId,
            round: round,
            amount: amount
          }
        })
      );
    }
  }

  gameFold(handId?: number, round?: GameRound, isTournament?: boolean): void {
    if (this.socket && this.socket.connected) {
      logDebug('FOLD', {
        handId: handId,
        round: round
      });
      this.socket.emit(
        isTournament ? SocketNamespace.GAME_TABLE : SocketNamespace.TABLE,
        JSON.stringify({
          name: EventName.HAND_ACTION,
          header: {
            type: HeaderType.REQUEST,
            action: HeaderAction.HAND_FOLD,
            messageId: uuid(),
            requestTime: new Date().getTime()
          },
          payload: {
            handId: handId,
            round: round
          }
        })
      );
    }
  }

  gameShowHand(cards: (0 | 1)[], isTournament?: boolean, handId?: number): void {
    if (this.socket && this.socket.connected) {
      logDebug('SHOW', {
        cards
      });
      this.socket.emit(
        isTournament ? SocketNamespace.GAME_TABLE : SocketNamespace.TABLE,
        JSON.stringify({
          name: EventName.HAND_ACTION,
          header: {
            type: HeaderType.REQUEST,
            action: HeaderAction.HAND_SHOWHAND,
            messageId: uuid(),
            requestTime: new Date().getTime()
          },
          payload: {
            cards,
            handId
          }
        })
      );
    }
  }

  chatJoin(): void {
    if (this.socket && this.socket.connected) {
      logDebug(SocketNamespace.CHAT, HeaderAction.USER_JOIN);
      this.socket.emit(
        SocketNamespace.CHAT,
        JSON.stringify({
          name: EventName.CHAT_ACTION,
          header: {
            type: HeaderType.REQUEST,
            action: HeaderAction.USER_JOIN,
            messageId: uuid(),
            requestTime: new Date().getTime()
          },
          payload: {}
        })
      );
    }
  }

  chatLeave(): void {
    if (this.socket && this.socket.connected) {
      logDebug(HeaderAction.USER_LEAVE, {});
      this.socket.emit(
        SocketNamespace.CHAT,
        JSON.stringify({
          name: EventName.CHAT_ACTION,
          header: {
            type: HeaderType.REQUEST,
            action: HeaderAction.USER_LEAVE,
            messageId: uuid(),
            requestTime: new Date().getTime()
          },
          payload: {}
        })
      );
    }
  }

  chatMessageSend(message: string): void {
    if (this.socket && this.socket.connected) {
      this.socket.emit(
        SocketNamespace.CHAT,
        JSON.stringify({
          name: EventName.CHAT_ACTION,
          header: {
            type: HeaderType.REQUEST,
            action: HeaderAction.CHAT_MESSAGE,
            messageId: uuid(),
            requestTime: new Date().getTime()
          },
          payload: {
            message: message.trimStart().trimEnd()
          }
        })
      );
    }
  }

  chatEmojiSend(emojiAlias: string): void {
    if (this.socket && this.socket.connected) {
      this.socket.emit(
        SocketNamespace.CHAT,
        JSON.stringify({
          name: EventName.CHAT_ACTION,
          header: {
            type: HeaderType.REQUEST,
            action: HeaderAction.CHAT_EMOJI,
            messageId: uuid(),
            requestTime: new Date().getTime()
          },
          payload: {
            message: emojiAlias
          }
        })
      );
    }
  }

  tableJoin(shareCode: string): void {
    if (this.socket && this.socket.connected) {
      logDebug('TABLE_JOIN', {
        shareCode
      });
      this.socket.emit(
        SocketNamespace.TABLE,
        JSON.stringify({
          name: EventName.TABLE_ACTION,
          header: {
            type: HeaderType.REQUEST,
            action: HeaderAction.USER_JOIN,
            messageId: uuid(),
            requestTime: new Date().getTime()
          },
          payload: {
            shareCode: shareCode
          }
        })
      );
    }
  }

  lobbyJoin(tableId?: number, shareCode?: string): void {
    if (this.socket && this.socket.connected) {
      logDebug('LOBBY_JOIN', {
        tableId,
        shareCode
      });
      this.socket.emit(
        SocketNamespace.LOBBY,
        JSON.stringify({
          name: EventName.OBSERVER_EVENT,
          header: {
            type: HeaderType.REQUEST,
            action: HeaderAction.USER_JOIN,
            tableId,
            messageId: uuid(),
            requestTime: new Date().getTime()
          },
          payload: {
            shareCode: shareCode
          }
        })
      );
    }
  }

  generalJoin(): void {
    if (this.socket && this.socket.connected) {
      logDebug('SOCKET | GENERAL_JOIN');
      this.socket.emit(
        SocketNamespace.GENERAL,
        JSON.stringify({
          name: EventName.GENERAL_EVENT,
          header: {
            type: HeaderType.REQUEST,
            action: HeaderAction.USER_JOIN,
            messageId: uuid(),
            requestTime: new Date().getTime()
          }
        })
      );
    }
  }

  tableStart(): void {
    if (this.socket && this.socket.connected) {
      this.socket.emit(
        SocketNamespace.TABLE,
        JSON.stringify({
          name: EventName.TABLE_ACTION,
          header: {
            type: HeaderType.REQUEST,
            action: HeaderAction.TABLE_START,
            messageId: uuid(),
            requestTime: new Date().getTime()
          },
          payload: {}
        })
      );
    }
  }

  tableSeatOut(seatId?: number): void {
    if (this.socket && this.socket.connected) {
      logDebug('SEAT_OUT', {
        seatId
      });
      this.socket.emit(
        SocketNamespace.TABLE,
        JSON.stringify({
          name: EventName.TABLE_ACTION,
          header: {
            type: HeaderType.REQUEST,
            action: HeaderAction.USER_SEAT_OUT,
            messageId: uuid(),
            requestTime: new Date().getTime()
          },
          payload: {
            seatId
          }
        })
      );
    }
  }

  tableSeatIn(seatId?: number): void {
    if (this.socket && this.socket.connected) {
      logDebug('SEAT_IN', {
        seatId
      });
      this.socket.emit(
        SocketNamespace.TABLE,
        JSON.stringify({
          name: EventName.TABLE_ACTION,
          header: {
            type: HeaderType.REQUEST,
            action: HeaderAction.USER_SEAT_IN,
            messageId: uuid(),
            requestTime: new Date().getTime()
          },
          payload: {
            seatId
          }
        })
      );
    }
  }

  tableStand(seatId?: number): void {
    if (this.socket && this.socket.connected && seatId !== undefined) {
      logDebug('STAND', {
        seatId
      });
      this.socket.emit(
        SocketNamespace.TABLE,
        JSON.stringify({
          name: EventName.TABLE_ACTION,
          header: {
            type: HeaderType.REQUEST,
            action: HeaderAction.USER_STAND,
            messageId: uuid(),
            requestTime: new Date().getTime()
          },
          payload: {
            seatId
          }
        })
      );
    }
  }

  tableLeave(): void {
    if (this.socket && this.socket.connected) {
      this.socket.emit(
        SocketNamespace.TABLE,
        JSON.stringify({
          name: EventName.TABLE_ACTION,
          header: {
            type: HeaderType.REQUEST,
            action: HeaderAction.USER_LEAVE,
            messageId: uuid(),
            requestTime: new Date().getTime()
          },
          payload: {}
        })
      );
    }
  }

  requestChips(amount: number): void {
    if (this.socket && this.socket.connected) {
      logDebug('ADD CHIPS', {
        amount
      });
      this.socket.emit(
        SocketNamespace.TABLE,
        JSON.stringify({
          name: EventName.TABLE_ACTION,
          header: {
            type: HeaderType.REQUEST,
            action: HeaderAction.ADD_CHIPS,
            messageId: uuid(),
            requestTime: new Date().getTime()
          },
          payload: {
            amount: amount
          }
        })
      );
    }
  }

  approveOrDenyChips(requesterUserId: number, amount: number, approveOrDeny: 'APPROVE' | 'DENY'): void {
    if (this.socket && this.socket.connected) {
      logDebug('CHIPS', {
        requesterUserId,
        amount,
        approveOrDeny
      });
      this.socket.emit(
        SocketNamespace.TABLE,
        JSON.stringify({
          name: EventName.TABLE_ACTION,
          header: {
            type: HeaderType.REQUEST,
            action: HeaderAction.ALLOW_ADD_CHIPS,
            messageId: uuid(),
            requestTime: new Date().getTime()
          },
          payload: {
            requesterUserId: requesterUserId,
            amount: amount,
            isAllowed: approveOrDeny === 'APPROVE'
          }
        })
      );
    }
  }

  verifyPassword(password: string): void {
    if (this.socket && this.socket.connected) {
      const hashedPassword = CryptoJS.SHA256(password).toString(CryptoJS.enc.Hex);
      this.socket.emit(
        SocketNamespace.TABLE,
        JSON.stringify({
          name: EventName.TABLE_ACTION,
          header: {
            type: HeaderType.REQUEST,
            action: HeaderAction.VERIFY_PASSWORD,
            messageId: uuid(),
            requestTime: new Date().getTime()
          },
          payload: {
            tablePassword: hashedPassword
          }
        })
      );
    }
  }

  tableClose(): void {
    if (this.socket && this.socket.connected) {
      logDebug('CLOSE');
      this.socket.emit(
        SocketNamespace.TABLE,
        JSON.stringify({
          name: EventName.TABLE_ACTION,
          header: {
            type: HeaderType.REQUEST,
            action: HeaderAction.TABLE_CLOSE,
            messageId: uuid(),
            requestTime: new Date().getTime()
          },
          payload: {}
        })
      );
    }
  }

  tournamentTableJoin(shareCode?: string, userId?: string): void {
    if (this.socket && this.socket.connected) {
      logDebug('SOCKET | GAME_TABLE_JOIN', shareCode, userId);
      this.socket.emit(
        SocketNamespace.GAME_TABLE,
        JSON.stringify({
          name: EventName.TOURNAMENT_TABLE_ACTION,
          header: {
            type: HeaderType.REQUEST,
            action: HeaderAction.CONNECT,
            messageId: uuid(),
            requestTime: new Date().getTime(),
            userId
          },
          payload: {
            shareCode
          }
        })
      );
    }
  }

  tournamentLobbyJoin(tournamentId?: string): void {
    if (this.socket && this.socket.connected) {
      logDebug('SOCKET | TOURNAMENT_LOBBY_JOIN');
      this.socket.emit(
        SocketNamespace.TOURNAMENT,
        JSON.stringify({
          name: EventName.TOURNAMENT_ACTION,
          header: {
            type: HeaderType.REQUEST,
            action: HeaderAction.CONNECT,
            messageId: uuid(),
            requestTime: new Date().getTime()
          },
          payload: {
            tournamentId
          }
        })
      );
    }
  }

  registerForTournament(tournamentId: string, password?: string): void {
    if (this.socket && this.socket.connected) {
      logDebug('SOCKET | TOURNAMENT_REGISTER', tournamentId, password);
      this.socket.emit(
        SocketNamespace.TOURNAMENT,
        JSON.stringify({
          name: EventName.TOURNAMENT_ACTION,
          header: {
            type: HeaderType.REQUEST,
            action: HeaderAction.REGISTER,
            messageId: uuid(),
            tournamentId,
            requestTime: new Date().getTime()
          },
          payload: {
            tournamentId,
            password
          }
        })
      );
    }
  }

  unregisterForTournament(tournamentId: string): void {
    if (this.socket && this.socket.connected) {
      logDebug('SOCKET | TOURNAMENT_UNREGISTER');
      this.socket.emit(
        SocketNamespace.TOURNAMENT,
        JSON.stringify({
          name: EventName.TOURNAMENT_ACTION,
          header: {
            type: HeaderType.REQUEST,
            action: HeaderAction.UNREGISTER,
            messageId: uuid(),
            tournamentId,
            requestTime: new Date().getTime()
          },
          payload: {
            tournamentId
          }
        })
      );
    }
  }
}

export const bpSocketService = SocketService.getInstance();
