import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import TitleValueHorizontal from '../../common/text/TitleValueHorizontal';
import BaseButton from '../../common/button/BaseButton';
import { TournamentData, TournamentDisplayData } from '../../../store/api/tournamentTypes';
import Text from '../../common/text/Text';
import { getTournamentDate } from '../../../utils/DateUtil';
import { logWarn } from '../../../utils/ConsoleUtil';
import useAssets from '../../../hooks/useAssets';
import useLangNavigate from '../../../hooks/useLangNavigate';

interface TournamentPreviewProps {
  tournamentData?: TournamentData;
}

const TournamentPreview: React.FC<TournamentPreviewProps> = ({ tournamentData }) => {
  const navigate = useLangNavigate();
  const { getNetwork } = useAssets();

  const convertToDisplayData = (tournamentData?: TournamentData): TournamentDisplayData | undefined => {
    if (!tournamentData) return undefined;
    const asset = getNetwork(tournamentData?.assetName);
    const startingStack = `${tournamentData.startingChip.toLocaleString()} (100BB)`;
    const reEntries = tournamentData.reEntries?.code === 'UNLIMITED' ? 'Unlimited' : tournamentData.reEntries?.value?.toString() ?? 'No re-entries';
    const payoutDistribution = tournamentData?.payout.type === 'PERCENTAGE' ? `Top ${tournamentData?.payout.value}% players` : `Top ${tournamentData?.payout.value} players`;
    return {
      startingTime: getTournamentDate(tournamentData.startingTime), // 변환 함수 활용
      lateRegistration: tournamentData.reEntries?.value ? `Up to ${tournamentData.reEntries.value} re-entries` : 'No re-entries',
      chips: `${asset?.displayName} - ${tournamentData.assetName}`,
      assetName: tournamentData.assetName || 'USDT',
      buyIn: `${tournamentData.buyIn.toLocaleString()} ${tournamentData.assetName}`,
      guarantee: `${tournamentData.guarantee.toLocaleString()} ${tournamentData.assetName}`,
      payoutDistribution: `${payoutDistribution}`,
      startingStack: startingStack,
      blindInterval: `${tournamentData.blindInterval?.value || '-'} min (${tournamentData?.blindInterval.code})`,
      registeredPlayers: `${tournamentData.entrantCount.toLocaleString()} players`,
      reEntries: reEntries,
      estimatedDuration: `${tournamentData.estimatedDuration} mins`
    };
  };

  const tournamentDisplayData = convertToDisplayData(tournamentData);

  const goDetail = () => {
    if (tournamentData?.id) {
      navigate(`/tournament/${tournamentData?.id}`);
    }
  };

  const goRegister = () => {
    if (tournamentData?.id) {
      navigate(`/tournament/${tournamentData?.id}`);
    }
  };

  const handleLinkClick = () => {
    alert('Link Clicked!');
  };

  const renderHelpMessage = (defaultText: string, boldText?: string, clickableText?: string): React.ReactNode => {
    return (
      <>
        {boldText && <BoldText>{boldText}</BoldText>} <DefaultText>{defaultText}</DefaultText> {clickableText && <ClickableText onClick={handleLinkClick}>{clickableText}</ClickableText>}
      </>
    );
  };

  useEffect(() => {
    logWarn(tournamentData);
  }, [tournamentData]);
  return (
    <PreviewContainer>
      <PreviewHeader>
        <Text textAlign={'start'} fontStyle={'TextLg_Semibold'} text={`${tournamentData?.title ?? 'Tournament Info'}`} />
        <Text textAlign={'start'} fontStyle={'TextMd_Semibold'} textColor={'gray500'} text={`${tournamentData?.gameType}`} />
      </PreviewHeader>
      <PreviewDetails>
        <TitleValueHorizontal label={'Starting Time'} labelColor={'gray400'} value={tournamentDisplayData?.startingTime ?? '-'} showHelpIcon={false} />
        <TitleValueHorizontal label={'Chips'} labelColor={'gray400'} value={tournamentDisplayData?.chips ?? '-'} showHelpIcon={false} />
        <TitleValueHorizontal label={'Buy-in'} labelColor={'gray400'} value={tournamentDisplayData?.buyIn ?? '-'} showHelpIcon={true} helpMessage={renderHelpMessage('is the entry fee required for participating in a tournament.', 'Buy-in')} />
        <TitleValueHorizontal label={'Guarantee'} labelColor={'gray400'} value={tournamentDisplayData?.guarantee ?? '-'} showHelpIcon={true} helpMessage={renderHelpMessage('is the minimum prize pool amount guaranteed by the tournament organizer.', 'Guarantee (GTD)')} />
        <TitleValueHorizontal label={'Payout Distribution'} labelColor={'gray400'} value={tournamentDisplayData?.payoutDistribution ?? '-'} showHelpIcon={true} helpMessage={renderHelpMessage("is the method by which the tournament's prize pool is divided.", 'Payout Distribution')} />
        <TitleValueHorizontal label={'Blind Interval'} labelColor={'gray400'} value={tournamentDisplayData?.blindInterval ?? '-'} showHelpIcon={true} helpMessage={renderHelpMessage('is the duration between increases in the mandatory bets.', 'Blind Intervals')} />
        <TitleValueHorizontal label={'Starting Stack'} labelColor={'gray400'} value={tournamentDisplayData?.startingStack ?? '-'} showHelpIcon={true} helpMessage={renderHelpMessage('is the amount of chips each player starts with.', 'Starting stack')} />
        <TitleValueHorizontal label={'Re-entries'} labelColor={'gray400'} value={tournamentDisplayData?.reEntries ?? '-'} showHelpIcon={true} helpMessage={renderHelpMessage('are the number of times a player can re-enter.', 'Re-entries')} />
        <TitleValueHorizontal label={'Estimated Duration'} labelColor={'gray400'} value={tournamentDisplayData?.estimatedDuration ?? '-'} showHelpIcon={false} />
      </PreviewDetails>
      <StyledButtonWrap>
        <BaseButton textStyle="TextMd_Semibold" size="btn44" color="gray" textColor="white" onClick={goDetail}>
          Lobby
        </BaseButton>
        <BaseButton textStyle="TextMd_Semibold" size="btn44" color="primary" textColor="white" onClick={goRegister}>
          Register
        </BaseButton>
      </StyledButtonWrap>
    </PreviewContainer>
  );
};

const BoldText = styled.span`
  ${({ theme }) => theme.textStyles.TextSmall_Bold}
  color: ${({ theme }) => theme.colors.gray800};
`;

const DefaultText = styled.span`
  ${({ theme }) => theme.textStyles.TextSmall_Medium}
  color: ${({ theme }) => theme.colors.gray800};
`;

const ClickableText = styled.span`
  ${({ theme }) => theme.textStyles.TextSmall_Bold}
  color: ${({ theme }) => theme.colors.primary600};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.blue300};
  }
`;

const PreviewContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray800};
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
`;

const PreviewHeader = styled.div`
  display: flex;
  padding: 24px;
  border-radius: 12px 12px 0 0;
  flex-direction: column;
  gap: 2px;
  background: linear-gradient(45deg, #101828 0%, #475467 100%);
`;

const PreviewDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px;
`;

const StyledButtonWrap = styled.div`
  display: flex;
  gap: 8px;
  padding: 0 24px 24px 24px;
`;

export default TournamentPreview;
