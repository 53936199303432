import { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import i18n, { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from '../i18n';
import { logDebug, logError } from '../utils/ConsoleUtil';
import useLangNavigate from './useLangNavigate';

const useLanguage = () => {
  const { lang } = useParams<{ lang: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const langNavigate = useLangNavigate();
  const [currentLang, setCurrentLang] = useState(i18n.language);
  const [isResourcesLoaded, setIsResourcesLoaded] = useState(false);

  const isSupportedLanguage = (language: string): boolean => SUPPORTED_LANGUAGES.includes(language);

  const shouldSkipLanguageUpdate = (): boolean => {
    const pathSegments = location.pathname.split('/').filter(Boolean);
    return pathSegments.length > 0 && pathSegments[0] === 'auth'; // Skip for paths starting with 'auth'
  };

  const updatePathWithLanguage = (language: string): void => {
    if (shouldSkipLanguageUpdate()) {
      logDebug('Skipping language update for path:', location.pathname);
      return;
    }

    const pathSegments = location.pathname.split('/').filter(Boolean);

    if (isSupportedLanguage(pathSegments[0])) {
      pathSegments.shift(); // Remove current language from path
    }
    if (language !== DEFAULT_LANGUAGE) {
      pathSegments.unshift(language); // Add new language to the path
    }
    const newPath = `/${pathSegments.join('/')}${location.search}${location.hash}`;
    if (newPath !== location.pathname) {
      navigate(newPath);
    }
  };

  const changeLanguage = (newLang: string): void => {
    if (newLang === i18n.language) return;

    const targetLanguage = isSupportedLanguage(newLang) ? newLang : DEFAULT_LANGUAGE;
    i18n
      .changeLanguage(targetLanguage)
      .then(() => {
        i18n
          .loadLanguages(targetLanguage, err => {
            if (err) {
              logError('Failed to load language resources:', err);
            } else {
              setCurrentLang(targetLanguage);
              updatePathWithLanguage(targetLanguage);
            }
          })
          .then();
      })
      .catch(error => {
        logError('Failed to change language:', error);
      });
  };

  useEffect(() => {
    i18n.on('initialized', () => {
      setIsResourcesLoaded(true);
    });

    return () => {
      i18n.off('initialized'); // Clean up event listener
    };
  }, []);

  useEffect(() => {
    const handleLanguageChanged = (newLanguage: string) => {
      if (newLanguage !== currentLang) {
        setCurrentLang(newLanguage);
        updatePathWithLanguage(newLanguage);
      }
    };

    i18n.on('languageChanged', handleLanguageChanged);

    return () => {
      i18n.off('languageChanged', handleLanguageChanged); // Clean up event listener
    };
  }, [currentLang, location.pathname]);

  useEffect(() => {
    if (isResourcesLoaded) {
      if (!lang) {
        if (currentLang !== DEFAULT_LANGUAGE && !shouldSkipLanguageUpdate()) {
          i18n
            .changeLanguage(DEFAULT_LANGUAGE, err => {
              if (err) {
                logError('Failed to load language resources:', err);
              } else {
                i18n
                  .loadLanguages(DEFAULT_LANGUAGE, err => {
                    if (err) {
                      logError('Failed to load language resources:', err);
                    } else {
                      setCurrentLang(DEFAULT_LANGUAGE);
                      updatePathWithLanguage(DEFAULT_LANGUAGE);
                    }
                  })
                  .then();
              }
            })
            .catch(error => {
              logError('Failed to change language to default:', error);
            });
        }
        return;
      }

      if (!isSupportedLanguage(lang)) {
        const decodedPath = decodeURIComponent(location.pathname); // Decode path
        const path = decodedPath.replace(`/${lang}`, '');
        navigate(path || '/', { replace: true });
        return;
      }

      if (lang !== currentLang) {
        i18n
          .changeLanguage(lang, err => {
            if (err) {
              logError('Failed to load language resources:', err);
            } else {
              i18n
                .loadLanguages(lang, err => {
                  if (err) {
                    logError('Failed to load language resources:', err);
                  } else {
                    setCurrentLang(lang);
                    updatePathWithLanguage(lang);
                  }
                })
                .then();
            }
          })
          .then(() => {
            setCurrentLang(lang);
          })
          .catch(error => {
            logError('Failed to change language:', error);
          });
      } else {
        i18n
          .loadLanguages(lang, err => {
            if (err) {
              logError('Failed to load language resources:', err);
            } else {
              setCurrentLang(lang);
              updatePathWithLanguage(lang);
            }
          })
          .then();
      }
    }
  }, [lang, location.pathname, navigate, langNavigate, currentLang, isResourcesLoaded]);

  return { lang, changeLanguage, isResourcesLoaded };
};

export default useLanguage;
