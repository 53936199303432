import { useEffect, useRef } from 'react';
import { useAuthContext } from './AuthContext';
import usePageVisibility from './usePageVisibility';
import { useLocation } from 'react-router-dom';
import { bpSocketService, SocketNamespace } from '../services/BpWebSocketService';
import { handleGeneralEvent } from '../components/common/toast/BpToast';
import tournamentSlice from '../store/slices/tournamentSlice';
import { useDispatch } from 'react-redux';
import useRouteMatch from './useRouteMatch';

const useSocket = () => {
  const { isAuthorized, channel, user } = useAuthContext();
  const isPageVisible = usePageVisibility();
  const location = useLocation();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const dispatch = useDispatch();
  const { tournamentId, isCashGame, isInTable } = useRouteMatch();

  useEffect(() => {
    if (!isInTable) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(async () => {
        // Disconnect existing listeners to prevent duplicates
        bpSocketService.off(SocketNamespace.GENERAL);
        bpSocketService.off(SocketNamespace.TOURNAMENT);

        if (isAuthorized && isPageVisible && channel.name) {
          // Connect and add listeners based on page context
          bpSocketService.connect(() => {
            bpSocketService.on(SocketNamespace.GENERAL, msg => {
              handleGeneralEvent(msg, channel);
            });
            bpSocketService.generalJoin();

            if (tournamentId) {
              // Connect to tournament lobby and join with tournament ID
              bpSocketService.on(SocketNamespace.TOURNAMENT, msg => {
                dispatch(tournamentSlice.actions.storeTournamentLobbySnapshot(msg)); // Dispatch the action
              });
              bpSocketService.tournamentLobbyJoin(tournamentId); // Join with tournament ID
            }
          });
        }
      }, 250);
    }

    return () => {
      if (!isInTable) {
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
        bpSocketService.off(SocketNamespace.GENERAL);
        if (tournamentId) {
          bpSocketService.off(SocketNamespace.TOURNAMENT);
        }
      }
    };
  }, [isAuthorized, user, isPageVisible, channel.name, location.pathname]);
};

export default useSocket;
