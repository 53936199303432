import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'src/store/store';
import useResizeObserver from 'use-resize-observer';
import SoundManager from 'src/utils/SoundUtil';
import _ from 'lodash';
import { selectTableRotateNumber, setTableRotateNumber } from '../../../../store/slices/tableSlice';
import { DELAY_WINNING_CHIP_INVISIBLE } from '../../../../utils/AnimationUtil';
import { useTableContext } from '../../../../hooks/TableProvider';
import useDevice from '../../../../hooks/useDevice';

const useTableStyles = () => {
  const { tableId, handRound, mySeatId, handStrength, winners, winnerType, needsChipGathering, tableSettings, tournamentTableInformation } = useTableContext();
  const { ref: tableRef, width } = useResizeObserver<HTMLDivElement>();
  const [tableFontSize, setTableFontSize] = React.useState(16);
  const [hasWinners, setHasWinners] = React.useState(false);
  const rotateNumber = useSelector((state: RootState) => selectTableRotateNumber(state, tableId));
  const [positions, setPositions] = useState<{ x: number; y: number }[] | undefined>(undefined);
  const [customBranding, setCustomBranding] = useState<string | undefined>(undefined);
  const { isMobile } = useDevice();
  const dispatch = useDispatch();

  // TODO Seat 리팩터링 하면서 이관
  const seatPosition = (seatId: number): number => {
    const totalSeats = 9;
    return (seatId - (mySeatId ?? rotateNumber ?? 0) + totalSeats) % totalSeats;
  };

  useEffect(() => {
    if (mySeatId !== undefined) {
      dispatch(setTableRotateNumber({ tableId, rotateNumber: mySeatId }));
    }
  }, [mySeatId, dispatch]);

  React.useEffect(() => {
    if (!handRound) return;
    if (handRound === 'FLOP') {
      SoundManager._instance?.playSound('card-flip');
    } else if (handRound === 'TURN') {
      SoundManager._instance?.playSound('card-flip');
    } else if (handRound === 'RIVER') {
      SoundManager._instance?.playSound('card-flip');
    }
  }, [handRound]);

  useEffect(() => {
    if (needsChipGathering) {
      _.delay(() => {
        SoundManager._instance?.playSound('chip-gathering');
      }, 500);
    }
  }, [needsChipGathering]);

  React.useEffect(() => {
    if (!winnerType) {
      setHasWinners(false);
      return;
    }
    if (winnerType === 'hero') {
      SoundManager._instance?.playSound('win');
    } else if (winnerType === 'villain') {
      SoundManager._instance?.playSound('villain-win');
    }
    _.delay(() => {
      setHasWinners(true);
    }, DELAY_WINNING_CHIP_INVISIBLE);
  }, [winnerType]);

  React.useEffect(() => {
    if (!width) return setTableFontSize(16);

    setTableFontSize((width / 1145) * 16);
  }, [width]);

  const calculateUserPositionsAsPercent = useCallback(
    (numUsers: number, mySeatId: number | undefined) => {
      const positions = [];
      const step = (2 * Math.PI) / numUsers;

      const initialThetaOffset = Math.PI / 2;

      for (let i = 0; i < numUsers; i++) {
        const adjustedSeatId = (i - (mySeatId ?? 0) + numUsers) % numUsers;
        const theta = step * adjustedSeatId + initialThetaOffset;
        const xPercent = 50 + 50 * Math.cos(theta);
        const yPercent = 50 + 50 * Math.sin(theta);
        const correctionValue = isMobile ? 7 : 8;
        const x = Math.round(isMobile && yPercent > 85 && yPercent !== 100 ? (xPercent > 51 ? xPercent + correctionValue : xPercent - correctionValue) : xPercent);
        let y = Math.round(yPercent > 51 ? (isMobile && yPercent > 85 ? yPercent + correctionValue - (isMobile ? 5 : 0) : yPercent + correctionValue) : yPercent < 49 ? yPercent - correctionValue : yPercent);

        if (isMobile) {
          y = y > 49 ? Math.max(62, y) : Math.min(18, y);
        }
        positions.push({ x: Math.min(Math.max(0, Math.round(x)), 100), y: Math.min(Math.max(0, Math.round(y)), isMobile ? 100 : 96.3) });
      }
      return positions;
    },
    [isMobile]
  );

  useEffect(() => {
    if (tableSettings?.seatsCount !== undefined) {
      setPositions(calculateUserPositionsAsPercent(tableSettings.seatsCount, mySeatId));
    }
    if (tournamentTableInformation) {
      setPositions(calculateUserPositionsAsPercent(tournamentTableInformation.static.seat.maxCount || 9, mySeatId));
    }
  }, [mySeatId, isMobile, tableSettings?.seatsCount, tournamentTableInformation]);

  useEffect(() => {}, []);

  return {
    tableRef,
    tableFontSize,
    seatPosition,
    hasWinners,
    positions,
    customBranding
  };
};

export default useTableStyles;
