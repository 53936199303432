import React from 'react';
import styled from '@emotion/styled';
import { StyledCell, StyledRow, StyledRowHeader, StyledTableBody, StyledTableHeader, TableSection } from './styles';
import useDevice from '../../../hooks/useDevice';
import { useUserProfile } from '../../../hooks/useUserProfile';
import { useTableContext } from '../../../hooks/TableProvider';
import { TournamentEntrant } from '../../../store/slices/tournamentTableTypes';
import { css } from '@emotion/react';
import { numberToDisplayString } from '../../../utils/StringUtil';

const TournamentInfoTabPlayers = () => {
  const { user } = useUserProfile();
  const { entrants } = useTableContext();
  const { isMobile } = useDevice();
  const renderPrizePool = (player: TournamentEntrant) => (
    <InTheMoneyRow key={player.userId} textStyles={'TextSmall_Medium'} selected={false}>
      <PlayerCell color={user?.id === player.userId ? 'warning300' : 'white'}>{player.rank}</PlayerCell>
      <PlayerCell color={'white'}>{<StyledFlag src={`https://assets.testbase.xyz/national_flag/${player.flag}.png`} alt={`user-flag`} />}</PlayerCell>
      <PlayerCell ellipsis align={'flex-start'} color={user?.id === player.userId ? 'warning300' : 'white'}>
        {player.nickname}
      </PlayerCell>
      <PlayerCell align={'flex-end'} color={user?.id === player.userId ? 'warning300' : 'white'}>
        {numberToDisplayString(player.chips) + ` (${numberToDisplayString(player.stack, 1)}BB)`}
      </PlayerCell>
    </InTheMoneyRow>
  );

  return (
    <InTheMoneyContainer>
      <TableSection>
        <StyledTableHeader>
          <InTheMoneyHeader textStyles={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}>
            <PlayerCell color={'gray400'}>Rank</PlayerCell>
            <PlayerCell color={'gray400'}>Flag</PlayerCell>
            <PlayerCell align={'flex-start'} color={'gray400'}>
              Player
            </PlayerCell>
            <PlayerCell align={'flex-end'} color={'gray400'}>
              Chips(BB)
            </PlayerCell>
          </InTheMoneyHeader>
        </StyledTableHeader>
        <PlayerBody>{entrants?.map(renderPrizePool)}</PlayerBody>
      </TableSection>
    </InTheMoneyContainer>
  );
};

const StyledFlag = styled.img`
  width: 20px;
  height: 15px;
  border-radius: 2px;
`;

const InTheMoneyContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const InTheMoneyHeader = styled(StyledRowHeader)`
  grid-template-columns: 1fr 1.2fr 2.7fr 2.7fr;
  padding: 8px 16px;
`;

const InTheMoneyRow = styled(StyledRow)`
  grid-template-columns: 1fr 1.2fr 2.7fr 2.7fr;
  padding: 4px 16px;
`;

const PlayerCell = styled(StyledCell)<{ align?: 'flex-end' | 'center' | 'flex-start'; ellipsis?: boolean }>`
  justify-content: ${({ align }) => align || 'center'};
  align-items: center;
  padding: 0;

  ${({ ellipsis }) =>
    ellipsis &&
    css`
      //width: 76px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
    `}
`;

const PlayerBody = styled(StyledTableBody)`
  padding-top: 36px;
`;

export default TournamentInfoTabPlayers;
