import React from 'react';
import styled from '@emotion/styled';
import { HorizontalLine } from '../../common/line/HorizontalLine';
import { useDispatch } from 'react-redux';
import { ModalType, showModalWithRequest } from '../../../store/slices/modalSlice';
import TransactionTemplate from './TransactionTemplate';
import LinearLayout from '../../layout/LinearLayout';
import Text from '../../common/text/Text';
import HorizontalLayout from '../../layout/HorizontalLayout';
import useDevice from '../../../hooks/useDevice';
import { useMyBalance } from '../../../hooks/useMyBalance';
import BaseButton from '../../common/button/BaseButton';
import { WalletIcon } from '../../common/icon';
import { toFixedFloor } from '../../../utils/StringUtil';
import useAssets from '../../../hooks/useAssets';
import { useTranslation } from 'react-i18next';

const WalletTemplate: React.FC = () => {
  const dispatch = useDispatch();
  const { isMobile } = useDevice();
  const { t } = useTranslation();
  const showSendModal = () => dispatch(showModalWithRequest({ type: ModalType.SendModal, data: { hasBack: false } }));
  const showDepositModal = () => dispatch(showModalWithRequest({ type: ModalType.DepositModal, data: { hasBack: false } }));
  const showWithdrawModal = () => dispatch(showModalWithRequest({ type: ModalType.WithdrawModal, data: { hasBack: false } }));
  const { myBalance } = useMyBalance();
  const { getNetworkDecimal, isLoading } = useAssets();

  return (
    <Wrap>
      <LinearLayout backgroundColor={'gray800'} borderRadius={12} padding={40}>
        <StyledOverView>
          <LinearLayout>
            <HorizontalLayout margin={'0 0 12px 0'} gap={8} justifyContent={'start'}>
              <WalletIcon />
              <Text textAlign={'start'} text={t('WALLET.Wallet')} fontStyle={'TextMd_Semibold'} textColor={'white'} />
            </HorizontalLayout>
            <Text textAlign={'start'} textColor={'gray400'} fontStyle={isMobile ? 'TextSmall_Medium' : 'TextMd_Medium'} text={t('WALLET.EstimatedBalance')} marginBottom={4} />
            <Text textAlign={'start'} textColor={'white'} fontStyle={isMobile ? 'TextLg_Semibold' : 'DisplayXs_Semibold'} text={`$${toFixedFloor(Number(myBalance?.estimateBalance ?? 0), 2, false, true) ?? ''}`} />
          </LinearLayout>
          <HorizontalLayout margin={'0'} gap={8} width={isMobile ? '100%' : 'fit-content'}>
            <BaseButton
              orientation={'vertical'}
              borderRadius={4}
              padding={isMobile ? '8px' : '16px'}
              leftIcon={'CashierSend'}
              minWidth={91}
              size={'auto'}
              color={'gray'}
              text={t('WALLET.Send')}
              textStyle={isMobile ? 'TextSmall_Semibold' : 'TextMd_Semibold'}
              textColor={'gray300'}
              onClick={showSendModal}
            />
            <BaseButton
              orientation={'vertical'}
              borderRadius={4}
              padding={isMobile ? '8px' : '16px'}
              leftIcon={'CashierWithdraw'}
              minWidth={91}
              size={'auto'}
              color={'gray'}
              text={t('WITHDRAW.Withdraw')}
              textStyle={isMobile ? 'TextSmall_Semibold' : 'TextMd_Semibold'}
              textColor={'gray300'}
              onClick={showWithdrawModal}
            />
            <BaseButton
              orientation={'vertical'}
              borderRadius={4}
              padding={isMobile ? '8px' : '16px'}
              leftIcon={'CashierDeposit'}
              minWidth={91}
              size={'auto'}
              color={'primary'}
              text={t('DEPOSIT.Deposit')}
              textStyle={isMobile ? 'TextSmall_Semibold' : 'TextMd_Semibold'}
              textColor={'primary100'}
              onClick={showDepositModal}
            />
          </HorizontalLayout>
        </StyledOverView>
        <HorizontalLine />

        <CurrencyListBox>
          {myBalance?.balances
            ?.filter(item => {
              return item.assetName === 'USDT' || item.currencyAmount > 0;
            })
            .map((currency, i) => (
              <CurrencyItem key={currency.assetName + i}>
                <CurrencyInfo>
                  <HorizontalLayout justifyContent={'start'} gap={16} margin={'0'}>
                    <img src={currency.symbolImage} alt={currency.assetName} />
                    <LinearLayout>
                      <Text fontStyle={isMobile ? 'TextSmall_Semibold' : 'TextMd_Semibold'} textColor={'white'} textAlign={'start'} text={currency.assetName} />
                      <Text fontStyle={'TextSmall_Medium'} textColor={'gray400'} textAlign={'start'} text={currency.assetDisplayName} />
                    </LinearLayout>
                  </HorizontalLayout>
                  <LinearLayout width={'fit-content'}>
                    {!isLoading && <Text fontStyle={'TextMd_Semibold'} textColor={'white'} textAlign={'end'} text={`${toFixedFloor(Number(currency.amount ?? 0), getNetworkDecimal(currency.assetName), true, true)}`} />}
                    {!isLoading && <Text fontStyle={'TextSmall_Medium'} textColor={'gray400'} textAlign={'end'} text={`$${toFixedFloor(Number(currency.currencyAmount ?? 0), 2, false, true)}`} />}
                  </LinearLayout>
                </CurrencyInfo>
              </CurrencyItem>
            ))}
        </CurrencyListBox>
      </LinearLayout>
      <TransactionTemplate />
    </Wrap>
  );
};

const StyledOverView = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 24px;
  margin-bottom: 24px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;
  }
`;

const Wrap = styled.div`
  width: 100%;
`;

const CurrencyListBox = styled.div`
  list-style: none;
  width: 100%;
  margin-top: 24px;
  padding: 0;

  flex-direction: column;
  display: flex;
  gap: 26px;

  @media (max-width: 768px) {
    margin-top: 16px;
    gap: 16px;
  }
`;

const CurrencyItem = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const CurrencyInfo = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: start;
  gap: 60px;
  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
    gap: 0;
  }

  img {
    width: 32px;
    height: 32px;
  }
`;

export default WalletTemplate;
