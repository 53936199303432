import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { ArrowDown } from '../../common/icon/icons/ArrowDown';
import { ArrowUp } from '../../common/icon/icons/ArrowUp';
import LinearLayout from '../../layout/LinearLayout';
import useDevice from '../../../hooks/useDevice';
import { AssetHistoryItem } from '../../../store/api/dto';
import useBottomReached from '../../../hooks/useInfinityScroll';
import { useLazyHistoryListQuery } from '../../../store/api/assets';
import { getTimestamp, toFixedFloor } from '../../../utils/StringUtil';
import { useSelector } from 'react-redux';
import { selectHistoryHasMore, selectHistoryItems, selectLastHistoryId } from '../../../store/slices/assetSlice';
import Text from '../../common/text/Text';
import { logDebug, logError } from '../../../utils/ConsoleUtil';
import useAssets from '../../../hooks/useAssets';
import { useTranslation } from 'react-i18next';

const HistoryTemplate = () => {
  const { isMobile } = useDevice();
  const { t } = useTranslation();

  const lastId = useSelector(selectLastHistoryId);
  const histories = useSelector(selectHistoryItems);
  const hasMore = useSelector(selectHistoryHasMore);

  const [fetchHistoryList, { isFetching }] = useLazyHistoryListQuery();
  const { isReached, loadMoreRef } = useBottomReached();
  const { getNetworkDecimal } = useAssets();

  useEffect(() => {
    fetchHistoryList({ limit: 20 })
      .unwrap()
      .then(() => {
        logDebug('History list fetched');
      })
      .catch(() => {
        logError('History list fetch failed');
      });
  }, []);

  useEffect(() => {
    if (hasMore && isReached && !isFetching) {
      fetchHistoryList({ lastId: lastId ?? undefined, limit: 20 })
        .unwrap()
        .then(() => {
          logDebug('History list fetched');
        })
        .catch(() => {
          logError('History list fetch failed');
        });
    }
  }, [isReached, isFetching, histories]);

  const getArrowIcon = (tr: AssetHistoryItem) => {
    if (tr.amount < 0) {
      return <ArrowDown color={'error500'} />;
    } else {
      return <ArrowUp color={'primary500'} />;
    }
  };

  const getTypeText = (tr: AssetHistoryItem) => {
    switch (tr.type) {
      case 'BUYIN':
        return t('HISTORY.BuyIn');
      case 'CASHOUT':
        return t('HISTORY.Cashout');
      case 'SEND':
        return t('HISTORY.Sent');
      case 'RECEIVE':
        return t('HISTORY.Received');
      default:
        return tr.type;
    }
  };

  const getView = (historyItem: AssetHistoryItem) => {
    if (isMobile) {
      return (
        <LinearLayout gap={4}>
          <Description>
            <TypeSymbol>{getTypeText(historyItem)}</TypeSymbol>
            <RoomNameText>{historyItem.description}</RoomNameText>
          </Description>
          <DateText>{getTimestamp(historyItem.createdAt)}</DateText>
        </LinearLayout>
      );
    } else {
      return (
        <>
          <DateText>{getTimestamp(historyItem.createdAt)}</DateText>
          <Description>
            <TypeSymbol>{getTypeText(historyItem)}</TypeSymbol>
            <RoomNameText>{historyItem.description}</RoomNameText>
          </Description>
        </>
      );
    }
  };

  return (
    <ActivityList backgroundColor={'gray800'} padding={40} borderRadius={12}>
      <TitleContainer>
        <DateText>{t('HISTORY.Date')}</DateText>
        {!isMobile && <DescriptionTitle>{t('HISTORY.Description')}</DescriptionTitle>}
        <RowBox>{t('HISTORY.Amount')}</RowBox>
      </TitleContainer>
      {histories.map((history, i) => (
        <ActivityItem key={history.id}>
          {getView(history)}
          <RowBox>
            <PriceText>
              {toFixedFloor(Number(history.amount ?? 0), getNetworkDecimal(history.asset.name), false, true)} {isMobile ? '' : history.asset.name}
            </PriceText>
            {getArrowIcon(history)}
          </RowBox>
        </ActivityItem>
      ))}
      {histories.length === 0 && <Text fontStyle={'TextMd_Medium'} textColor={'gray400'} textAlign={'center'} text={t('HISTORY.Empty')} marginTop={160} marginBottom={160} />}
      <div ref={loadMoreRef} />
    </ActivityList>
  );
};

export default HistoryTemplate;

const ActivityList = styled(LinearLayout)`
  overflow-y: auto;
  max-height: 70vh;
  width: 100%;
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  @media (max-width: 768px) {
    padding-bottom: 0;
  }
`;

const ActivityItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid;
  border-bottom-color: ${({ theme }) => theme.colors.gray700};
  padding-top: 16px;
  padding-bottom: 16px;
`;

const RowBox = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  flex: 1;
  ${({ theme }) => theme.textStyles.TextSmall_Medium};
  color: ${({ theme }) => theme.colors.gray400};
  @media (max-width: 768px) {
    gap: 2px;
  }
`;

const DateText = styled.div`
  ${({ theme }) => theme.textStyles.TextSmall_Medium};
  color: ${({ theme }) => theme.colors.gray400};
  flex: 1;
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextXs_Medium};
  }
`;

const DescriptionTitle = styled.div`
  ${({ theme }) => theme.textStyles.TextSmall_Medium};
  color: ${({ theme }) => theme.colors.gray400};
  flex: 2;
  display: flex;
  flex-direction: row;
  gap: 4px;
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextXs_Medium};
  }
`;

const Description = styled.div`
  ${({ theme }) => theme.textStyles.TextMd_Medium};
  color: ${({ theme }) => theme.colors.white};
  flex: 2;
  display: flex;
  flex-direction: row;
  gap: 4px;
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextSmall_Medium};
  }
`;

const TypeSymbol = styled.div`
  ${({ theme }) => theme.textStyles.TextSmall_Medium};
  color: ${({ theme }) => theme.colors.white};
  padding: 2px 8px;
  background-color: ${({ theme }) => theme.colors.gray750};
  border-radius: 100px;
  align-content: center;
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextXm_Medium};
  }
`;

const RoomNameText = styled.div`
  ${({ theme }) => theme.textStyles.TextMd_Medium};
  color: ${({ theme }) => theme.colors.white};
  flex: 1;
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextSmall_Medium};
  }
`;

const PriceText = styled.div`
  ${({ theme }) => theme.textStyles.TextMd_Semibold};
  color: ${({ theme }) => theme.colors.white};
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextSmall_Semibold};
  }
`;
