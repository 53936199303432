import React, { useEffect } from 'react';
import { SliderProps } from './types';
import { RangeSlider, Wrap } from './style';
import useSlider from './useSlider';
import useDevice from 'src/hooks/useDevice';
import styled from '@emotion/styled';
import { ReactComponent as ArrowIcon } from '../../../assets/img/tooltip-arrow.svg';
import { divideBigNumbers, multiplyBigNumbers, subtractBigNumbers } from '../../../utils/BigNumberUtil';
import { numberToDisplayString } from '../../../utils/StringUtil';

const Slider = (props: SliderProps) => {
  const { isMobile } = useDevice();
  const { value, progress, handleSlider, showTooltip } = useSlider(props);

  const handleTouchStart = (e: React.TouchEvent<HTMLInputElement>) => {
    e.preventDefault();
  };

  return (
    <Wrap>
      {isMobile && (
        <TooltipWrap isShow={showTooltip} position={multiplyBigNumbers(divideBigNumbers(value, props.max), subtractBigNumbers(231, 32)).toNumber()}>
          <Tooltip>
            <div>{value < props.max ? numberToDisplayString(value) : 'All In'}</div>
            <TooltipArrow />
          </Tooltip>
        </TooltipWrap>
      )}
      <RangeSlider value={value} type="range" step={props.step} min={props.min} max={props.max} onTouchStart={handleTouchStart} onTouchMove={handleTouchStart} onChange={handleSlider} progress={progress} useThumb={props.min <= props.max} />
    </Wrap>
  );
};

const TooltipArrow = styled(ArrowIcon)`
  position: absolute;
  top: 50%;
  left: 95%;
  transform: translateY(-50%);
  z-index: 1;
`;

const TooltipWrap = styled.div<{ position: number; isShow?: boolean }>`
  bottom: ${({ position }) => position}px;
  left: -55px;
  position: absolute;
  transition: opacity 0.2s ease-in-out;
  ${({ isShow }) => (isShow ? `opacity: 1;` : 'opacity: 0;')}
`;

const Tooltip = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 51px;
  height: 34px;

  ${({ theme }) => theme.textStyles.TextXs_Semibold}
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors['red-gradient']};
  border-radius: 8px;
`;

export default Slider;
