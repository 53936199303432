import React, { useEffect } from 'react';
import Card from '../../../common/card';
import Seat from '../seat';
import Chip from '../../../common/chip';
import useTableStyles from './useTableStyles';
import { CenterArea, CombinationText, CommunityCardsWrap, PlayingArea, Pot, StyledArea, StyledChipWrap, StyledPotArea, StyledTable, StyledTableLogo, StyledTableText, StyledTableWrap, TableArea, TableImg, TotalPot } from './styles';
import TableTimer from './SeatOutCounter';
import useDevice from '../../../../hooks/useDevice';
import { useUserTableSetting } from '../../../../hooks/useUserTableSetting';
import { ModalStatus, ModalType, showModal } from '../../../../store/slices/modalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import useTableActions from '../../../../hooks/useTableActions';
import { useTableContext } from '../../../../hooks/TableProvider';
import { useChats } from '../../../../hooks/useChats';
import { ReactComponent as LogoIcon } from '../../../../assets/img/table-logo.svg';
import WaitingMessage from '../waittingMessage';
import { useTournamentLobby } from '../../../../hooks/useTournamentLobby';
import TableMessage from './tableMessage';
import { useTranslation } from 'react-i18next';
import { CardCombination, CardCombinationMessage, HeaderAction } from '../../../../store/slices/streamingTypes';
import { findTwoPairRanks } from '../../../../utils/StringUtil';
import i18n from 'i18next';
import { SUPPORTED_LANGUAGES } from '../../../../i18n';

const Table = () => {
  const dispatch = useDispatch();
  const pathSegments = location.pathname.split('/').filter(Boolean); // 빈 값 제거
  const mainPath = SUPPORTED_LANGUAGES.includes(pathSegments[0]) ? pathSegments[1] : pathSegments[0];
  const { isMobile } = useDevice();
  const { t } = useTranslation();
  const userTableSetting = useUserTableSetting();
  const { action, tableSnapshot, isTournamentTable, tableSettings, pot, sidePots, totalPot, handStrength, mySeatData, communityCards, handRound, availableSeats, totalPotForDisplay, mySeatId } = useTableContext();
  const { tableFontSize, tableRef, seatPosition, hasWinners, positions, customBranding } = useTableStyles();
  const { canReserve, seatOrBuyIn, selectUser, showVerifyPasswordModal } = useTableActions();
  const { emojis } = useChats();
  const { modalResponse } = useSelector((state: RootState) => state.modalStateSlice);

  const { tournamentData } = useTournamentLobby();

  useEffect(() => {
    if (modalResponse !== null) {
      if (modalResponse.type === ModalType.PasswordVerifyModal && modalResponse.status === ModalStatus.Approve && availableSeats >= 1) {
        seatOrBuyIn(typeof modalResponse.data === 'number' ? modalResponse.data : undefined);
      }
    }
  }, [modalResponse, dispatch]);

  useEffect(() => {
    if (isTournamentTable && action === HeaderAction.USER_SEAT_OUT) {
      dispatch(showModal(ModalType.TournamentResultModal));
    }
  }, [isTournamentTable, action]);

  const myCombination = React.useMemo(() => {
    if (!handStrength) return '';

    const combination = handStrength.combination;
    const strength = t(`TABLE.${CardCombinationMessage[combination]}`);
    const rank = handStrength.rank;
    const card = handStrength.bestHand;
    const isEnglish = i18n.language === 'en'; // 현재 언어가 'en'인지 확인
    let message = '';

    if (strength && card) {
      switch (combination) {
        case CardCombination.HIGH_CARD:
          message += `, ${rank}`;
          break;
        case CardCombination.ONE_PAIR:
          message += isEnglish ? `, ${rank}s` : `, ${rank}`;
          break;
        case CardCombination.TWO_PAIR:
          message += `, ${findTwoPairRanks(card, isEnglish)}`;
          break;
        case CardCombination.THREE_OF_A_KIND:
          message += isEnglish ? `, ${rank}s` : `, ${rank}`;
          break;
        case CardCombination.FULL_HOUSE:
          message += isEnglish ? `, ${rank}s` : `, ${rank}`;
          break;
        case CardCombination.FOUR_OF_A_KIND:
          message += isEnglish ? `, ${rank}s` : `, ${rank}`;
          break;
        default:
          break;
      }
    }

    return `${strength}${message}`;
  }, [handStrength, t, i18n.language]);

  return (
    <StyledTableWrap tableFontSize={tableFontSize}>
      <StyledTable id={'table'} ref={tableRef}>
        <TableArea>
          <StyledArea>
            {positions?.map((pos, i) => (
              <Seat
                isTournament={mainPath === 'tournament'}
                pos={pos}
                fontSize={tableFontSize}
                key={'seat' + i}
                seatPosition={seatPosition(i)}
                seatId={i}
                userTableSetting={userTableSetting}
                selectUser={selectUser}
                seatOrBuyIn={seatOrBuyIn}
                canReserve={canReserve}
                showVerifyPasswordModal={showVerifyPasswordModal}
                emojis={emojis}
              />
            ))}
          </StyledArea>
        </TableArea>{' '}
        <StyledTableLogo>
          {!customBranding && <LogoIcon />}
          <StyledTableText>{customBranding || 'BASEPOKER'}</StyledTableText>
        </StyledTableLogo>
        <TableImg isMobile={isMobile} src={require(`src/assets/img/${isMobile ? 'mobile-' : ''}table-${userTableSetting?.tableStyle?.toLowerCase() || 'gray'}.png`)} alt="table" />
      </StyledTable>
      <PlayingArea>
        <TableTimer />
        <CenterArea>
          <TotalPot>
            {totalPot ? (
              <>
                {t('TABLE.TotalPot')}
                <Pot>{totalPotForDisplay}</Pot>
              </>
            ) : undefined}
          </TotalPot>
          <CombinationText>{myCombination && handRound !== 'SHOWDOWN' ? myCombination : ''}</CombinationText>
          <CommunityCardsWrap>
            {new Array(5).fill(null).map((_, i) => (
              <Card key={`communityCard-${i}`} cardIndex={i} card={communityCards && communityCards?.[i]} cardType={'community'} />
            ))}
          </CommunityCardsWrap>
          <StyledPotArea>
            {!hasWinners && (
              <StyledChipWrap>
                {(sidePots?.length || 0) > 0 ? (
                  sidePots?.map((sidePot, i) => <Chip key={'sidePot-' + i} type={'pot'} stack={sidePot.pot} chipValueMultiplier={tableSettings?.chipValueMultiplier} blindAmount={tableSettings?.blindAmount} />)
                ) : pot ? (
                  <Chip type={'pot'} stack={pot} chipValueMultiplier={tableSettings?.chipValueMultiplier} blindAmount={tableSettings?.blindAmount} />
                ) : undefined}{' '}
              </StyledChipWrap>
            )}
          </StyledPotArea>
        </CenterArea>
      </PlayingArea>

      {mainPath === 'cashgame' && !mySeatData?.autoStandAt && <WaitingMessage />}
      {mainPath === 'tournament' && <TableMessage />}
    </StyledTableWrap>
  );
};

export default Table;
