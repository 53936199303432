import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { logDebug, logError } from '../../utils/ConsoleUtil';
import { setUnauthorized } from '../slices/authSlice';

const publicApi: string[] = ['setting/country', 'setting/emoji', '/health'];

const baseQueryWithHandler = async (args: any, api: any, extraOptions: any) => {
  const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_API,
    prepareHeaders: (headers, { getState }) => {
      headers.append('accept', `application/json`);
      headers.append('credentials', `include`);
      return headers;
    },
    credentials: 'include'
  });
  // API 요청을 실행하고 결과를 result에 저장
  const result = await baseQuery(args, api, extraOptions);

  // 요청이 성공적이고 (error가 없고) 응답 상태가 200(성공)일 때
  if (result?.error === undefined && result?.meta?.response?.status === 200) {
    // 요청 URL이 공개 API가 아닌 경우
    if (!publicApi.includes(args.url)) {
      // Redux 상태에서 인증 실패 상태를 false로 설정 (즉, 인증된 상태로 변경)
      api.dispatch(setUnauthorized(false));
    }
  }
  // 요청이 실패하고, 응답 상태가 401(인증 실패)일 때
  if (result.error && result.error.status === 401) {
    // Redux 상태에서 인증 실패 상태를 true로 설정 (즉, 인증되지 않은 상태로 변경)
    api.dispatch(setUnauthorized(true));
  }
  return result;
};

export const basePokerApi = createApi({
  baseQuery: baseQueryWithHandler,
  refetchOnReconnect: true,
  /**
   * TAG
   */
  tagTypes: ['Util', 'Auth', 'User', 'Table', 'UserNote', 'FoundUser', 'Country', 'Emoji', 'Assets', 'Tournament'],
  endpoints: () => ({})
});

export enum ErrorTypes {
  UNKNOWN = '__unknown__',
  INVALID_PARAMS = '__invalid_params__',
  UNAUTHORIZED = '__unauthorized__',
  USER_NOT_FOUND = '__user_not_found__',
  NOT_FOUND = '__not_found__',
  FAILED_VERIFY = '__failed_verify__',
  NOT_FOUND_BUY_IN_RANGE = '__not_found_buy_in_range__',
  INVALID_ASSET = '__invalid_asset__',
  INVALID_AMOUNT = '__invalid_amount__',
  INVALID_ADDRESS = '__invalid_address__',
  DUPLICATED_DATA = '__duplicated_data__',
  TABLE_INVALID_SEAT = '__table_invalid_seat_status__',
  EXCEPTION = '__exception__',
  EXCEED_USER_NAME_UPDATE = '__exceeded_user_name_update_count__',
  EMAIL_VERIFY_INVALID = '__email_verify_invalid_code__',
  CLOSED_TABLE = '__already_closed_table__',
  NOT_FOUND_TABLE = '__table_not_found__',
  RESTRICTED_IP = '__duplicated_ip__',
  RESTRICTED_LOC = '__restricted_location__',
  MAINTENANCE = '__maintenance__',
  PASSWORD_NOT_VERIFIED = '__password_not_verified__',
  PASSWORD_INVALID = '__invalid_table_password__',
  PASSWORD_EXCEEDED = '__cannot_request_verify_password__',
  LOW_AMOUNT_TO_SEAT_IN = '__low_amount__',
  ALREADY_REQUEST = '__already_has_request__',
  NETWORK_ERROR = 'NETWORK_ERROR',
  NO_ERROR_CODE = 'NO_ERROR_CODE'
}

export function checkErrorType(errorCode: string | undefined): ErrorTypes | undefined {
  if (errorCode) {
    const entries = Object.entries(ErrorTypes);
    for (const [key, value] of entries) {
      if (key === errorCode || value === errorCode) {
        return ErrorTypes[key as keyof typeof ErrorTypes];
      }
    }
  }
  return undefined;
}

export function getErrorMessageByCode(errorCode: string | undefined): string {
  if (errorCode) {
    const errorType = checkErrorType(errorCode);
    if (!errorType) {
      return 'Unspecified error code : ' + errorCode;
    }
    return getErrorMessageByType(errorType);
  } else {
    return '';
  }
}

export function getErrorMessageByType(errorType: ErrorTypes | undefined): string {
  if (errorType) {
    logError('Error Type', errorType);
    return getErrorMessage(errorType);
  } else {
    return '';
  }
}

export function getErrorMessage(errorType: ErrorTypes): string {
  switch (errorType) {
    case ErrorTypes.EXCEED_USER_NAME_UPDATE:
      return 'You have exceeded the maximum number of username updates.';
    case ErrorTypes.EMAIL_VERIFY_INVALID:
      return 'The code you entered is incorrect. Please try again.';
    case ErrorTypes.UNAUTHORIZED:
      return 'You are not authorized to perform this action.';
    case ErrorTypes.USER_NOT_FOUND:
    case ErrorTypes.NOT_FOUND:
      return 'The requested user was not found.';
    case ErrorTypes.FAILED_VERIFY:
      return 'Verification failed. Please try again.';
    case ErrorTypes.NETWORK_ERROR:
      return 'A network error occurred. Please check your connection and try again.';
    case ErrorTypes.NO_ERROR_CODE:
      return 'No error code was provided.';
    case ErrorTypes.UNKNOWN:
    case ErrorTypes.EXCEPTION:
      return 'An unknown error has occurred. Please contact support.';
    case ErrorTypes.NOT_FOUND_BUY_IN_RANGE:
      return 'No purchases found within the specified range.';
    case ErrorTypes.INVALID_ASSET:
      return 'The provided asset is invalid.';
    case ErrorTypes.INVALID_AMOUNT:
      return 'The specified amount is invalid.';
    case ErrorTypes.INVALID_ADDRESS:
      return 'The provided address is invalid.';
    case ErrorTypes.DUPLICATED_DATA:
      return 'Duplicate data encountered. Please verify your information.';
    case ErrorTypes.TABLE_INVALID_SEAT:
      return 'The specified table seat is invalid.';
    case ErrorTypes.PASSWORD_NOT_VERIFIED:
      return 'Please Password Verification';
    case ErrorTypes.PASSWORD_INVALID:
      return 'Incorrect password. Please try again';
    case ErrorTypes.PASSWORD_EXCEEDED:
      return 'You have entered the password incorrectly too many times. Please wait 5 minutes before trying again.';
    default:
      return 'An unexpected error has occurred.';
  }
}

export interface ApiResponse<T> {
  timestamp: number;
  data: T;
}

export interface ErrorResponse {
  timestamp: number;
  data: Record<string, any>;
  code: string;
}

export function isDataResponse(response: any): response is { data: any } {
  return typeof response === 'object' && 'data' in response;
}

export function isErrorResponse(data: any): data is ErrorResponse {
  return typeof data === 'object' && 'code' in data && 'data' in data;
}

export const handleTransformResponse =
  <T>(methodName: string) =>
  (response: ApiResponse<T>): ApiResponse<T> => {
    logDebug(`API [${methodName}]\n`, response);
    return response;
  };

export const handleTransformErrorResponse =
  (methodName: string) =>
  (error: any): { method: string; status: number; code: string; data?: {} } => {
    logError(`API Error [${methodName}]\n`, error);
    const errorStatus = error.status || 500;
    const errorCode = error.data?.code || 'UNKNOWN_ERROR';
    const errorData = error.data?.data || {};

    return {
      method: methodName,
      status: errorStatus,
      code: errorCode,
      data: errorData
    };
  };

export function createQueryParams(dto: Record<string, any>): string {
  const params = new URLSearchParams();
  for (const key in dto) {
    if (dto.hasOwnProperty(key) && dto[key] !== undefined) {
      params.append(key, dto[key].toString());
    }
  }
  return params.toString();
}
