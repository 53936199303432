import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { StyledCell, StyledRow, StyledRowHeader, StyledTableBody, StyledTableHeader, TableSection } from './styles';
import { useTournamentLobby } from '../../../hooks/useTournamentLobby';
import { TournamentPlayer } from '../../../store/slices/tournamentTypes';
import { toFixedFloor } from '../../../utils/StringUtil';
import Text from '../../common/text/Text';
import useOpenTable from '../../../hooks/useOpenTable';

const TournamentDetailTabPlayers: React.FC = () => {
  const { tournamentId, tournamentPlayers } = useTournamentLobby();
  const { openTable } = useOpenTable();

  const renderRow = (index: number, tournamentPlayer: TournamentPlayer) => (
    <TournamentPlayersRow key={tournamentPlayer.userId + index} selected={false} onDoubleClick={() => openTable(tournamentPlayer?.shareCode, tournamentId)}>
      <TournamentPlayersCell color={'white'}>{tournamentPlayer.rank === 0 ? '-' : tournamentPlayer.rank}</TournamentPlayersCell>
      <TournamentPlayersCell color={'white'}>{<StyledFlag src={`https://assets.testbase.xyz/national_flag/${tournamentPlayer.flag}.png`} alt={`user-flag`} />}</TournamentPlayersCell>
      <TournamentPlayersCell color={'white'}>{tournamentPlayer.nickname}</TournamentPlayersCell>
      <TournamentPlayersCell color={'white'}>{toFixedFloor(tournamentPlayer.chips, 0, false, true)}</TournamentPlayersCell>
    </TournamentPlayersRow>
  );

  return (
    <TournamentPlayersContainer>
      <TableSection>
        <StyledTableHeader>
          <TournamentPlayersHeader>
            <TournamentPlayersCell color={'gray400'}>Rank</TournamentPlayersCell>
            <TournamentPlayersCell color={'gray400'}>Flag</TournamentPlayersCell>
            <TournamentPlayersCell color={'gray400'}>Player</TournamentPlayersCell>
            <TournamentPlayersCell color={'gray400'}>Chips(BB)</TournamentPlayersCell>
          </TournamentPlayersHeader>
        </StyledTableHeader>
        <StyledTableBody>{tournamentPlayers && tournamentPlayers?.length > 0 ? tournamentPlayers?.map((value, index) => renderRow(index, value)) : <NoPlayersMessage fontStyle={'TextSmall_Medium'} textColor={'gray300'} text={'No Players'} />}</StyledTableBody>
      </TableSection>
      <StyledDivider />
    </TournamentPlayersContainer>
  );
};

const StyledFlag = styled.img`
  width: 20px;
  height: 15px;
  border-radius: 2px;
`;

const StyledDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.gray900};
`;

const TournamentPlayersContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const TournamentPlayersHeader = styled(StyledRowHeader)`
  grid-template-columns: 0.8fr 0.8fr 1.5fr 1fr;
`;

const TournamentPlayersRow = styled(StyledRow)`
  grid-template-columns: 0.8fr 0.8fr 1.5fr 1fr;
  user-select: none;
`;

const TournamentPlayersCell = styled(StyledCell)`
  align-items: center;
  padding: 8px;
`;

const NoPlayersMessage = styled(Text)`
  padding-top: 160px;
  color: ${({ theme }) => theme.colors.gray500};
  align-self: center;
`;

export default TournamentDetailTabPlayers;
