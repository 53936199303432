import React from 'react';
import { IconProps } from '../types';
import { Colors } from 'src/styles/Colors';

export const Gifts = React.memo<IconProps>(({ w = 20, h = 20, color = 'gray400' }) => (
  <svg width={w} height={h} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.10827 4.59091C5.02225 4.34467 4.97727 4.08353 4.97727 3.81818C4.97727 3.20781 5.21528 2.61969 5.64327 2.18392C6.07172 1.74768 6.65582 1.5 7.26786 1.5C8.65468 1.5 9.51515 2.44154 10 3.25582C10.4849 2.44154 11.3453 1.5 12.7321 1.5C13.3442 1.5 13.9283 1.74768 14.3567 2.18392C14.7847 2.61969 15.0227 3.20781 15.0227 3.81818C15.0227 4.08353 14.9778 4.34467 14.8917 4.59091L16.5886 4.59091C16.7509 4.5909 16.9091 4.59088 17.0436 4.60006C17.1907 4.6101 17.3651 4.63364 17.546 4.70855C17.9247 4.86541 18.2255 5.16627 18.3824 5.54495C18.4573 5.7258 18.4808 5.90022 18.4909 6.04733C18.5 6.18177 18.5 6.33991 18.5 6.50212V6.54334C18.5 6.70554 18.5 6.8637 18.4909 6.99813C18.4808 7.14524 18.4573 7.31966 18.3824 7.50051C18.2255 7.87919 17.9247 8.18005 17.546 8.33691C17.3651 8.41182 17.1907 8.43537 17.0436 8.4454C16.9091 8.45458 16.751 8.45457 16.5887 8.45455H3.41136C3.24906 8.45457 3.09094 8.45458 2.95642 8.4454C2.80932 8.43537 2.63489 8.41182 2.45404 8.33691C2.07536 8.18005 1.7745 7.87919 1.61765 7.50051C1.54274 7.31966 1.51919 7.14524 1.50915 6.99813C1.49998 6.86366 1.49999 6.70555 1.5 6.54331V6.5022C1.49999 6.33996 1.49998 6.1818 1.50915 6.04733C1.51919 5.90022 1.54274 5.7258 1.61765 5.54495C1.7745 5.16627 2.07536 4.86541 2.45404 4.70855C2.63489 4.63364 2.80932 4.6101 2.95642 4.60006C3.0909 4.59088 3.24911 4.5909 3.41137 4.59091L5.10827 4.59091ZM7.26786 3.04545C7.07478 3.04545 6.88673 3.12342 6.74587 3.26684C6.60455 3.41072 6.52273 3.60879 6.52273 3.81818C6.52273 4.02757 6.60455 4.22564 6.74587 4.36953C6.88673 4.51294 7.07478 4.59091 7.26786 4.59091H8.94465C8.88871 4.4585 8.82435 4.32135 8.75093 4.18544C8.37548 3.4904 7.89352 3.04545 7.26786 3.04545ZM11.0554 4.59091C11.1113 4.4585 11.1757 4.32135 11.2491 4.18544C11.6245 3.4904 12.1065 3.04545 12.7321 3.04545C12.9252 3.04545 13.1133 3.12342 13.2541 3.26684C13.3955 3.41072 13.4773 3.60879 13.4773 3.81818C13.4773 4.02757 13.3955 4.22564 13.2541 4.36953C13.1133 4.51294 12.9252 4.59091 12.7321 4.59091H11.0554Z"
      fill={Colors[color]}
    />
    <path
      d="M3.04546 10.7727C3.04546 10.346 3.39142 10 3.81819 10H8.60909C8.82548 10 8.93367 10 9.01632 10.0421C9.08902 10.0792 9.14812 10.1383 9.18516 10.211C9.22727 10.2936 9.22727 10.4018 9.22727 10.6182V17.8818C9.22727 18.0982 9.22727 18.2064 9.18516 18.289C9.14812 18.3617 9.08902 18.4208 9.01632 18.4579C8.93367 18.5 8.82548 18.5 8.60909 18.5H6.26105C5.85379 18.5 5.50233 18.5 5.21294 18.4764C4.90744 18.4514 4.60359 18.3963 4.31121 18.2473C3.87501 18.0251 3.52038 17.6704 3.29813 17.2342C3.14915 16.9419 3.09404 16.638 3.06908 16.3325C3.04543 16.0431 3.04544 15.6917 3.04546 15.2843L3.04546 10.7727Z"
      fill={Colors[color]}
    />
    <path
      d="M14.7871 18.4764C14.4976 18.5 14.1462 18.5 13.7389 18.5H11.3909C11.1745 18.5 11.0663 18.5 10.9837 18.4579C10.911 18.4208 10.8519 18.3617 10.8148 18.289C10.7727 18.2064 10.7727 18.0982 10.7727 17.8818V10.6182C10.7727 10.4018 10.7727 10.2936 10.8148 10.211C10.8519 10.1383 10.911 10.0792 10.9837 10.0421C11.0663 10 11.1745 10 11.3909 10H16.1818C16.6086 10 16.9545 10.346 16.9545 10.7727V15.2843C16.9546 15.6917 16.9546 16.0431 16.9309 16.3325C16.906 16.638 16.8509 16.9419 16.7019 17.2343C16.4796 17.6704 16.125 18.0251 15.6888 18.2473C15.3964 18.3963 15.0926 18.4514 14.7871 18.4764Z"
      fill={Colors[color]}
    />
  </svg>
));

Gifts.displayName = 'Gifts';
