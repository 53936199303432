import React, { useCallback, useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { ArrowLeft, CloseIcon } from '../../common/icon';
import HorizontalLayout from '../../layout/HorizontalLayout';
import { dismissModal, ModalType, showModal } from '../../../store/slices/modalSlice';
import { useDispatch } from 'react-redux';
import useDevice from '../../../hooks/useDevice';
import LinearLayout from '../../layout/LinearLayout';
import NetworkSelect, { NetworkOptionType } from '../../common/select/NetworkSelect';
import Text from '../../common/text/Text';
import TextInput from '../../common/input/TextInput';
import WithdrawInput from './WithdrawInput';
import BaseButton from '../../common/button/BaseButton';
import { useMyBalance } from '../../../hooks/useMyBalance';
import { AssetBalance, AssetType } from '../../../store/api/responseTypes';
import { useLazyCheckVerifyAddressQuery, useRequestWithdrawalMutation } from '../../../store/api/assets';
import useAsset from '../../../hooks/useAsset';
import CurrencyView from './CurrencySelect';
import { toFixedFloor } from '../../../utils/StringUtil';
import { showFailToast } from '../../common/toast/BpToast';
import { getErrorMessageByCode } from '../../../store/api/api';
import { debounce } from 'lodash';
import { addBigNumbers, subtractBigNumbers } from '../../../utils/BigNumberUtil';
import { useTranslation } from 'react-i18next';

const WithdrawModal = ({ hasBack = true }: { hasBack?: boolean }) => {
  const dispatch = useDispatch();
  const { isMobile } = useDevice();
  const { t } = useTranslation();
  const closeModal = () => dispatch(dismissModal());
  const backToCashierModal = () => dispatch(showModal(ModalType.CashierModal));

  const { myBalance: data } = useMyBalance();
  const [selectedAssetBalance, setSelectedAssetBalance] = React.useState<AssetBalance | undefined>(data?.balances[0]);
  const { tokenInfos: networkInfos, displayDecimal } = useAsset(selectedAssetBalance?.assetName ?? AssetType.USDT);

  const [currentView, setCurrentView] = React.useState<'enter' | 'confirm'>('enter');

  const [selectedNetwork, setSelectedNetwork] = React.useState<NetworkOptionType | null>(null);
  const networkWithdrawConfig = useMemo(() => networkInfos?.find(info => info.chainNetworkName === selectedNetwork?.type), [selectedNetwork, networkInfos]);
  const withdrawFeeAmount = useMemo(() => toFixedFloor(networkWithdrawConfig?.withdrawFeeAmount ?? 0, displayDecimal), [networkWithdrawConfig]);
  const minWithdrawAmount = useMemo(() => toFixedFloor(networkWithdrawConfig?.minWithdrawAmount ?? 0, displayDecimal), [networkWithdrawConfig]);

  const [walletAddress, setWalletAddress] = React.useState('');
  const [walletAddressValid, setWalletAddressValid] = React.useState(false);

  const [amount, setAmount] = React.useState('');
  const [amountErrorMessage, setAmountErrorMessage] = React.useState<string>('');
  const [addressErrorMessage, setAddressErrorMessage] = React.useState<string>('');

  const [checkValidAddress, { isSuccess, isLoading: isChecking }] = useLazyCheckVerifyAddressQuery();
  const [mutate, { isLoading }] = useRequestWithdrawalMutation();

  useEffect(() => {
    setSelectedNetwork(null);
    setWalletAddress('');
    setWalletAddressValid(false);
    setAmount('');
    setAmountErrorMessage('');
    setAddressErrorMessage('');
  }, [selectedAssetBalance?.assetName]);

  useEffect(() => {
    setWalletAddress('');
    setWalletAddressValid(false);
    setAmount('');
  }, [selectedNetwork]);

  useEffect(() => {
    const minWithdrawMessage = t('MESSAGE.MinAmountRequired', { amount: toFixedFloor(addBigNumbers(Number(minWithdrawAmount ?? 0), Number(withdrawFeeAmount)).toNumber(), displayDecimal) ?? '0.00', asset: selectedAssetBalance?.assetName });
    if (Number(selectedAssetBalance?.amount) < Number(minWithdrawAmount)) {
      return setAmountErrorMessage(`${t('MESSAGE.NotEnoughBalance')}` + `. ${minWithdrawMessage}`);
    } else if (Number(amount) > 0 && subtractBigNumbers(Number(amount), Number(withdrawFeeAmount)).toNumber() < Number(minWithdrawAmount)) {
      return setAmountErrorMessage(minWithdrawMessage);
    }
    setAmountErrorMessage('');
  }, [selectedAssetBalance, minWithdrawAmount, amount]);

  const checkAddress = useCallback(
    debounce((chainNetwork: string, address: string) => {
      checkValidAddress({ chainNetwork, address })
        .unwrap()
        .then(response => {
          setWalletAddressValid(response?.data);
          setAddressErrorMessage('');
        })
        .catch(() => {
          setWalletAddressValid(false);
          setAddressErrorMessage(t('MESSAGE.InvalidAddress', { network: chainNetwork }));
        });
    }, 250),
    []
  );

  const handleAddressChange = (value: string) => {
    setAddressErrorMessage('');
    setWalletAddressValid(false);
    if (selectedNetwork !== null && value) {
      checkAddress(selectedNetwork.type, value);
    }
    setWalletAddress(value);
  };

  const handleAmountChange = (amount: string) => {
    setAmount(amount);
  };

  const withdraw = async () => {
    if (!walletAddress || subtractBigNumbers(Number(amount), Number(withdrawFeeAmount)).toNumber() < Number(minWithdrawAmount) || !selectedNetwork || !isSuccess || !walletAddressValid) return;
    await mutate({ asset: selectedAssetBalance?.assetName ?? AssetType.USDT, amount: Number(amount), address: walletAddress, chainNetwork: selectedNetwork.type })
      .unwrap()
      .then(() => {
        closeModal();
      })
      .catch(error => {
        showFailToast(getErrorMessageByCode(error?.code));
      });
  };

  return currentView === 'enter' ? (
    <WithdrawEnter
      t={t}
      hasBack={hasBack}
      backToCashierModal={backToCashierModal}
      closeModal={closeModal}
      data={data}
      isMobile={isMobile}
      setSelectedAssetBalance={setSelectedAssetBalance}
      selectedAssetBalance={selectedAssetBalance}
      setSelectedNetwork={setSelectedNetwork}
      handleAddressChange={handleAddressChange}
      amountErrorMessage={amountErrorMessage}
      walletAddress={walletAddress}
      walletAddressValid={walletAddressValid}
      amount={amount}
      displayDecimal={displayDecimal}
      withdrawFeeAmount={withdrawFeeAmount}
      handleAmountChange={handleAmountChange}
      setCurrentView={setCurrentView}
      minWithdrawAmount={minWithdrawAmount}
      selectedNetwork={selectedNetwork}
      isSuccess={isSuccess}
      isChecking={isChecking}
      addressErrorMessage={addressErrorMessage}
    />
  ) : (
    <WithdrawConfirm
      t={t}
      walletAddress={walletAddress}
      closeModal={closeModal}
      selectedAssetBalance={selectedAssetBalance}
      amount={amount}
      displayDecimal={displayDecimal}
      withdrawFeeAmount={withdrawFeeAmount}
      isMobile={isMobile}
      selectedNetwork={selectedNetwork}
      isLoading={isLoading}
      withdraw={withdraw}
      setCurrentView={setCurrentView}
    />
  );
};

export default WithdrawModal;

const LeftIconDiv = styled.div`
  display: flex;
  align-items: center;
`;

const RightIconDiv = styled.div`
  display: flex;
  align-items: center;
`;

const ModalWrap = styled.div`
  width: 100%;
  gap: 24px;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray800};
  padding: 32px 40px;
  border-radius: 12px;
  min-width: 600px;
  max-width: 600px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    min-width: 0;
    gap: 16px;
    padding: 24px 20px;
  }
`;
const Title = styled.h1<{ hasBack: boolean }>`
  ${({ theme }) => theme.textStyles.TextXl_Semibold};
  color: ${({ theme }) => theme.colors.white};
  text-align: start;
  display: flex;
  flex: 1;
  margin: ${({ hasBack }) => (hasBack ? '0 24px' : '0 24px 0 0')};
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextMd_Semibold};
    margin: 0 8px;
  }
`;

const StyledConfirmCurrency = styled.div`
  display: flex;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray750};
  padding: 16px;
  box-sizing: border-box;
  border-radius: 8px;
  justify-content: space-between;
  @media (max-width: 768px) {
    padding: 12px 16px;
  }
`;

const StyledWithdrawInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
`;

const StyledAddressText = styled(Text)`
  max-width: 200px;
  word-break: break-all;
`;

const StyledButtons = styled.div`
  width: 100%;
  gap: 16px;
  display: flex;
`;

const WithdrawEnter = ({
  t,
  hasBack,
  backToCashierModal,
  closeModal,
  data,
  isMobile,
  setSelectedAssetBalance,
  selectedAssetBalance,
  setSelectedNetwork,
  handleAddressChange,
  amountErrorMessage,
  walletAddress,
  walletAddressValid,
  amount,
  displayDecimal,
  withdrawFeeAmount,
  handleAmountChange,
  setCurrentView,
  minWithdrawAmount,
  selectedNetwork,
  isSuccess,
  isChecking,
  addressErrorMessage
}: any) => {
  const getAddressErrorMessage = (assetName: string) => {
    if (selectedNetwork.type === 'ETHEREUM' || selectedNetwork.type === 'POLYGON') {
      return t('MESSAGE.InvalidAddress', { network: `${assetName} - ERC-20` });
    } else {
      return t('MESSAGE.InvalidAddress', { network: `${assetName}` });
    }
  };
  return (
    <ModalWrap>
      <HorizontalLayout margin={'0'}>
        {hasBack && (
          <LeftIconDiv>
            <ArrowLeft onClick={backToCashierModal} />
          </LeftIconDiv>
        )}
        <Title hasBack={hasBack}>{t('WITHDRAW.Withdraw')}</Title>
        <RightIconDiv>
          <CloseIcon onClick={closeModal} />
        </RightIconDiv>
      </HorizontalLayout>
      <LinearLayout gap={16}>
        <LinearLayout gap={isMobile ? 2 : 4}>
          <Text fontStyle={isMobile ? 'TextXs_Medium' : 'TextMd_Medium'} textAlign={'start'} textColor={'gray300'} text={t('DEPOSIT.Asset')} />
          <CurrencyView onChange={setSelectedAssetBalance} defaultValue={selectedAssetBalance} balances={data?.balances ?? []} />
        </LinearLayout>
        <LinearLayout gap={isMobile ? 2 : 4}>
          <Text fontStyle={isMobile ? 'TextXs_Medium' : 'TextMd_Medium'} textAlign={'start'} textColor={'gray300'} text={t('WITHDRAW.Network')} />
          <NetworkSelect defaultValue={selectedNetwork} assetType={selectedAssetBalance?.assetName ?? AssetType.USDT} onChange={network => setSelectedNetwork(network)} />
        </LinearLayout>
        <LinearLayout gap={isMobile ? 2 : 4}>
          <Text fontStyle={isMobile ? 'TextXs_Medium' : 'TextMd_Medium'} textAlign={'start'} textColor={'gray300'} text={t('WITHDRAW.Address', { asset: selectedAssetBalance?.assetName })} />
          <TextInput type={'text'} value={walletAddress} placeholder={t('WITHDRAW.EnterAddress', { asset: selectedAssetBalance?.assetName })} onClear={() => handleAddressChange('')} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleAddressChange(e.target.value)} />
          {walletAddress && addressErrorMessage && <Text textAlign={'start'} text={getAddressErrorMessage(selectedAssetBalance?.assetName)} fontStyle={'TextSmall_Medium'} textColor={'error500'} />}
        </LinearLayout>
        <LinearLayout gap={isMobile ? 2 : 4}>
          <HorizontalLayout justifyContent={'space-between'} margin={'0'}>
            <Text fontStyle={isMobile ? 'TextXs_Medium' : 'TextMd_Medium'} textAlign={'start'} textColor={'gray300'} text={t('CASHIER.Amount')} />
            <Text fontStyle={isMobile ? 'TextSmall_Medium' : 'TextMd_Medium'} textColor={'white'} text={`${toFixedFloor(selectedAssetBalance?.amount ?? 0, displayDecimal, false, true)} ${selectedAssetBalance?.assetName}`} />
          </HorizontalLayout>
          <WithdrawInput value={amount} asset={selectedAssetBalance?.assetName} placeholder={toFixedFloor(0, displayDecimal)} max={Number(toFixedFloor(selectedAssetBalance?.amount ?? 0, displayDecimal))} suffix={'Max'} onValueChange={handleAmountChange} displayDecimal={displayDecimal} />
          {amountErrorMessage && <Text textAlign={'start'} text={amountErrorMessage} fontStyle={'TextSmall_Medium'} textColor={'error500'} />}
        </LinearLayout>
        <LinearLayout gap={0}>
          <HorizontalLayout margin={'0'}>
            <Text fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'gray400'} text={t('WITHDRAW.ReceivingAmount')} />
            <Text
              fontStyle={isMobile ? 'TextMd_Semibold' : 'TextXl_Semibold'}
              textColor={'white'}
              text={`${
                subtractBigNumbers(Number(amount), Number(withdrawFeeAmount)).toNumber() > 0 ? toFixedFloor(subtractBigNumbers(Number(amount), Number(withdrawFeeAmount)).toNumber(), displayDecimal, false, true) : toFixedFloor(0, displayDecimal, false, true)
              } ${selectedAssetBalance?.assetName}`}
            />
          </HorizontalLayout>
          <HorizontalLayout margin={'0'}>
            <Text fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'gray400'} text={t('WITHDRAW.NetworkFee')} />
            <Text fontStyle={isMobile ? 'TextSmall_Medium' : 'TextMd_Medium'} textColor={'gray400'} text={`${toFixedFloor(Number(withdrawFeeAmount), displayDecimal)} ${selectedAssetBalance?.assetName}`} />
          </HorizontalLayout>
        </LinearLayout>
      </LinearLayout>
      <BaseButton
        size={isMobile ? 'btn40' : 'btn60'}
        text={t('WITHDRAW.Withdraw')}
        color={'primary'}
        textStyle={isMobile ? 'TextSmall_Semibold' : 'TextLg_Semibold'}
        disabled={!walletAddress || subtractBigNumbers(Number(amount), Number(withdrawFeeAmount)).toNumber() < Number(minWithdrawAmount) || !selectedNetwork || !isSuccess || !walletAddressValid || isChecking || Number(selectedAssetBalance?.amount) < Number(minWithdrawAmount)}
        onClick={() => setCurrentView('confirm')}
      />
    </ModalWrap>
  );
};

const WithdrawConfirm = ({ t, closeModal, selectedAssetBalance, amount, displayDecimal, withdrawFeeAmount, isMobile, selectedNetwork, isLoading, withdraw, setCurrentView, walletAddress }: any) => {
  return (
    <ModalWrap>
      <HorizontalLayout margin={'0'}>
        <LeftIconDiv>
          <ArrowLeft onClick={() => setCurrentView('enter')} />
        </LeftIconDiv>
        <Title hasBack={true}>{t('WITHDRAW.ConfirmWithdrawTitle')}</Title>
        <RightIconDiv>
          <CloseIcon onClick={closeModal} />
        </RightIconDiv>
      </HorizontalLayout>
      <LinearLayout gap={16}>
        <LinearLayout gap={4}>
          <Text fontStyle={isMobile ? 'TextXs_Medium' : 'TextMd_Medium'} textAlign={'start'} textColor={'gray300'} text={t('WITHDRAW.ConfirmWithdraw')} />
          <StyledConfirmCurrency>
            <HorizontalLayout margin={'0'} gap={8} justifyContent={'start'}>
              <img src={selectedAssetBalance.symbolImage} alt={selectedAssetBalance.assetName} />
              <Text fontStyle={isMobile ? 'TextSmall_Medium' : 'TextMd_Semibold'} textColor={'white'} text={selectedAssetBalance?.assetName} />
            </HorizontalLayout>
            <HorizontalLayout margin={'0'} gap={8} justifyContent={'end'}>
              <Text fontStyle={isMobile ? 'TextLg_Semibold' : 'TextXl_Semibold'} lineHeight={isMobile ? 28 : 30} textColor={'white'} text={`${amount} ${selectedAssetBalance?.assetName}`} />
            </HorizontalLayout>
          </StyledConfirmCurrency>
        </LinearLayout>
        <StyledWithdrawInfo>
          <HorizontalLayout margin={'0'}>
            <Text fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'gray400'} text={t('WITHDRAW.Network')} />
            <Text fontStyle={isMobile ? 'TextSmall_Medium' : 'TextMd_Medium'} textColor={'warning400'} text={selectedNetwork?.label} />
          </HorizontalLayout>
          <HorizontalLayout margin={'0'}>
            <Text fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'gray400'} text={t('COMMON.Address')} />
            <StyledAddressText fontStyle={isMobile ? 'TextSmall_Medium' : 'TextMd_Medium'} textColor={'white'} textAlign={'end'} text={walletAddress} />
          </HorizontalLayout>
          <HorizontalLayout margin={'0'}>
            <Text fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'gray400'} text={t('COMMON.Amount')} />
            <Text fontStyle={isMobile ? 'TextSmall_Medium' : 'TextMd_Medium'} textColor={'white'} text={`${amount} ${selectedAssetBalance?.assetName}`} />
          </HorizontalLayout>
          <HorizontalLayout margin={'0'}>
            <Text fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'gray400'} text={t('WITHDRAW.NetworkFee')} />
            <Text fontStyle={isMobile ? 'TextSmall_Medium' : 'TextMd_Medium'} textColor={'white'} text={`${toFixedFloor(Number(withdrawFeeAmount), displayDecimal)} ${selectedAssetBalance?.assetName}`} />
          </HorizontalLayout>
          <HorizontalLayout margin={'0'}>
            <Text fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'gray400'} text={t('WITHDRAW.WithdrawTime')} />
            <Text fontStyle={isMobile ? 'TextSmall_Medium' : 'TextMd_Medium'} textColor={'white'} text={t('WITHDRAW.WithdrawBlock', { value: selectedNetwork?.confirmCount })} />
          </HorizontalLayout>
        </StyledWithdrawInfo>
      </LinearLayout>
      <Text fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'gray400'} text={t('WITHDRAW.WithdrawBlock', { newLine: isMobile ? '' : '\n' })} />
      <StyledButtons>
        <BaseButton onClick={closeModal} size={isMobile ? 'btn40' : 'btn60'} textStyle={isMobile ? 'TextSmall_Semibold' : 'TextLg_Semibold'} color={'gray'} text={t('BUTTON.Cancel')} />
        <BaseButton isLoading={isLoading} size={isMobile ? 'btn40' : 'btn60'} text={t('BUTTON.Confirm')} color={'primary'} textStyle={isMobile ? 'TextSmall_Semibold' : 'TextLg_Semibold'} onClick={withdraw} />
      </StyledButtons>
    </ModalWrap>
  );
};
