import React, { useState } from 'react';
import styled from '@emotion/styled';
import { TournamentTabBox } from '../../../pages/TournamentDetailScreen';
import TournamentDetailTabInformation from './TournamentDetailTabInformation';
import TournamentDetailTabStructure from './TournamentDetailTabStructure';
import TournamentDetailTabPrizePool from './TournamentDetailTabPrizePool';
import TournamentDetailTabChat from './TournamentDetailTabChat';
import TournamentDetailTabPlayers from './TournamentDetailTabPlayers';
import TournamentDetailTabTables from './TournamentDetailTabTables';
import TournamentDetailTabInTheMoney from './TournamentDetailTabInTheMoney';

const Tabs = styled.div`
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black};
  flex-shrink: 0;
  height: 57px;
`;

const Tab = styled.button<{ isActive: boolean }>`
  background: none;
  padding: 8px 16px;
  ${({ theme }) => theme.textStyles.TextMd_Semibold}
  cursor: pointer;
  color: ${({ theme, isActive }) => (isActive ? theme.colors.white : theme.colors.gray400)};
  border-bottom: ${({ theme, isActive }) => (isActive ? `2px solid ${theme.colors.white}` : 'none')};
  border-style: none;

  &:hover {
    color: ${({ theme }) => theme.colors.white};
  }
`;

const TabContent = styled.div`
  box-sizing: border-box;
  flex: 1;
  overflow: auto;
`;

export type TournamentTab = 'Information' | 'Structure' | 'Prize Pool' | 'Lobby Chat' | 'Players' | 'Tables' | 'In The Money';

const TournamentDetailTab: React.FC<{ initialTab: TournamentTab; tabs: TournamentTab[] }> = ({ initialTab, tabs }) => {
  const [activeTab, setActiveTab] = useState<TournamentTab>(initialTab);

  const handleTabClick = (tab: TournamentTab) => {
    setActiveTab(tab);
  };

  const renderTabContent = (tab: TournamentTab) => {
    switch (tab) {
      case 'Information':
        return <TournamentDetailTabInformation />;
      case 'Structure':
        return <TournamentDetailTabStructure />;
      case 'Prize Pool':
        return <TournamentDetailTabPrizePool />;
      case 'Lobby Chat':
        return <TournamentDetailTabChat />;
      case 'Players':
        return <TournamentDetailTabPlayers />;
      case 'Tables':
        return <TournamentDetailTabTables />;
      case 'In The Money':
        return <TournamentDetailTabInTheMoney />;
      default:
        return null;
    }
  };

  return (
    <TournamentTabBox>
      <Tabs>
        {tabs.map(tab => (
          <Tab key={tab} isActive={activeTab === tab} onClick={() => handleTabClick(tab)}>
            {tab}
          </Tab>
        ))}
      </Tabs>
      <TabContent>{renderTabContent(activeTab)}</TabContent>
    </TournamentTabBox>
  );
};
export default TournamentDetailTab;
