import { Colors } from './Colors';
import { css } from '@emotion/react';

export const ButtonSize = {
  btn32: '32px',
  btn36: '36px',
  btn40: '40px',
  btn44: '44px',
  btn48: '48px',
  btn60: '60px',
  btn70: '70px',
  btn90: '90px',
  auto: 'auto'
};

export const ButtonColors = {
  primary: css`
    background: ${Colors.primary600};
    color: ${Colors.white};
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    &:hover {
      background: ${Colors.primary500};
    }

    &:disabled {
      background: ${Colors.gray700};
      color: ${Colors.gray500};

      svg path {
        fill: ${Colors.gray500};
      }
    }
  `,

  gray: css`
    background: ${Colors.gray700};
    color: ${Colors.white};
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    &:hover {
      background: ${Colors.gray600};
    }

    &:disabled {
      background: ${Colors.gray700};
      color: ${Colors.gray500};

      svg path {
        fill: ${Colors.gray500};
      }
    }
  `,
  grayOpacity: css`
    background: ${Colors.gray600_50};
    color: ${Colors.gray200};
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    &:hover {
      background: ${Colors.gray600_70};
    }

    &:disabled {
      background: ${Colors.gray600_30};
      color: ${Colors.gray500};

      svg path {
        fill: ${Colors.gray500};
      }
    }
  `,
  outline: css`
    background: transparent;
    color: ${Colors.gray200};
    border: 1px solid ${Colors.gray500};

    &:hover {
      background: ${Colors.white_05};
      border: 1px solid ${Colors.gray400};
    }

    &:disabled {
      background: transparent;
      color: ${Colors.gray600};
      border: 1px solid ${Colors.gray600};

      svg path {
        fill: ${Colors.gray600};
      }
    }
  `,
  outline700: css`
    background: transparent;
    color: ${Colors.gray200};
    border: 1px solid ${Colors.gray700};

    &:hover {
      background: ${Colors.white_05};
      border: 1px solid ${Colors.gray400};
    }

    &:disabled {
      background: transparent;
      color: ${Colors.gray600};
      border: 1px solid ${Colors.gray600};

      svg path {
        fill: ${Colors.gray600};
      }
    }
  `,
  text: css`
    background: transparent;
    color: ${Colors.gray200};

    &:hover {
      color: ${Colors.white};
    }

    &:disabled {
      color: ${Colors.gray600};

      svg path {
        fill: ${Colors.gray600};
      }
    }
  `,
  red: css`
    background: ${Colors.error600};
    color: ${Colors.white};
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    &:hover {
      background: ${Colors.error700};
    }

    &:disabled {
      background: ${Colors.gray700};
      color: ${Colors.gray500};

      svg path {
        fill: ${Colors.gray500};
      }
    }
  `,
  actionRed: css`
    color: ${Colors.white};
    box-shadow:
      0 5px 5px rgba(0, 0, 0, 0.25),
      inset 0 1px 1px rgba(255, 255, 255, 0.2);
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.25));
    background: linear-gradient(180deg, #df4d4a 0%, #a33a38 100%);

    &:hover {
      background: linear-gradient(180deg, #df4d4a 0%, #8b1715 100%);
    }
  `,
  actionGray: css`
    color: ${Colors.white};
    box-shadow: 0px 1px 2px 0px rgba(255, 255, 255, 0.25) inset;
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.25));
    background: ${Colors.gray600};

    &:hover {
      background: ${Colors.gray700};
    }

    &:disabled {
      opacity: 0.4;

      &:hover {
        background: ${Colors.gray600};
      }
    }
  `,
  actionGreen: css`
    color: ${Colors.black};
    box-shadow: 0px -2px 2px 0px rgba(5, 79, 49, 0.4) inset;
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.25));
    background: linear-gradient(180deg, #1fd178 0%, #19be73 52%, #00b66a 100%);
    border-radius: 100px;

    //&:hover {
    //  background: linear-gradient(180deg, #018f69 0%, #0b3524 100%);
    //}
  `
};
