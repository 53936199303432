import { useLocation } from 'react-router-dom';
import i18n, { SUPPORTED_LANGUAGES } from '../i18n';

interface RouteMatchResult {
  languageCode: string;
  shareCode: string | null;
  tournamentId: string | null;
  isCashGame: boolean;
  isInTable: boolean;
}

/**
 * useRouteMatch: 현재 경로를 분석하고 필요한 데이터를 반환하는 훅
 */
const useRouteMatch = (): RouteMatchResult => {
  const location = useLocation();

  // 동적으로 언어 코드 패턴 생성
  const langPattern = SUPPORTED_LANGUAGES.join('|');
  const dynamicPatterns = [
    {
      key: 'cashGameTable',
      pattern: new RegExp(`^(?:\\/(${langPattern}))?\\/cashgame\\/table\\/([^/]+)$`)
    },
    {
      key: 'tournamentTable',
      pattern: new RegExp(`^(?:\\/(${langPattern}))?\\/tournament\\/([^/]+)\\/table(?:\\/([^/]+))?$`)
    },
    {
      key: 'tournament',
      pattern: new RegExp(`^(?:\\/(${langPattern}))?\\/tournament\\/([^/]+)$`)
    }
  ];

  // 경로 매칭
  const matches = dynamicPatterns.reduce(
    (acc, { key, pattern }) => {
      const match = location.pathname.match(pattern);
      if (match) {
        acc[key] = match.slice(1); // 첫 번째 그룹은 언어 코드
      }
      return acc;
    },
    {} as Record<string, string[]>
  );

  // 매칭된 결과 처리
  const languageCode = matches['cashGameTable']?.[0] || matches['tournamentTable']?.[0] || matches['tournament']?.[0] || i18n.language || SUPPORTED_LANGUAGES[0];
  const shareCode = matches['cashGameTable']?.[1] || matches['tournamentTable']?.[2] || null;
  const tournamentId = matches['tournament']?.[1] || matches['tournamentTable']?.[1] || null;
  const isCashGame = Boolean(matches['cashGameTable']);
  const isInTable = location.pathname.split('/').some(p => p === 'table');

  return {
    languageCode,
    shareCode,
    tournamentId,
    isCashGame,
    isInTable
  };
};

export default useRouteMatch;
