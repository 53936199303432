import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { ChevronDown } from '../icon/icons/ChevronDown';
import { AssetType, TableCreateAssetConfig } from '../../../store/api/responseTypes';
import { ReactComponent as SelectedIcon } from '../../../assets/img/ic_selected.svg';
import HorizontalLayout from '../../layout/HorizontalLayout';
import useDevice from '../../../hooks/useDevice';
import { useTranslation } from 'react-i18next';

interface AssetSelectProps {
  initial?: string;
  options: TableCreateAssetConfig[];
  onChange: (value: TableCreateAssetConfig) => void;
}

const AssetSelect: React.FC<AssetSelectProps> = ({ initial, options, onChange }) => {
  const { isMobile } = useDevice();
  const { t } = useTranslation();
  const [selectedAsset, setSelectedAsset] = useState<TableCreateAssetConfig | undefined>(options[0]);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (initial) {
      const findAsset = options?.find(asset => asset.asset === initial);
      if (findAsset) {
        setSelectedAsset(findAsset);
        onChange(findAsset);
        setIsOpen(false);
      }
    }
  }, [initial]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [dropdownRef]);

  const handleSelect = (option: TableCreateAssetConfig) => {
    setSelectedAsset(option);
    onChange(option);
    setIsOpen(false);
  };

  const getTitle = (option: TableCreateAssetConfig) => {
    if (option.asset === AssetType.BPP) {
      return t('BUTTON.PlayMoney');
    }
    return option.assetDisplayName + ' - ' + option.asset;
  };

  return (
    <DropdownContainer ref={dropdownRef}>
      <DropdownSelected isMobile={isMobile} onClick={() => setIsOpen(!isOpen)}>
        {selectedAsset ? (
          <>
            <DropdownItemParent>
              <DropdownImage src={selectedAsset.symbolImage} alt={selectedAsset.assetDisplayName + '-Image'} />
              <DropdownText isMobile={isMobile}>{getTitle(selectedAsset)}</DropdownText>
            </DropdownItemParent>
            <ChevronDown w={16} h={16} color={'white'} />
          </>
        ) : (
          <>
            <DropdownText isMobile={isMobile}>Select an asset</DropdownText>
            <ChevronDown w={16} h={16} color={'white'} />
          </>
        )}
      </DropdownSelected>
      {isOpen && (
        <DropdownList>
          {options.map(option => (
            <DropdownItem key={option.asset} onClick={() => handleSelect(option)}>
              <HorizontalLayout justifyContent={'start'} margin={'0'} gap={0}>
                <DropdownImage src={option.symbolImage} alt={option.assetDisplayName + '-DropdownImage'} />
                <DropdownText isMobile={isMobile}>{getTitle(option)}</DropdownText>
              </HorizontalLayout>
              {selectedAsset?.assetId === option.assetId && <SelectedIcon />}
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </DropdownContainer>
  );
};

const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
  cursor: pointer;
`;

const DropdownSelected = styled.div<{ isMobile: boolean }>`
  display: flex;
  ${({ isMobile, theme }) => (isMobile ? theme.textStyles.TextSmall_Medium : theme.textStyles.TextMd_Medium)};
  min-height: 52px;
  padding-left: 16px;
  padding-right: 16px;
  border: none;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.gray900};
  color: ${({ theme }) => theme.colors.white};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &:hover {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.gray600};
  }

  @media (max-width: 768px) {
    min-height: 40px;
  }
`;

const DropdownItemParent = styled.div`
  display: flex;
  flex: 1;
  cursor: pointer;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  border-radius: 8px;
  min-height: 48px;

  @media (max-width: 768px) {
    min-height: 40px;
  }
`;

const DropdownList = styled.div`
  position: absolute;
  width: 92.4%;
  margin-top: 2px;
  background-color: ${({ theme }) => theme.colors.gray900};
  border-radius: 8px;
  max-height: 348px;
  overflow-y: auto;
  z-index: 15;
  padding: 10px 10px;
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.3);

  @media (max-width: 768px) {
    max-height: 150px;
  }
`;

const DropdownItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  border-radius: 8px;
  margin: 4px 0;
  user-select: none;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray800};
  }
`;

const DropdownImage = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;

const DropdownText = styled.div<{ isMobile: boolean }>`
  ${({ isMobile, theme }) => (isMobile ? theme.textStyles.TextSmall_Medium : theme.textStyles.TextMd_Medium)};
  color: ${({ theme }) => theme.colors.white};
`;

export default AssetSelect;
