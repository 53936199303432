import { animate, useMotionValue, useTransform } from 'framer-motion';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Colors } from 'src/styles/Colors';
import { combinedDealerPositionType, UseSeatProps } from '../components/template/room/seat/types';
import SoundManager from '../utils/SoundUtil';
import { CardCombination, CardCombinationMessage, GameRound, HeaderAction, PlayerAction, PlayerStatus } from '../store/slices/streamingTypes';
import { useCountryList } from './useCountryList';
import { DELAY_ACTION_TEXT_CLEAR, DELAY_BEFORE_BET, DELAY_SETTLEMENT_CLEAR } from '../utils/AnimationUtil';
import { useEmojiList } from './useEmojiList';
import { useUserProfile } from './useUserProfile';
import { SeatStatus, TableStatus } from '../store/api/responseTypes';
import { findTwoPairRanks, numberToDisplayString, toFixedFloor } from '../utils/StringUtil';
import { useTableContext } from './TableProvider';
import { useUserTableSetting } from './useUserTableSetting';
import { useCreateConfig } from './useCreateConfig';
import { divideBigNumbers } from '../utils/BigNumberUtil';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { logDebug } from '../utils/ConsoleUtil';

type SeatDisplayType = 'Clear' | 'SmallBlind' | 'BigBlind' | PlayerAction;

const useSeat = ({ seatId, emojis, pos }: UseSeatProps) => {
  const { isTournamentTable, tableId, isTieGame, handRound, ask, tableCallAmount, roles, action, mySeatId, myStack, myUserData, tableSnapshot, header, tableSettings, tableAssetInfo, chipDecimal } = useTableContext();

  const emojiList = useEmojiList();
  const countries = useCountryList();
  const { user } = useUserProfile();
  const userTableSetting = useUserTableSetting();
  const { getAssetInfo } = useCreateConfig();
  const { t } = useTranslation();
  const tableAssetConfig = getAssetInfo(tableAssetInfo?.name);

  const [isWinnerSeat, setIsWinnerSeat] = useState(false);
  const [isTurnSeat, setIsTurnSeat] = useState(false);
  const [seatDisplay, setSeatDisplayType] = useState<SeatDisplayType>('Clear');
  const [showEmoji, setShowEmoji] = useState(false);
  const [progress, setProgress] = useState<number | undefined>(undefined);
  const [extraProgress, setExtraProgress] = useState<number | undefined>(undefined);
  const [extraCountDown, setExtraCountDown] = useState<number | undefined>(undefined);
  const [isProgressSet, setIsProgressSet] = useState(false);
  const [timerBg, setTimerBackground] = useState<keyof typeof Colors>('success400');
  const [textColor, setTextColor] = useState<keyof typeof Colors>();
  const [actionBg, setActionBackground] = useState<keyof typeof Colors>();
  const [actionText, setActionText] = useState<string>('');
  const [dealerPos, setDealerPos] = React.useState<combinedDealerPositionType>();

  const emojiTimer = useRef<NodeJS.Timeout>();
  const animatedStack = useMotionValue(0);

  const seatData = useMemo(() => tableSnapshot?.seats?.find(seat => seat.seatId === seatId), [tableSnapshot?.seats, seatId]);
  const player = useMemo(() => tableSnapshot?.hand?.players?.find(player => player.seatId === seatId), [tableSnapshot?.hand?.players, seatId]);
  const seatedUserData = useMemo(() => tableSnapshot?.users?.find(user => user.userId === seatData?.userId), [tableSnapshot?.users, seatData?.userId]);
  const winnerData = useMemo(() => tableSnapshot?.hand?.winners?.find(winner => winner.seatId === seatId), [tableSnapshot?.hand?.winners, seatId]);
  const isMySeat = useMemo(() => user?.id === seatData?.userId, [user?.id, seatData?.userId]);
  const isHandWaiting = useMemo(() => tableSnapshot?.status === TableStatus.PLAYING && seatData?.status === SeatStatus.SEAT_IN && !player, [tableSnapshot?.status, seatData, player]);
  const isBB = useMemo(() => roles?.bigBlind === seatId, [roles?.bigBlind]);
  const isSB = useMemo(() => roles?.smallBlind === seatId, [roles?.smallBlind]);

  const stack = useMemo(() => {
    const playerStack = player?.stack === undefined ? seatedUserData?.stack : player?.stack;
    return userTableSetting.isBlindView ? divideBigNumbers(playerStack, tableSettings?.blindAmount!.big ?? 1).toNumber() : playerStack ?? 0;
  }, [userTableSetting?.isBlindView, player, seatedUserData, tableSettings?.blindAmount]);

  const emoji = useMemo(() => {
    const filteredEmojis = emojis.filter(emoji => emoji.payload.userId === seatedUserData?.userId);
    return filteredEmojis[filteredEmojis.length - 1];
  }, [emojis, seatedUserData?.userId]);

  const emojiUrl = useMemo(() => emojiList?.find(e => e.alias === emoji?.payload?.message)?.emojiUrl, [emojiList, emoji?.payload?.message]);
  const emojiReceivedTime = emoji?.payload?.timestamp;

  const winRate = useMemo(() => {
    if (player?.tieRate === 100) {
      return 100 / (tableSnapshot?.hand?.winners?.length ?? 1);
    } else {
      return player?.winningRate;
    }
  }, [tableSnapshot?.hand?.winners, player?.winningRate, player?.tieRate]);

  const isTopWinRate = useMemo(() => {
    if (isTieGame) {
      return player?.tieRate === 100;
    } else {
      return winRate !== undefined && tableSnapshot?.hand?.players?.every(player => (player?.winningRate ?? 0) <= winRate);
    }
  }, [winRate, isTieGame, player?.tieRate]);

  const combinationMessage = useMemo(() => {
    if (player === undefined) return '';
    const strength = CardCombinationMessage[player.handStrength?.combination as CardCombination] || player.handStrength?.combination;
    const rank = player.handStrength?.rank;
    const card = player.handStrength?.bestHand;
    let message = '';
    if (strength && card) {
      switch (player?.handStrength?.combination) {
        case CardCombination.HIGH_CARD:
          message += `, ${rank}`;
          break;
        case CardCombination.ONE_PAIR:
          message += `, ${rank}s`;
          break;
        case CardCombination.TWO_PAIR:
          message += `, ${findTwoPairRanks(card)}`;
          break;
        case CardCombination.THREE_OF_A_KIND:
        case CardCombination.FULL_HOUSE:
        case CardCombination.FOUR_OF_A_KIND:
          message += `, ${rank}s`;
          break;
        default:
          break;
      }
    }
    return `${strength}${message}`;
  }, [player?.handStrength]);

  useEffect(() => {
    if (ask?.seatId === seatId) {
      setIsTurnSeat(true);
    } else {
      setIsTurnSeat(false);
    }
  }, [ask?.seatId, seatId]);

  useEffect(() => {
    if (winnerData) {
      if (header?.action === HeaderAction.ROUND_SETTLEMENT) {
        setTimeout(() => {
          setIsWinnerSeat(true);
        }, 2500);
      } else if (header?.action === HeaderAction.USER_JOIN) {
        setIsWinnerSeat(true);
      }
    } else {
      setIsWinnerSeat(false);
    }
  }, [winnerData, header?.action]);

  const transformedStack = useTransform(animatedStack, latest => {
    const numericValue = latest || 0;
    if (userTableSetting.isBlindView) {
      const hasDecimal = numericValue % 1 !== 0;
      return toFixedFloor(numericValue, hasDecimal ? 1 : 0).toLocaleString() + ' BB';
    }
    if (tableAssetConfig?.assetType === 'BPP') {
      return numberToDisplayString(numericValue, 0);
    }
    return numberToDisplayString(numericValue, chipDecimal);
  });

  useEffect(() => {
    if (stack !== undefined && stack !== animatedStack.get()) {
      animate(animatedStack, stack, { duration: 0.5 });
    }
  }, [stack, animatedStack]);

  useEffect(() => {
    const isJoin = action === HeaderAction.USER_JOIN;

    if (!seatData?.status) return;

    if (seatData?.status === 'SEAT_OUT') {
      setTextColor('gray400');
      setActionText(t('SEAT.SittingOut'));
      return;
    }
    if (!isTournamentTable && seatData?.status === 'RESERVED') {
      setTextColor('gray400');
      setActionText(t('SEAT.Reserved'));
      return;
    }
    if (seatData?.status === 'SEAT_IN' && isHandWaiting) {
      setTextColor('gray400');
      setActionText(t('SEAT.Waiting'));
      return;
    }

    switch (seatDisplay) {
      case 'Clear':
        setTextColor('success400');
        setActionText('');
        setActionBackground(undefined);
        break;
      case 'BigBlind':
        if (isJoin) return;
        setTextColor('warning400');
        setActionText(t('SEAT.BigBlind'));
        resetActionState();
        break;
      case 'SmallBlind':
        if (isJoin) return;
        setTextColor('warning400');
        setActionText(t('SEAT.SmallBlind'));
        resetActionState();
        break;
      case PlayerAction.ALLIN:
        if (!isJoin) {
          SoundManager._instance?.stopSound('all-in', 1.5);
          SoundManager._instance?.playSound('voice-all-in');
          SoundManager._instance?.playSound('all-in', 0.5);
          SoundManager._instance?.playSound('chip-single');
        }
        setTextColor('white');
        setActionText(t('ACTIONS.Allin'));
        setActionBackground('error500');
        break;
      case PlayerAction.CHECK:
        if (isJoin) return;
        SoundManager._instance?.playSound('voice-check');
        SoundManager._instance?.playSound('check');
        setTextColor('white');
        setActionText(t('ACTIONS.Check'));
        setActionBackground('primary500');
        resetActionState();
        break;
      case PlayerAction.BET: {
        if (isJoin) return;
        SoundManager._instance?.playSound('voice-bet');
        SoundManager._instance?.playSound('chip-single');
        setTextColor('white');
        setActionText(t('ACTIONS.Bet'));
        setActionBackground('warning500');
        resetActionState();
        break;
      }
      case PlayerAction.RAISE: {
        if (isJoin) return;
        SoundManager._instance?.playSound('voice-raise');
        SoundManager._instance?.playSound('chip-single');
        setTextColor('white');
        setActionText(t('ACTIONS.Raise'));
        setActionBackground('warning500');
        resetActionState();
        break;
      }
      case PlayerAction.CALL:
        if (isJoin) return;
        SoundManager._instance?.playSound('voice-call');
        SoundManager._instance?.playSound('chip-single');
        setTextColor('white');
        setActionText(t('ACTIONS.Call'));
        setActionBackground('primary500');
        resetActionState();
        break;
      case PlayerAction.FOLD: {
        if (isJoin) return;
        SoundManager._instance?.playSound('fold');
        SoundManager._instance?.playSound('voice-fold');
        setTextColor('white');
        setActionText(t('ACTIONS.Fold'));
        setActionBackground(undefined);
        resetActionState();
        break;
      }
      default:
        resetActionState();
        setTextColor('success400');
        break;
    }
  }, [seatDisplay, seatData?.status, tableSnapshot?.status, isHandWaiting, isTournamentTable]);

  const resetActionState = (delay: number = DELAY_ACTION_TEXT_CLEAR) => {
    _.delay(() => {
      setSeatDisplayType('Clear');
    }, delay);
  };

  useEffect(() => {
    if (handRound === GameRound.PRE_FLOP && action === HeaderAction.ROUND_PREFLOP) {
      if (isBB && player?.status !== PlayerStatus.CASHGAME_ALL_IN && player?.status !== PlayerStatus.ALLIN && player?.status !== 'FOLD') {
        _.delay(() => {
          setSeatDisplayType('BigBlind');
        }, DELAY_BEFORE_BET);
      }
      if (isSB && player?.status !== PlayerStatus.CASHGAME_ALL_IN && player?.status !== PlayerStatus.ALLIN && player?.status !== 'FOLD') {
        _.delay(() => {
          setSeatDisplayType('SmallBlind');
        }, DELAY_BEFORE_BET);
      }
    } else if (action === HeaderAction.ROUND_SETTLEMENT) {
      resetActionState(DELAY_SETTLEMENT_CLEAR);
    } else if (action === HeaderAction.TABLE_START || action === HeaderAction.TABLE_PAUSE || player === undefined || player.status === undefined) {
      resetActionState(0);
    }
    if (action !== HeaderAction.ROUND_SETTLEMENT && player?.status !== PlayerStatus.CASHGAME_ALL_IN && player?.status !== PlayerStatus.ALLIN && seatDisplay === PlayerAction.ALLIN) {
      resetActionState(0);
    }
  }, [handRound, isBB, isSB, action, player]);

  useEffect(() => {
    if (player?.status === PlayerStatus.CASHGAME_ALL_IN || player?.status === PlayerStatus.ALLIN) {
      setSeatDisplayType(PlayerAction.ALLIN);
    } else if (player?.lastAction !== undefined) {
      setSeatDisplayType(player.lastAction);
    }
  }, [player?.status, player?.lastAction]);

  useEffect(() => {
    if (player?.cards && player.cards.every(card => card !== 'XX')) {
      SoundManager._instance?.playSound('card-flip');
    }
  }, [player?.cards]);

  useEffect(() => {
    if (progress === undefined) {
      setTimerBackground('success400');
    } else if (progress < 25) {
      setTimerBackground('error500');
    } else if (progress < 50) {
      setTimerBackground('warning500');
    }
  }, [progress]);

  useEffect(() => {
    setIsProgressSet(false);
    setProgress(undefined);
    setExtraProgress(undefined);
    setExtraCountDown(undefined);
    setTimerBackground('success400');

    if (!ask?.startTimeout || !ask.endTimeout || !isTurnSeat) return;

    const start = new Date(ask?.startTimeout).getTime();
    const end = new Date(ask.endTimeout).getTime();
    const extraEnd = ask?.endExtraTimeout ? new Date(ask?.endExtraTimeout).getTime() : undefined;
    const total = end - start;

    let interval: NodeJS.Timeout;
    let extraInterval: NodeJS.Timeout;

    const updateExtraProgress = () => {
      const now = new Date().getTime();
      const remaining = extraEnd! - now;
      const newProgress = Math.max(0, Math.min(100, (remaining / total) * 100));
      const extraTime = Math.max(0, (remaining / 1000) | 0);
      setExtraProgress(newProgress);
      setExtraCountDown(extraTime);
      if (now >= extraEnd!) {
        clearInterval(extraInterval);
        setExtraProgress(undefined);
      }
    };

    const updateProgress = () => {
      const now = new Date().getTime();
      const remaining = end - now;
      const newProgress = Math.max(0, (remaining / total) * 100);
      setProgress(newProgress);

      if (now >= end) {
        if (extraEnd) {
          extraInterval = setInterval(updateExtraProgress, 1000);
        } else {
          setProgress(undefined);
        }
        clearInterval(interval);
      }
    };
    updateProgress();
    setTimeout(() => setIsProgressSet(true), 0);
    interval = setInterval(updateProgress, 1000);
    return () => {
      clearInterval(interval);
      clearInterval(extraInterval);
    };
  }, [ask?.endExtraTimeout, ask?.endTimeout, ask?.startTimeout, isTurnSeat]);

  useEffect(() => {
    if (mySeatId === seatId && timerBg === 'error500') {
      SoundManager._instance?.playSound('countdown');
    }
  }, [timerBg, mySeatId, seatId]);

  useEffect(() => {
    if (!emojiReceivedTime || !emojiUrl) {
      return;
    }
    const now = new Date();
    const timeDifference = now.getTime() - new Date(emojiReceivedTime).getTime();

    if (timeDifference > 4000) {
      return;
    }

    setShowEmoji(true);

    if (emojiTimer.current) {
      clearTimeout(emojiTimer.current);
    }

    emojiTimer.current = setTimeout(() => {
      setShowEmoji(false);
    }, 4000 - timeDifference);

    return () => {
      if (emojiTimer.current) {
        clearTimeout(emojiTimer.current);
      }
    };
  }, [emojiUrl, emojiReceivedTime]);

  const userFlagUrl = useMemo(() => countries?.find(country => country.code === seatedUserData?.countryCode)?.flagUrl, [countries, seatedUserData?.countryCode]);

  useEffect(() => {
    if (pos.x > 50) {
      if (pos.y < 30) {
        setDealerPos('left-bottom');
      } else if (pos.y > 80) {
        setDealerPos('left-top');
      } else {
        setDealerPos('left');
      }
    } else if (pos.x < 50) {
      if (pos.y < 30) {
        setDealerPos('right-bottom');
      } else if (pos.y > 80) {
        setDealerPos('right-top');
      } else {
        setDealerPos('right');
      }
    } else if (pos.x === 50) {
      if (pos.y > 50) {
        setDealerPos('left-top');
      } else {
        setDealerPos('right-bottom');
      }
    }
  }, [pos]);

  return {
    seatedPlayer: player,
    seatedUserData,
    isFolded: player?.status === PlayerStatus.FOLD,
    stack: player?.stack === undefined ? seatedUserData?.stack : player?.stack,
    isBlur: player?.status === PlayerStatus.FOLD || seatData?.status === SeatStatus.SEAT_OUT,
    cards: player?.cards,
    seatData,
    tableId,
    tableStatus: tableSnapshot?.status,
    isHandWaiting,
    isDealer: roles?.dealer === seatId,
    isBB,
    isSB,
    isAnte: roles?.ante?.includes(seatId),
    isTurn: isTurnSeat,
    isWinner: isWinnerSeat,
    winnerData,
    round: handRound,
    isMySeat,
    isAlreadySeated: Boolean(tableSnapshot?.seats?.some(seat => seat.userId === user?.id)),
    tableCallAmount,
    ask,
    emojiUrl,
    emojiReceivedTime,
    winRate,
    isTopWinRate,
    combinationMessage,
    hasSittingUser: Boolean(seatData) && seatData?.status !== SeatStatus.EMPTY,
    showEmoji,
    textColor,
    actionText,
    actionBg,
    transformedStack,
    progress,
    extraProgress,
    isProgressSet,
    extraCountDown,
    timerBg,
    isProgressing: isTurnSeat && progress !== undefined,
    isExtraProgressing: isTurnSeat && extraProgress !== undefined,
    flagUrl: userFlagUrl,
    tableSettings,
    myUserData,
    myStack,
    dealerPos,
    symbolImage: tableAssetInfo?.type === 'CRYPTO' ? tableAssetConfig?.symbolImage : undefined
  };
};

export default useSeat;
