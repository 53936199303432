import React, { useEffect, useMemo } from 'react';
import styled from '@emotion/styled';

import HorizontalLayout from '../../layout/HorizontalLayout';
import { useDispatch } from 'react-redux';
import Text from '../../common/text/Text';
import { dismissModal, ModalType, showModal } from '../../../store/slices/modalSlice';
import { XCloseIcon } from '../../common/icon/icons/XCloseIcon';
import { numberToDisplayString, toFixedFloor } from '../../../utils/StringUtil';
import LinearLayout from '../../layout/LinearLayout';
import { bpSocketService } from '../../../services/BpWebSocketService';
import BaseButton from '../../common/button/BaseButton';
import { useMyBalance } from '../../../hooks/useMyBalance';
import useDevice from '../../../hooks/useDevice';
import { useTournamentLobby } from '../../../hooks/useTournamentLobby';
import TitleValueHorizontal from '../../common/text/TitleValueHorizontal';

const TournamentRegisterModal: React.FC = () => {
  const dispatch = useDispatch();
  const { isMobile } = useDevice();
  const { tournamentId, tournamentInfo, tournamentAsset } = useTournamentLobby();
  const { myBalance } = useMyBalance();
  const [assetBalance, setAssetBalance] = React.useState(0);

  const isInsufficient = useMemo(() => {
    return assetBalance < (tournamentInfo?.static.buyIn ?? Number.MAX_VALUE);
  }, [assetBalance, tournamentInfo]);

  useEffect(() => {
    setAssetBalance(myBalance?.balances?.find(item => item.assetName === tournamentInfo?.static.assetName)?.amount ?? 0);
  }, [myBalance]);

  const register = () => {
    if (tournamentId) {
      bpSocketService.registerForTournament(tournamentId);
      dispatch(dismissModal());
    }
    // if (isInsufficient) {
    //   dispatch(showModal(ModalType.DepositModal));
    // } else {
    //   if (tournamentId) {
    //     bpSocketService.registerForTournament(tournamentId);
    //   }
    //   dispatch(dismissModal());
    // }
  };

  const cancel = () => {
    dispatch(dismissModal());
  };

  const terms = () => {
    dispatch(dismissModal());
  };

  return (
    <Background>
      <HorizontalLayout margin={'0'}>
        <Text fontStyle={isMobile ? 'TextMd_Semibold' : 'TextXl_Semibold'} textAlign={'start'} textColor={'white'} text={'Buy-In'} />
        <XCloseIcon onClick={cancel} />
      </HorizontalLayout>
      <Text fontStyle={isMobile ? 'TextLg_Bold' : 'DisplayXs_Bold'} textAlign={'center'} textColor={'white'} text={tournamentInfo?.static.title} />
      <LinearLayout>
        {isInsufficient && <StyledErrorText>{`Insufficient ${tournamentInfo?.static.assetName} Balance`}</StyledErrorText>}
        <StyledBalanceView alignItem={'center'} backgroundColor={'gray750'} padding={8} margin={'0'} borderRadius={8}>
          <StyledCurrencyText>
            <Text text={tournamentInfo?.static.assetName} fontStyle={'TextMd_Medium'} textColor={'gray300'} />
          </StyledCurrencyText>
          <StyledCurrencyText>
            <Text text={toFixedFloor(assetBalance, tournamentAsset?.displayDecimals ?? 0, false, true)} fontStyle={'TextMd_Semibold'} textColor={'gray300'} />
          </StyledCurrencyText>
        </StyledBalanceView>
      </LinearLayout>
      <LinearLayout gap={5}>
        <TitleValueHorizontal label={'Buy-in'} labelColor={'gray400'} value={`${toFixedFloor(tournamentInfo?.static.buyIn, tournamentAsset?.displayDecimals ?? 0, false, true)} ${tournamentInfo?.static.assetName}`} justify={'space-evenly'} gap={16} />
        <TitleValueHorizontal label={'Starting Chips'} labelColor={'gray400'} value={`${numberToDisplayString(tournamentInfo?.static.startingChips)} (100BB)`} justify={'space-evenly'} gap={16} />
        {isInsufficient && <Text marginTop={11} fontStyle={'TextMd_Medium'} textColor={'white'} text={'Would you like to deposit more?'} />}
        {!isInsufficient && (
          <LinkText isMobile={isMobile}>
            By registering, you agree to{' '}
            <Link isMobile={isMobile} onClick={terms}>
              Terms of Service
            </Link>
            .
          </LinkText>
        )}
      </LinearLayout>
      <HorizontalLayout margin={'0'} gap={16}>
        <BaseButton textStyle={'TextMd_Semibold'} size={'btn60'} textColor={'white'} text={'Cancel'} color={'gray'} onClick={cancel} />
        <BaseButton textStyle={'TextMd_Semibold'} size={'btn60'} textColor={'white'} text={isInsufficient ? 'Cashier' : 'Register'} color={'primary'} onClick={register} />
      </HorizontalLayout>
    </Background>
  );
};

export default TournamentRegisterModal;

const StyledBalanceView = styled(HorizontalLayout)`
  padding: 8px !important;
`;

const Background = styled.div`
  width: 520px;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray800};
  padding: 32px 40px;
  border-radius: 12px;
  gap: 24px;
  @media (max-width: 500px) {
    padding: 32px 20px;
  }
`;

const StyledCurrencyText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 24px;
  padding: 0 8px;
`;

const Link = styled.span<{ isMobile: boolean }>`
  ${({ isMobile, theme }) => (isMobile ? theme.textStyles.TextSmall_Medium : theme.textStyles.TextMd_Medium)};
  color: ${({ theme }) => theme.colors.primary500};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const LinkText = styled.span<{ isMobile: boolean }>`
  ${({ isMobile, theme }) => (isMobile ? theme.textStyles.TextSmall_Medium : theme.textStyles.TextMd_Medium)};
  color: ${({ theme }) => theme.colors.gray500};
  width: 100%;
  text-align: ${({ isMobile }) => (isMobile ? 'center' : 'center')};
  margin-top: 11px;
`;

const StyledErrorText = styled.div`
  ${({ theme }) => theme.textStyles.TextMd_Medium};
  color: ${({ theme }) => theme.colors.error500};
  margin-bottom: 4px;
  width: 100%;
  text-align: center;
`;
