import React from 'react';
import { IconProps } from '../types';
import { Colors } from 'src/styles/Colors';

export const CheckCircle = React.memo<IconProps>(({ w = 20, h = 20, color = 'primary600', subColor = 'white' }) => (
  <svg width={w} height={h} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width={w} height={h} rx="10" fill={Colors[color]} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.2326 6.73955C14.4225 6.92938 14.4225 7.23717 14.2326 7.42701L8.8854 12.7742C8.69556 12.9641 8.38777 12.9641 8.19793 12.7742L5.76738 10.3437C5.57754 10.1538 5.57754 9.84605 5.76738 9.65621C5.95722 9.46637 6.26501 9.46637 6.45484 9.65621L8.54167 11.743L13.5452 6.73955C13.735 6.54971 14.0428 6.54971 14.2326 6.73955Z"
      fill={Colors[subColor]}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.2531 6.44459C13.6057 6.09204 14.1773 6.09204 14.5299 6.44459L14.2377 6.73679L14.5299 6.44459C14.8824 6.79715 14.8824 7.36876 14.5299 7.72131L9.18263 13.0685C8.83007 13.4211 8.25847 13.4211 7.90591 13.0685L5.47536 10.638C5.1228 10.2854 5.1228 9.71382 5.47535 9.36126C5.82791 9.0087 6.39952 9.0087 6.75208 9.36126L8.54427 11.1535L13.2531 6.44459ZM13.9406 7.03385C13.9135 7.00673 13.8695 7.00673 13.8424 7.03385L8.8389 12.0373C8.67618 12.2001 8.41236 12.2001 8.24964 12.0373L6.16282 9.95052C6.1357 9.9234 6.09173 9.9234 6.06461 9.95052C6.03749 9.97763 6.03749 10.0216 6.06461 10.0487L8.49517 12.4793C8.52229 12.5064 8.56626 12.5064 8.59337 12.4793L13.9406 7.13206C13.9677 7.10494 13.9677 7.06097 13.9406 7.03385Z"
      fill={Colors[subColor]}
    />
  </svg>
));

CheckCircle.displayName = 'CheckCircle';
